import { camelCase } from "lodash-es"
import moment from "moment"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ISitePreview } from "../models/site"
import { UnitName } from "../models/unit"
import { KWH_PER_MWH, TIME_RANGES } from "./constants"

export const sharedDropdownFormat = "MMMM YYYY"

const renameKeys = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  formatMethod: (input: string) => string
) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ...{ [obj[formatMethod(key)] || formatMethod(key)]: obj[key] },
    }),
    {}
  )
export const currencyTestRegex = /^\$\d{1,3}(?:,\d{3})?\.\d{2}$/
export const numberWithCommasTestRegex =
  /^\d{1,3}(?:,\d{3}){0,3}?(?:\.\d{1,3})?$/
export const roundedNumberWithCommasTestRegex = /^\d{1,3}(?:,\d{3}){0,3}?$/
export const roundedCurrencyTestRegex = /^\$\d{1,3}(?:,\d{3})?$/
export const roundedVolumeTestRegex = /^\d{1,3}(?:,\d{3}){0,3}?(\sMWh)$/
export const currencyPerMwhTestRegex =
  /^\$\d{1,3}(?:,\d{3})?\.\d{2}(\s\/\sMWh)$/
export const roundedNumberWithCommasOrNanTestRegex =
  /^(\d{1,3}(?:,\d{3}){0,3}?)|(__.__)$/

export const anyLoadedSiteUrlRegex = /\/sites\/\d+\?/
export const anyLoadedOrganizationUrlRegex = /\/organizations\/\d+/
export const utcDateTimeRegex =
  /(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])\s(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])\sUTC/

export const formatToSnakeCase = (inString: string): string =>
  inString
    ? inString
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join("_")
    : inString

export const renameKeysToSnakeCase = (
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => renameKeys(obj, formatToSnakeCase)

export const titleize = (text: string): string =>
  text
    .split(" ")
    .map((str) =>
      [
        str.charAt(0).toUpperCase(),
        [...str.slice(1)].join("").toLowerCase(),
      ].join("")
    )
    .join(" ")

export const formatEnergyVolumeInUnit = (
  energyVolume: number,
  inputUnitType: UnitName.KilowattHour | UnitName.MegawattHour,
  outputUnitType: UnitName.KilowattHour | UnitName.MegawattHour
): number => {
  if (inputUnitType === outputUnitType) {
    return energyVolume
  }
  if (
    inputUnitType === UnitName.MegawattHour &&
    outputUnitType === UnitName.KilowattHour
  ) {
    return energyVolume * KWH_PER_MWH
  }
  if (
    inputUnitType === UnitName.KilowattHour &&
    outputUnitType === UnitName.MegawattHour
  ) {
    return energyVolume / KWH_PER_MWH
  }
  throw new Error("Formatter.formatEnergyVolumeInUnit: unknown WattUnit!")
}

export const transformPricePerWhIntoUnit = (
  pricePerMwh: number,
  inputUnitType: UnitName.KilowattHour | UnitName.MegawattHour,
  outputUnitType: UnitName.KilowattHour | UnitName.MegawattHour
): number => {
  if (inputUnitType === outputUnitType) {
    return pricePerMwh
  }
  if (
    inputUnitType === UnitName.MegawattHour &&
    outputUnitType === UnitName.KilowattHour
  ) {
    return pricePerMwh / KWH_PER_MWH
  }
  if (
    inputUnitType === UnitName.KilowattHour &&
    outputUnitType === UnitName.MegawattHour
  ) {
    return pricePerMwh * KWH_PER_MWH
  }
  throw new Error("Formatter.formatEnergyVolumeInUnit: unknown WattUnit!")
}

export const transformKwhToWattUnit = (
  kwh: number,
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
): number => (wattUnit === UnitName.MegawattHour ? kwh / KWH_PER_MWH : kwh)

// DEPRECATED: DO NOT USE
/**
 * @deprecated
 * @param obj
 */
const isObject = (obj): boolean =>
  obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"

// Mass eslint disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const snakeCaseToCamelCase = (obj: any): any => {
  if (isObject(obj)) {
    const n = {}

    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(obj).forEach((k) => {
      // migration to strict mode batch disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      n[camelCase(k)] = snakeCaseToCamelCase(obj[k])
    })

    return n
  }
  if (Array.isArray(obj)) {
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map((i) => snakeCaseToCamelCase(i))
  }

  return obj
}

// DEPRECATED: DO NOT USE
/**
 * @deprecated
 * @param timeRange
 * @param startDate
 * @param endDate
 */
export const timeRangeDisplayText = (
  timeRange: TIME_RANGES,
  startDate: string,
  endDate
): string => {
  switch (timeRange) {
    case TIME_RANGES.DAY:
      return startDate
    case TIME_RANGES.YEAR:
      return moment(startDate).format("YYYY")
    default:
      return `${startDate} - ${endDate}`
  }
}

type IBooleanObject = Record<string, boolean>
/**
 * Filter only truthy values
 *
 * @param obj {siteIsAmr: true, siteIsAmi: false} e.g.
 * @returns {[Array[]]} returns a matrix [[siteIsAmr, true]]
 */
export const getSiteDetailsMatrix = (obj: IBooleanObject) =>
  Object.entries(obj).filter((value) => value[1])
