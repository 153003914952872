import { operatingHoursMap } from "../../utils/operating-hours-options"
import type { SiteInputSchema } from "../../utils/site-input-schema"

export function buildCommonMeta(values: SiteInputSchema) {
  let commonMeta: Record<string, string | number> = {
    in_comstock_building_type: values.in_comstock_building_type,
    in_county_name: values.in_county_name,
    in_sqft: Number(values.in_sqft),
  }
  if (values.in_year_built) {
    commonMeta.in_year_built = Number(values.in_year_built)
  }
  let hoursValues
  if (values.operatingHours) {
    hoursValues =
      operatingHoursMap[values.operatingHours as keyof typeof operatingHoursMap]
  }
  if (hoursValues) {
    commonMeta = { ...commonMeta, ...hoursValues }
  }
  return commonMeta
}
