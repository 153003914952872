import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"

import OrgGuard from "../../components/route/orgGuard/orgGuard"
import UserGuard from "../../components/route/userGuard/userGuard"
import { RootPath } from "../../models/route"
import ReportRoute from "./components/reportRoute/reportRoute"

const ReportingRoute: React.FC = () => {
  const { organization } = useOrganizationContext()

  return (
    <Routes>
      <Route
        element={
          organization?.id ? (
            <Navigate replace to={`${RootPath.Report}/${organization.id}`} />
          ) : null
        }
        path="/"
      />
      <Route
        element={
          <OrgGuard>
            {
              // UserGuard must be used at this route level inside OrgGuard
              // because prior url (e.g. /reporting) is forwarding to /reporting/:orgId to add an orgId in the url
              // which is required to determine the active organization
            }
            <UserGuard>
              <ReportRoute />
            </UserGuard>
          </OrgGuard>
        }
        path="/:orgId/*"
      />
    </Routes>
  )
}

export default ReportingRoute
