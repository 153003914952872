import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"

import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import { allCustomersWorkspaceName } from "./sigma.types"
import type { SigmaPaginatedResponse, SigmaWorkbook } from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

// Filtering is necessary because the user associated with the API secret has access to
// the All Customers team/workspace as well as all org teams/workspaces
const filterWorkbooks = (
  workbooks: SigmaWorkbook[],
  workspaceNames: string[]
): SigmaWorkbook[] => {
  const accessibleWorkbooks = workbooks.filter((workbook) =>
    workspaceNames
      .map((name) => name.toLowerCase())
      .includes(workbook.path.toLowerCase())
  )

  return accessibleWorkbooks
}

/**
 *
 * @returns A list of Sigma workbooks available for the organization
 */
export const useSigmaWorkbooks = (organizationName: string) => {
  const { fetchSigmaWorkbooks, sigmaAccessTokenIsFetched } = useSigmaApi()
  const enabled = sigmaAccessTokenIsFetched && !!organizationName
  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbooks,
    "getMany",
    organizationName
  )
  const queryResult = useQuery<SigmaPaginatedResponse<SigmaWorkbook>>({
    enabled,
    queryFn: fetchSigmaWorkbooks,
    queryKey: [queryKey],
    select: (data) => ({
      ...data,
      entries: filterWorkbooks(data?.entries ?? [], [
        allCustomersWorkspaceName,
        organizationName,
      ]),
    }),
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbooks)
}
