import React from "react"

import { Typography } from "@mui/material"

import { FONT_WEIGHT } from "../../../../models/typography"
import type { UtilityFormType } from "../../model/utilityCredentials"
import CircleIcon from "../circleIcon/circleIcon"
import { FormOptionButton } from "./formOptionButton"

interface FormOptionProps {
  currentForm: UtilityFormType
  formId: string
  formName: string
  formState: string
}

const FormOption: React.FC<FormOptionProps> = ({
  formId,
  formName,
  formState,
  currentForm,
}) => (
  <FormOptionButton id={formId} selected={currentForm === formId}>
    <Typography fontWeight={FONT_WEIGHT.bold}>{formName}</Typography>
    <CircleIcon formFilledState={formState} />
  </FormOptionButton>
)

export default FormOption
