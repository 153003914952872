import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

import { client } from "@/services/client"
import type { Schemas } from "@/services/types"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { ApiQueryName } from "../../../../models/api"

const getRepBuildingCounts = async (): Promise<
  Schemas["BuildingCountsResponse"]
> => {
  const { data, error } = await client.GET(
    "/api/v1/recommendations/building_counts",
    {}
  )

  if (data) {
    return data
  }

  throw error as Error
}

/**
 * Returns REP building counts
 *
 * @example
 * const { data, isLoading } = useRepBuildingCounts()
 */
export const useRepBuildingCounts = (): UseQueryResult<
  Schemas["BuildingCountsResponse"]
> => {
  const queryKey = useQueryKey(ApiQueryName.RepBuildingCounts, "getMany")

  const useQueryParams = {
    queryKey,
    queryFn: async () => getRepBuildingCounts(),
  }

  const query = useQuery(useQueryParams)

  return query
}
