import type { ReactNode } from "react"
import React from "react"

import { Box } from "@mui/material"

import type { ICard } from "../../models/report"
import ReportingHeader from "../reportingHeader/reportingHeader"

interface ReportLayoutProps {
  actions: ReactNode
  details?: ReactNode
  filters: ReactNode
  notifications?: ReactNode
  organizationId: string
  report: ICard
}

const ReportLayout = ({
  actions,
  details,
  filters,
  notifications,
  organizationId,
  report,
}: ReportLayoutProps) => (
  <>
    <Box mb={2}>
      <ReportingHeader card={report} organizationId={organizationId} />
    </Box>
    {notifications}
    <Box mb={3}>{filters}</Box>
    <Box mb={2}>{actions}</Box>
    {details}
  </>
)

ReportLayout.defaultProps = {
  details: "",
  notifications: "",
}

export default ReportLayout
