import type { CurrencyCode } from "../models/currencyCode"
import { LanguageCurrencyMap } from "../models/currencyCode"
import type { LanguageRegion } from "../models/i18n"
import { UnitName } from "../models/unit"

// DEPRECATED: DO NOT USE
/**
 * @deprecated
 */
const enum WattUnit {
  KWH = "KWh",
  MWH = "MWh",
}

const CURRENCY_KEY = "currency"
const LANGUAGE_KEY = "language"
const WATT_UNIT_KEY = "watt-unit"

export interface UserInfo {
  organizationId: string
}

export const getCurrency = (): CurrencyCode | null => {
  const savedCurrencyCode = localStorage.getItem(CURRENCY_KEY) as CurrencyCode

  // Only return valid saved currency codes
  // If an invalid currency code makes it way into local storage, return null
  return Array.from(LanguageCurrencyMap.values()).includes(savedCurrencyCode)
    ? savedCurrencyCode
    : null
}

export const getLanguage = (): LanguageRegion | null =>
  localStorage.getItem(LANGUAGE_KEY) as LanguageRegion

export const saveCurrency = (currency: CurrencyCode): void => {
  localStorage.setItem(CURRENCY_KEY, currency)
}

export const saveLanguage = (language: LanguageRegion): void => {
  localStorage.setItem(LANGUAGE_KEY, language)
}

export const getWattUnit = ():
  | UnitName.KilowattHour
  | UnitName.MegawattHour => {
  const savedWattUnit: string = localStorage.getItem(WATT_UNIT_KEY)

  // Also handle old implementation using WattUnit to preserve compatibility
  if (
    savedWattUnit === WattUnit.KWH ||
    savedWattUnit === UnitName.KilowattHour
  ) {
    return UnitName.KilowattHour
  }

  return UnitName.MegawattHour
}

export const saveWattUnit = (
  unit: UnitName.KilowattHour | UnitName.MegawattHour
): void => {
  localStorage.setItem(WATT_UNIT_KEY, unit)
}

export const updateUserSessionInfo = (
  userSessionInfo: Record<string, UserInfo>,
  userId: string | undefined,
  organizationId: string | undefined
): Record<string, UserInfo> => {
  if (!userId || !organizationId) {
    return userSessionInfo
  }

  if (!userSessionInfo[userId]) {
    userSessionInfo[userId] = { organizationId }
  } else {
    userSessionInfo[userId].organizationId = organizationId
  }

  return userSessionInfo
}
