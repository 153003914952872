import type { FC } from "react"
import React from "react"
import { Link } from "react-router-dom"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { IconButton, Typography } from "@mui/material"

import { HStack } from "../../nzds"
import type { PageTitleProps } from "./page-title.types"

export const PageTitle: FC<PageTitleProps> = ({ title, back, actions }) => (
  <HStack alignItems="center" gap={2} justifyContent="space-between">
    <HStack alignItems="center" gap={2}>
      {back ? (
        <IconButton
          aria-label={back.label}
          color="neutral"
          component={Link}
          to={back.to}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      ) : null}
      <Typography variant="h1">{title}</Typography>
    </HStack>

    {actions ? <HStack gap={1}>{actions}</HStack> : null}
  </HStack>
)
