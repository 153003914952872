import type { Dispatch, SetStateAction } from "react"
import React, { useEffect, useState } from "react"
import { Navigate, Route, Routes, useMatch, useParams } from "react-router-dom"

import { useSiteContext } from "@/contexts/siteProvider"
import { useLocalStorage } from "@uidotdev/usehooks"

import { useOrganizationContext } from "../../../contexts/organizationProvider"
import { RootPath, SitePath } from "../../../models/route"
import Electricity from "../../../modules/site/components/electricity/electricity"
import NaturalGas from "../../../modules/site/components/naturalGas/naturalGas"
import SiteAnalysis from "../../../modules/site/components/siteAnalysis/siteAnalysis"
import { useMonthSummariesMinMaxDates } from "../../../modules/site/services/useMonthSummariesMinMaxDates/useMonthSummariesMinMaxDates"
import { useNaturalGasAvailableMonths } from "../../../modules/site/services/useNaturalGasAvailableMonths/useNaturalGasAvailableMonths"
import { useServiceHourMinMaxDates } from "../../../modules/site/services/useServiceHourMinMaxDates/useServiceHourMinMaxDates"
import { useSiteExtraAttributes } from "../../../modules/site/services/useSiteExtraAttributes/useSiteExtraAttributes"
import { useAuthentication } from "../../../services"
import type { UserInfo } from "../../../utils/localStorage"
import { updateUserSessionInfo } from "../../../utils/localStorage"
import { Dashboard } from "../../dashboard/dashboard"
import Page404 from "../../nav/page404/page404"

/**
 * A component that handles the "/sites/:siteId" route
 *
 * @deprecated Delete after site_explorer is permanently live
 * @example
 * return (
 *   <SiteRoute />
 * )
 */
const SiteRoute = (): JSX.Element => {
  const [isUnreachableSite, setIsUnreachableSite]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ] = useState(false)
  const { organization, setOrganization } = useOrganizationContext()
  const [userSessionInfo, setUserSessionInfo] = useLocalStorage<
    Record<string, UserInfo>
  >(`${process.env.REACT_APP_ENV}UserSessionInfo`, {})
  const { authenticatedUser } = useAuthentication()
  const { activeSite, getSiteById, setActiveSite } = useSiteContext()
  const params = useParams()
  const isNaturalGasMatch = useMatch(`sites/:siteId/${SitePath.NaturalGas}`)
  const isElectricityMatch = useMatch(`sites/:siteId/${SitePath.Electricity}`)

  // Does site track natural gas?
  const { naturalGasAvailableMonths, isNaturalGasAvailableMonthsFetched } =
    useNaturalGasAvailableMonths(activeSite?.id)
  const siteTracksAndHasGas =
    isNaturalGasAvailableMonthsFetched &&
    naturalGasAvailableMonths &&
    naturalGasAvailableMonths.length >= 1

  // Does site track electricity?
  const { serviceHourMinMaxDates, isServiceHourMinMaxDatesFetched } =
    useServiceHourMinMaxDates(activeSite)

  const {
    siteExtraAttributes: { hasAmrMeter, hasAmiMeter } = {
      hasAmrMeter: null,
      hasAmiMeter: null,
    },
    isSiteExtraAttributesFetched,
  } = useSiteExtraAttributes(activeSite?.id)

  const isMonthlySummaryVisible =
    hasAmrMeter && !hasAmiMeter && isSiteExtraAttributesFetched

  const { monthSummariesMinMaxDates, isMonthSummariesMinMaxDatesFetched } =
    useMonthSummariesMinMaxDates(activeSite?.id, {
      isDisabled: !isMonthlySummaryVisible,
    })

  const siteHasElectricityServiceHours =
    isServiceHourMinMaxDatesFetched &&
    serviceHourMinMaxDates?.end !== null &&
    serviceHourMinMaxDates?.start !== null

  const siteHasElectricityMonthlySummary: boolean = isMonthlySummaryVisible
    ? isMonthSummariesMinMaxDatesFetched &&
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      monthSummariesMinMaxDates?.end.isValid() &&
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      monthSummariesMinMaxDates?.start.isValid()
    : true

  const siteTracksAndHasElectricity =
    siteHasElectricityServiceHours && siteHasElectricityMonthlySummary

  const siteHasNoData: boolean =
    !siteTracksAndHasElectricity &&
    !siteTracksAndHasGas &&
    isServiceHourMinMaxDatesFetched &&
    (!isMonthlySummaryVisible ||
      (isMonthlySummaryVisible && isMonthSummariesMinMaxDatesFetched)) &&
    isNaturalGasAvailableMonthsFetched

  const naturalGasMatchNoData =
    isNaturalGasMatch &&
    !siteTracksAndHasGas &&
    isNaturalGasAvailableMonthsFetched

  const electricityMatchNoData =
    isElectricityMatch &&
    !siteTracksAndHasElectricity &&
    isServiceHourMinMaxDatesFetched &&
    (!isMonthlySummaryVisible ||
      (isMonthlySummaryVisible && isMonthSummariesMinMaxDatesFetched))

  // Set the active site from the url
  // also change organization when new site's org is different
  useEffect(() => {
    if (params.siteId) {
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getSiteById(params.siteId).then((newActiveSite) => {
        if (newActiveSite) {
          if (organization?.id !== newActiveSite.organization?.id) {
            setOrganization(newActiveSite.organization)
            const updatedUserInfo = updateUserSessionInfo(
              userSessionInfo,
              authenticatedUser?.id,
              newActiveSite.organization.id
            )
            setUserSessionInfo(updatedUserInfo)
          }
        }

        setActiveSite(newActiveSite)
        setIsUnreachableSite(!newActiveSite)
      })
    }
  }, [params.siteId])

  if (isUnreachableSite) {
    return <Navigate to={RootPath.NotFound} />
  }

  if (electricityMatchNoData) {
    return <Navigate replace to={SitePath.NaturalGas} />
  }

  if (naturalGasMatchNoData) {
    if (!siteTracksAndHasElectricity) {
      return <Navigate replace to={SitePath.Emissions} />
    }
    return <Navigate replace to={SitePath.Electricity} />
  }

  return (
    <Routes>
      <Route
        element={
          <Dashboard
            site={activeSite}
            siteHasNoData={siteHasNoData}
            siteTracksAndHasElectricity={siteTracksAndHasElectricity}
            siteTracksAndHasGas={siteTracksAndHasGas}
          />
        }
        path={SitePath.DashboardRoot}
      >
        <Route element={<SiteAnalysis />} path={SitePath.Emissions} />
        <Route element={<Electricity />} path={SitePath.Electricity} />
        <Route element={<NaturalGas />} path={SitePath.NaturalGas} />
      </Route>
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}

export default SiteRoute
