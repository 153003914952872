import React, { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

import { useSiteSearchContext } from "../../contexts/siteSearchProvider"
import { useSiteSearchResultsActiveContext } from "../../contexts/siteSearchResultsActiveProvider"
import { AppLayout } from "./appLayout/appLayout"

interface IWithNavigationProps {
  children: React.ReactNode
}

const WithNavigation = (props: IWithNavigationProps): JSX.Element => {
  const { children } = props
  const location = useLocation()
  const { setIsSiteSearchResultsActive } = useSiteSearchResultsActiveContext()
  const { setIsOrgSitesChanging } = useSiteSearchContext()

  useLayoutEffect(() => {
    setIsSiteSearchResultsActive(false)
    setIsOrgSitesChanging(true)
  }, [location, setIsSiteSearchResultsActive, setIsOrgSitesChanging])

  return <AppLayout>{children}</AppLayout>
}

export default WithNavigation
