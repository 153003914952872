import React from "react"

import { Box, Divider, Stack, Typography } from "@mui/material"

import { NzeroLogo as NLogo } from "../../../../components/logo/nzeroLogo"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { deprecatedGray400 } from "../../../../utils/colors"
import { pxToRem } from "../../../../utils/unit"
import type {
  UtilityCredentialsData,
  currentFormStateType,
} from "../../model/utilityCredentials"
import { UtilityFormType } from "../../model/utilityCredentials"
import FormOption from "../formOptions/formOption"

interface CredentialSelectionProps {
  currentForm: UtilityFormType
  currentFormState: currentFormStateType
  orgData: UtilityCredentialsData
}

const CredentialSelection = ({
  orgData,
  currentForm,
  currentFormState,
}: CredentialSelectionProps): JSX.Element => (
  <Stack
    alignItems="flex-start"
    sx={{
      width: { sm: "100%", md: "50%" },
      height: "100%",
      padding: 4.5,
    }}
  >
    <Stack
      direction="row"
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
        mb: 4.5,
      }}
    >
      <Box
        sx={{
          height: pxToRem(60),
        }}
      >
        <NLogo width={120} />
      </Box>
      <Divider
        orientation="vertical"
        sx={{ mx: 2, borderColor: deprecatedGray400.toString() }}
      />
      <Typography color="primary" variant={TYPOGRAPHY_VARIANT.h1}>
        {orgData.aud}
      </Typography>
    </Stack>

    <Typography sx={{ mb: 3 }} variant={TYPOGRAPHY_VARIANT.h3}>
      Input the login credentials for your organizations utility providers. This
      will begin the process of setting up your organization.
    </Typography>

    <Stack spacing={1.5} width="100%">
      <FormOption
        currentForm={currentForm}
        formId={UtilityFormType.Electric}
        formName="Electricity Credentials"
        formState={currentFormState.electric}
      />

      <FormOption
        currentForm={currentForm}
        formId={UtilityFormType.NaturalGas}
        formName="Natural Gas Credentials"
        formState={currentFormState.naturalGas}
      />
    </Stack>
  </Stack>
)

export default CredentialSelection
