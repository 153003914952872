import React from "react"

import { useAuthentication } from "@/services/authentication"
import { useFeature } from "@/services/feature"

import { Box, Divider, Typography } from "@mui/material"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { gray200 } from "../../../../utils/colors"
import type { ICard } from "../../models/report"
import { CardGroup, cards } from "../../models/report"
import { ReportingCard } from "../reportingCard/reportingCard"

const Reporting: React.FC = () => {
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()
  const { isLimitedAccessUser } = useAuthentication()

  const renderReportingCards = (reportingCards: ICard[]) =>
    Object.keys(reportingCards).reduce((acc: JSX.Element[], key: string) => {
      if (
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        reportingCards[key].featureFlag === undefined ||
        // Mass lint disable
        // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        isFeatureEnabled(reportingCards[key].featureFlag, organization)
      ) {
        return [
          ...acc,
          <ReportingCard
            key={key}
            // migration to strict mode batch disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            card={reportingCards[key]}
            organization={organization}
          />,
        ]
      }
      return acc
    }, [])

  // Hide cards that are not enabled for limited access users
  const group1 = cards.filter(
    (card) =>
      card.group === CardGroup.One &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )
  const group2 = cards.filter(
    (card) =>
      card.group === CardGroup.Two &&
      (!isLimitedAccessUser ||
        (isLimitedAccessUser && card.isVisibleToLimitedAccessUser))
  )

  return (
    <>
      <Box mb={3} mt={1}>
        <Typography data-e2e="reporting-title" variant="h1">
          Reporting
        </Typography>
        <Typography>Select report type</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
        {renderReportingCards(group1)}
      </Box>
      {group2.length > 0 && (
        <Divider sx={{ marginBottom: 3, borderColor: gray200.toString() }} />
      )}
      <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
        {renderReportingCards(group2)}
      </Box>
    </>
  )
}

export default Reporting
