import { useEffect } from "react"

import { useAuthentication } from "../authentication"
import { useGtag } from "./use-gtag"

export const useGtagSetUser = () => {
  const { authenticatedUser } = useAuthentication()
  const { gtagData, gtagIsFetched } = useGtag()

  // Initialize Gtag user
  useEffect(() => {
    if (authenticatedUser && gtagData && gtagIsFetched) {
      gtagData.gtag("set", {
        user_id: authenticatedUser.email,
      })
    }
  }, [authenticatedUser, gtagData, gtagIsFetched])
}
