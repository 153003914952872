import React from "react"

import SearchIcon from "@mui/icons-material/Search"
import { InputAdornment, Paper, TextField } from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import makeStyles from "@mui/styles/makeStyles"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import { useSiteSearchContext } from "../../contexts/siteSearchProvider"
import { useSiteSearchResultsActiveContext } from "../../contexts/siteSearchResultsActiveProvider"
import type { ISitePreview } from "../../models/site"
import { FeatureFlags, useFeature } from "../../services"
import { firstStrLowerCaseContainsSecondStrLowerCase } from "../../utils"
import { darkGray, dustyGray, lightGray, solidGray } from "../../utils/colors"

const siteSearchStyles = {
  top: "12px",
  left: "12px",
  right: 0,
  margin: "auto",
  transition: "width 0.3s",
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${lightGray.toString()}`,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${solidGray.toString()}`,
  },
  fontSize: "16px",
}

const useStyles = makeStyles({
  inactive: {
    ...siteSearchStyles,
    position: "absolute",
    width: 225,
  },
  active: {
    ...siteSearchStyles,
    position: "absolute",
    width: 478,
  },
  optionSpan: {
    width: "100%",
    display: "flex",
  },
  option: {
    margin: "auto 16px",
    color: darkGray.toString(),
  },
  optionSearch: {
    color: lightGray.toString(),
  },
  search: {
    color: dustyGray.toString(),
  },
  textField: {
    "& fieldset": {
      borderRadius: "6px",
    },
  },
  dropDownActive: {
    border: `1px solid ${lightGray.toString()}`,
    marginTop: "4px",
  },

  dropDownInactive: {
    display: "none",
  },
})

const OPTIONS_LIMIT = 8
const defaultFilterOptions = createFilterOptions()
const filterOptions = (options, state) =>
  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)

interface SiteSearchProps {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  sitePreviews: ISitePreview[]
}

export const SiteSearch = ({
  sitePreviews,
  setSearchTerm,
}: SiteSearchProps) => {
  const classes = useStyles()
  const { setSearchResults, isOrgSitesChanging, setIsOrgSitesChanging } =
    useSiteSearchContext()
  const { setIsSiteSearchResultsActive } = useSiteSearchResultsActiveContext()
  const [inputValue, setInputValue] = React.useState("")
  const [isFocus, setIsFocus] = React.useState<boolean>(false)
  const [searchSuggestions, setSearchSuggestions] = React.useState([])
  const textRef = React.useRef<HTMLInputElement>(null)
  const { organization } = useOrganizationContext()

  const { isFeatureEnabled } = useFeature()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const getSearchSuggestions = (
    siteData: ISitePreview[],
    searchTerm: string
  ): string[] => {
    const newSearchSuggestions: string[] = []

    siteData.forEach((site): void => {
      const { name, departmentName, topOrgUnitName } = site

      if (firstStrLowerCaseContainsSecondStrLowerCase(name, searchTerm)) {
        newSearchSuggestions.push(name)
      }

      if (
        isUseFlexibleHierarchyEnabled &&
        !newSearchSuggestions.includes(topOrgUnitName)
      ) {
        if (
          firstStrLowerCaseContainsSecondStrLowerCase(
            topOrgUnitName,
            searchTerm
          )
        ) {
          newSearchSuggestions.push(topOrgUnitName)
        }
      }

      if (
        !isUseFlexibleHierarchyEnabled &&
        !newSearchSuggestions.includes(departmentName)
      ) {
        if (
          firstStrLowerCaseContainsSecondStrLowerCase(
            departmentName,
            searchTerm
          )
        ) {
          newSearchSuggestions.push(departmentName)
        }
      }
    })

    return newSearchSuggestions
  }

  const updateSiteSearch = (searchTerm: string): void => {
    const searchResults: ISitePreview[] = []

    sitePreviews.forEach((sitePreview) => {
      if (
        firstStrLowerCaseContainsSecondStrLowerCase(
          sitePreview.name,
          searchTerm
        ) ||
        (!isUseFlexibleHierarchyEnabled &&
          firstStrLowerCaseContainsSecondStrLowerCase(
            sitePreview.departmentName,
            searchTerm
          )) ||
        (isUseFlexibleHierarchyEnabled &&
          firstStrLowerCaseContainsSecondStrLowerCase(
            sitePreview.topOrgUnitName,
            searchTerm
          ))
      ) {
        searchResults.push(sitePreview)
      }
    })

    setSearchTerm(searchTerm)
    setSearchResults(searchResults)
    setIsSiteSearchResultsActive(true)
  }

  const onKeyPress = (e): void => {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (e.target.value && e.code === "Enter") {
      // Mass lint disable
      // Mass eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      e.target.blur()
      // Mass lint disable
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      updateSiteSearch(e.target.value)
    }
  }

  React.useEffect(() => {
    if (isOrgSitesChanging) {
      setSearchSuggestions([])
      setIsOrgSitesChanging(false)
      setInputValue("")
      setIsFocus(false)
      const textInput: HTMLInputElement = textRef.current.querySelector("input")
      textInput.placeholder = "Search Sites"
    }
  }, [isOrgSitesChanging])

  React.useEffect(() => {
    if (inputValue.length) {
      const newSearchSuggestions: string[] = getSearchSuggestions(
        sitePreviews,
        inputValue
      )

      if (newSearchSuggestions.length) {
        setSearchSuggestions(newSearchSuggestions)
      } else {
        setSearchSuggestions([`No search results for "${inputValue}"`])
      }
    } else {
      setSearchSuggestions([])
    }
  }, [inputValue, sitePreviews])

  const click = () => {
    setIsFocus(true)
  }

  const blur = (e) => {
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (e.target.value === "") {
      setIsFocus(false)
      // Mass lint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      e.target.placeholder = "Search Sites"
    }
  }

  const autocompleteClass: string = isFocus ? classes.active : classes.inactive
  const paperClass: string =
    inputValue.length > 0 ? classes.dropDownActive : classes.dropDownInactive

  return (
    <Autocomplete
      blurOnSelect
      className={autocompleteClass}
      data-testid="autocomplete"
      filterOptions={filterOptions}
      freeSolo
      onBlur={blur}
      onFocus={click}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "clear") {
          setInputValue("")
        }
        setInputValue(newInputValue)
      }}
      options={searchSuggestions}
      PaperComponent={({ children }) => (
        <Paper className={paperClass}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={textRef}
          className={classes.textField}
          data-e2e="search-site-input"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.search} />
              </InputAdornment>
            ),
          }}
          name="text-field"
          onFocus={(e) => {
            e.target.placeholder = ""
          }}
          onKeyDown={(e) => {
            onKeyPress(e)
          }}
          placeholder="Search Sites"
          variant="outlined"
        />
      )}
      renderOption={(props, option: string) => (
        <li {...props}>
          {/* Component needs to be refactored as it does not follow best practices */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div
            className={classes.optionSpan}
            onClick={() => {
              updateSiteSearch(option)
            }}
          >
            <SearchIcon className={classes.optionSearch} />
            <p className={classes.option}>{option}</p>
          </div>
        </li>
      )}
      size="small"
      value={inputValue}
    />
  )
}
