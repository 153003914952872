// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react"

import { ButtonBase } from "@mui/material"
import type { ButtonBaseProps } from "@mui/material"
import { styled } from "@mui/material/styles"

interface FormOptionButtonProps extends ButtonBaseProps {
  selected?: boolean
}

export const FormOptionButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "selected",
})<FormOptionButtonProps>(({ theme, selected }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.typography.pxToRem(12),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: selected
    ? theme.palette.primary.light
    : theme.palette.background.default,
}))
