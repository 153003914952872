import React from "react"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"

import { Box } from "@mui/material"

import { useViewport } from "../../../contexts/viewportProvider"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
} from "../../../utils/constants"
import { If } from "../../if/if"
import { AppHeader } from "./appHeader/appHeader"
import { NavigationDrawer } from "./navigation-drawer/navigation-drawer"

const styles = {
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    padding: "64px 24px 24px",
    position: "relative",
  },
  contentOpen: {
    marginLeft: `${LEFT_NAV_EXPANDED_WIDTH}px`,
  },
  contentCollapsed: {
    marginLeft: `${LEFT_NAV_COLLAPSED_WIDTH}px`,
  },
}

interface IAppLayoutProps {
  children: React.ReactNode
}

export const AppLayout = ({ children }: IAppLayoutProps) => {
  const { organization } = useOrganizationContext()

  const { sitePreviews } = useSitePreviews(organization?.id)

  const { collapsed, setCollapsed } = useViewport()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }
  const contentClass = collapsed ? styles.contentCollapsed : styles.contentOpen

  return (
    <>
      <If condition={organization}>
        <AppHeader collapsed={collapsed} sitePreviews={sitePreviews} />
        <nav>
          <NavigationDrawer
            isOpen={!collapsed}
            organization={organization}
            toggleDrawer={toggleCollapsed}
          />
        </nav>
      </If>
      <Box component="main" sx={[styles.content, contentClass]}>
        {children}
      </Box>
    </>
  )
}
