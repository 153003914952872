import { createAndSortOptions } from "./sort-options"

const countyOptionsValues = [
  "__MISSING__",
  "AL, Baldwin County",
  "AL, Mobile County",
  "AL, Monroe County",
  "FL, Escambia County",
  "FL, Okaloosa County",
  "FL, Santa Rosa County",
]

const countyOptionsLabels = [
  "Unknown",
  "Baldwin County (AL)",
  "Mobile County (AL)",
  "Monroe County (AL)",
  "Escambia County (FL)",
  "Okaloosa County (FL)",
  "Santa Rosa County (FL)",
]

export const countyOptions = createAndSortOptions(
  countyOptionsValues,
  countyOptionsLabels
)
