import React from "react"
import type { ReactNode } from "react"
import type { LinkProps } from "react-router-dom"
import { Link } from "react-router-dom"

import { DemoDialog } from "@/components/demo-dialog/demo-dialog"
import { HStack, useDisclosure } from "@/nzds"
import { useAuthentication } from "@/services/authentication"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import { Box, Button, Divider, IconButton, Typography } from "@mui/material"

import { ShareButton } from "../../share-button/share-button"
import { SiteSearch } from "../site-search/site-search"
import { UserMenu } from "../userMenu/userMenu"

interface PageHeaderProps {
  actions?: ReactNode
  backLink?: LinkProps
  navigation?: ReactNode
  title: string
}

export const PageHeader = ({
  title,
  actions,
  navigation,
  backLink,
}: PageHeaderProps) => {
  const { authenticatedUser, isFreeUser } = useAuthentication()

  const {
    isOpen: demoDialogIsOpen,
    onClose: demoDialogOnClose,
    onOpen: demoDialogOnOpen,
  } = useDisclosure()

  return (
    <Box bgcolor="common.white" position="sticky" top={0} zIndex="appBar">
      <HStack
        alignItems="center"
        height={76}
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <HStack alignItems="center" gap={2}>
          {backLink ? (
            <IconButton
              aria-label={backLink.title}
              component={Link}
              size="small"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              state={backLink.state}
              to={backLink.to}
            >
              <ArrowBackIosNewRoundedIcon fontSize="small" />
            </IconButton>
          ) : null}
          <Typography data-e2e="page-header-title" variant="h1">
            {title}
          </Typography>
        </HStack>
        <Box alignItems="center" display="flex" gap={1}>
          {isFreeUser ? (
            <>
              <Button
                color="primary"
                onClick={demoDialogOnOpen}
                size="small"
                sx={{ mr: 1 }}
                variant="contained"
              >
                Book a Demo
              </Button>
              <DemoDialog open={demoDialogIsOpen} onClose={demoDialogOnClose} />
            </>
          ) : null}

          {!isFreeUser ? (
            <>
              <SiteSearch />
              <ShareButton iconButton title="Share URL" />
            </>
          ) : null}

          {authenticatedUser && <UserMenu />}
        </Box>
      </HStack>
      {navigation ? <>{navigation}</> : null}
      <Divider />
      {actions ? (
        <>
          <Box px={3} py={2}>
            {actions}
          </Box>
          <Divider />
        </>
      ) : null}
    </Box>
  )
}
