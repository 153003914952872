import React, { useCallback, useMemo } from "react"

import { FeatureFlags, useAvailableReportDates, useFeature } from "@/services"
import type { Moment } from "moment"
import moment from "moment"

import { WarningAmberRounded } from "@mui/icons-material"
import { Alert, Box, FormControl, FormLabel } from "@mui/material"

import { MonthRangeSelector } from "../../../../components/date/monthRangeSelector/monthRangeSelector"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import useFilters from "../../../../hooks/useFilters/useFilters"
import DateFilter from "../../../../models/filter/dateFilter"
import type { IRange } from "../../../../models/range"
import type {
  Gri302ReportTableRow,
  Gri303ReportTableRow,
  Gri305ReportTableRow,
  Gri306ReportTableRow,
  IGriFilters,
} from "../../models/gri"
import type { Report } from "../../models/report"
import { Card } from "../../models/report"
import { useGriReport } from "../../services/useGriReport/useGriReport"
import DataAlert from "../dataAlert/dataAlert"
import DataSummary from "../dataSummary/dataSummary"
import DownloadCsv from "../downloadCsv/downloadCsv"
import DownloadPreviewCsv from "../downloadPreviewCsv/downloadPreviewCsv"
import ReportLayout from "../reportLayout/reportLayout"

const Gri = () => {
  const { filters, setFilters } = useFilters<IGriFilters>({
    start: DateFilter,
    end: DateFilter,
  })

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const { availableReportDatesData } = useAvailableReportDates(organization?.id)

  const isDataUnderReview: boolean = isFeatureEnabled(
    FeatureFlags.REPORT_GRI_UNDER_REVIEW,
    organization
  )

  const isDataReady: boolean = isFeatureEnabled(
    FeatureFlags.REPORT_GRI_READY_TO_REPORT,
    organization
  )

  const { griReport, isGriReportLoading } = useGriReport(
    organization?.id,
    {
      start: filters.start.value,
      end: filters.end.value,
    },
    isDataReady
  )

  // Mass lint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const reports: Report<
    | Gri302ReportTableRow
    | Gri303ReportTableRow
    | Gri305ReportTableRow
    | Gri306ReportTableRow
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  >[] = useMemo(() => (griReport ? Object.values(griReport) : []), [griReport])

  const onDateChange = useCallback(
    (value: IRange<Moment>): void => {
      setFilters({
        start: new DateFilter(moment(value.start)),
        end: new DateFilter(moment(value.end)),
      })
    },
    [setFilters]
  )

  return (
    <ReportLayout
      actions={
        <span>
          {!isDataReady && (
            <DownloadPreviewCsv
              isDisabled={!griReport || isGriReportLoading}
              isLoading={isGriReportLoading}
              report={reports}
            />
          )}
          {isDataReady && (
            <DownloadCsv
              isDisabled={!griReport || isGriReportLoading}
              isLoading={isGriReportLoading}
              report={reports}
            />
          )}
        </span>
      }
      details={
        <DataSummary>
          <Box component="p" sx={{ mt: 0, mb: 2 }}>
            Your data download includes 4 individual CSV files for energy
            (GRI–302), water (GRI–303), emissions (GRI–305), and waste (GRI–306)
          </Box>
          <Box component="p" sx={{ m: 0 }}>
            Your emissions CSV (GRI-305) will include two data points for scope
            2 emissions:
          </Box>
          <Box component="ol" sx={{ mt: 0, mb: 2, pl: 2.5 }}>
            <li>Location based emissions (eGRID)</li>
            <li>NZero advanced grid study</li>
          </Box>
          <Alert
            icon={<WarningAmberRounded sx={{ color: "grey.800" }} />}
            severity="warning"
          >
            This download does not include market-based emissions. Contact us to
            explore adding them.
          </Alert>
        </DataSummary>
      }
      filters={
        <FormControl>
          <FormLabel sx={{ mb: 0.5 }}>Date Range</FormLabel>
          <MonthRangeSelector
            availableMaxMinMonths={availableReportDatesData}
            onChange={onDateChange}
            value={{
              start: filters.start.value,
              end: filters.end.value,
            }}
          />
        </FormControl>
      }
      notifications={
        <DataAlert
          isDataReady={isDataReady}
          isDataUnderReview={isDataUnderReview}
        />
      }
      organizationId={organization?.id}
      report={Card.Gri}
    />
  )
}

export default Gri
