import React from "react"

import { Box, Stack, Typography } from "@mui/material"

import SuccessImage from "../../../../assets/images/utilityCredentials/success.png"

export const SuccessPage: React.FC = () => (
  <Stack alignItems="center" direction="column" spacing={2}>
    <Box
      alt="success"
      component="img"
      height="175px"
      mb={1}
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src={SuccessImage}
      width="175px"
    />
    <Typography align="center" color="primary.main" variant="h1" width="80%">
      Thank you for submitting your credentials
    </Typography>
    <Typography align="center" color="grey.900" width="80%">
      We are validating your credentials and will let you know when the set up
      is complete. You may be contacted if any further verification is required.
    </Typography>
  </Stack>
)
