import React, { useState } from "react"
import type { FC } from "react"

import { DialogTitle } from "@/nzds/feedback/dialog-title/dialog-title"

import { Dialog, DialogContent, Skeleton } from "@mui/material"

import { demoCalendarUrl } from "./demo-dialog.types"
import type { DemoDialogProps } from "./demo-dialog.types"

export const DemoDialog: FC<DemoDialogProps> = ({ onClose, open }) => {
  const [isCalendarLoading, setIsCalendarLoading] = useState(true)

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      aria-describedby="demo-dialog-description"
      aria-labelledby="demo-dialog-title"
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { height: "calc(100% - 64px)" },
      }}
    >
      <DialogTitle id="demo-dialog-title" onClose={handleClose}>
        Book a Demo
      </DialogTitle>
      {
        // Prevent whitespace character from causing dialog scroll using `display: "flex"`
      }
      <DialogContent
        id="demo-dialog-description"
        sx={{ display: "flex", position: "relative" }}
      >
        {isCalendarLoading && (
          <Skeleton
            data-testid="demo-dialog-skeleton"
            height="auto"
            sx={{
              bottom: "24px",
              left: "24px",
              position: "absolute",
              right: "24px",
              top: 0,
              zIndex: 1,
            }}
            variant="rounded"
            width="auto"
          />
        )}
        <iframe
          height="100%"
          onLoad={() => {
            setIsCalendarLoading(false)
          }}
          src={demoCalendarUrl}
          style={{ border: 0 }}
          title="Book a demo calendar"
          width="100%"
        />
      </DialogContent>
    </Dialog>
  )
}
