import React from "react"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"

import { UtilityFormStateType } from "../../model/utilityCredentials"

interface CircleIconProps {
  formFilledState: string
}

const CircleIcon = ({ formFilledState }: CircleIconProps): JSX.Element => (
  <>
    {formFilledState === UtilityFormStateType.Incomplete && (
      <RadioButtonUncheckedIcon color="action" />
    )}
    {formFilledState === UtilityFormStateType.Complete && (
      <CheckCircleIcon color="primary" />
    )}
    {formFilledState === UtilityFormStateType.Error && (
      <ErrorIcon color="error" />
    )}
  </>
)

export default CircleIcon
