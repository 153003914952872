import z from "zod"

export const modelRetrofitsSchema = z.object({
  before: z.object({
    in_interior_lighting_generation: z.string().trim().optional(),
    in_energy_code_followed_during_last_hvac_replacement: z
      .string()
      .trim()
      .optional(),
    in_energy_code_followed_during_last_roof_replacement: z
      .string()
      .trim()
      .optional(),
    in_energy_code_followed_during_last_svc_water_htg_replacement: z
      .string()
      .trim()
      .optional(),
  }),
  after: z.object({
    in_energy_code_followed_during_last_hvac_replacement: z
      .string()
      .trim()
      .optional(),
    in_energy_code_followed_during_last_roof_replacement: z
      .string()
      .trim()
      .optional(),
    in_energy_code_followed_during_last_svc_water_htg_replacement: z
      .string()
      .trim()
      .optional(),
    in_interior_lighting_generation: z.string().trim().optional(),
  }),
})

export type ModelRetrofitsSchema = z.infer<typeof modelRetrofitsSchema>
