import type { FC, MouseEventHandler } from "react"
import React from "react"
import { Link } from "react-router-dom"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"

import CityStreetImageSrc from "../../../../assets/images/recommendations/city-street.png"
import { RootPath } from "../../../../models/route"
import { DialogTitle } from "../../../../nzds/feedback/dialog-title/dialog-title"

interface PlanConfirmationDialogProps {
  onClose: MouseEventHandler<HTMLButtonElement> | undefined
  open: boolean
}

export const PlanConfirmationDialog: FC<PlanConfirmationDialogProps> = ({
  open,
  onClose,
}) => (
  <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
    <DialogTitle id="plan-save-confirmation" onClose={onClose}>
      You&apos;re on Track!
    </DialogTitle>
    <DialogContent>
      <Box>
        <Box
          alt="success"
          component="img"
          mb={1}
          src={CityStreetImageSrc as string}
          width="200px"
        />
        <Typography>
          We’ve successfully added this plan to your roadmap.
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        component={Link}
        sx={{ mr: "auto" }}
        to={`${RootPath.Recommendations}`}
        variant="contained"
      >
        View portfolio overview
      </Button>
    </DialogActions>
  </Dialog>
)
