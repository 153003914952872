export const allCustomersTeamName = "All Customers"
export const allCustomersWorkspaceName = allCustomersTeamName

export interface SigmaAccessToken {
  accessToken: string
  expiresIn: number
  refreshToken: string
  tokenType: string
}

export interface SigmaEmbedConfig {
  email: string
  embedPath: string
  externalUserTeam: string
  organizationId: string
}

export interface SigmaPaginatedResponse<ResponseT> {
  entries: ResponseT[]
  hasMore: boolean
  nextPage: string | null
  total: number
}

export interface SigmaUserAttribute {
  key: string
  value: string
}

export interface SigmaWorkbook {
  createdAt: string
  createdBy: string
  id: string // We're adding id to the response, we shouldn't do this
  latestVersion: number
  name: string
  ownerId: string
  path: string // e.g. "Boyd", "Engineering", "ENVS"
  updatedAt: string
  updatedBy: string
  url: string
  workbookId: string
  workbookUrlId: string
}

export interface SigmaWorkbookEmbed {
  embedId: string
  embedUrl: string
  public: boolean
}

export interface SigmaWorkbookSecureEmbedUrlData {
  secureEmbedUrl: string
}

export interface SigmaWorkbookSecureEmbedUrlResponse {
  data: SigmaWorkbookSecureEmbedUrlData
  status: string
  title: string
}
