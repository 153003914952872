import React from "react"
import { Route, Routes } from "react-router-dom"

import { SiteExplorerIndexRoute } from "./pages/site-explorer-index-route/site-explorer-index-route"
import { SiteExplorerResultRoute } from "./pages/site-explorer-result-route/site-explorer-result-route"

/**
 * All routes included under `/recommendations/site-explorer`
 */
export const SiteExplorerRoute = () => (
  <Routes>
    <Route element={<SiteExplorerIndexRoute />} index />
    <Route element={<SiteExplorerResultRoute />} path="result" />
  </Routes>
)
