import type { ReactNode } from "react"
import React, { useEffect } from "react"

import { useLocalStorage } from "@uidotdev/usehooks"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import type Organization from "../../models/organization"
import { CIN_BELL_ID } from "../../models/organization"
import { useAuthentication, useAuthorizedOrganizations } from "../../services"
import type { UserInfo } from "../../utils"
import { updateUserSessionInfo } from "../../utils"
import { isCinBellUser } from "../../utils/user"

interface OrganizationResolverProps {
  children?: ReactNode
}

// This component is necessary because we always display the active org's name and sites in the left nav
// even when navigating to a route such as /settings that has nothing to do with the active org
// It's ultimately a UX issue... the nav shouldn't always display the active org's info
// Some routes either use multiple orgs (e.g. /dashboard?org=1,2,3) or no org (e.g. /settings)
// but our UI always display org/site info in the left navigation

/**
 * A component that ensures an active organization is set when it otherwise wouldn't be
 *
 * @param props - The component properties
 * @param props.children - The child nodes to render
 * @param props.user - The current user
 * @example
 * return (
 *   <OrganizationResolver>
 *     <ChildComponent>
 *   </OrganizationResolver>
 * )
 */
export const OrganizationResolver = ({
  children,
}: OrganizationResolverProps) => {
  const { organization, setOrganization } = useOrganizationContext()
  const { authenticatedUser } = useAuthentication()
  const {
    data: authorizedOrganizations,
    isFetched: isAuthorizedOrganizationsFetched,
  } = useAuthorizedOrganizations()

  const [userSessionInfo, setUserSessionInfo] = useLocalStorage<
    Record<string, UserInfo>
  >(`${process.env.REACT_APP_ENV}UserSessionInfo`, {})

  // Default to the first organization if the param is not set

  const storedOrganizationId =
    userSessionInfo[authenticatedUser?.id]?.organizationId

  useEffect(() => {
    const setDefaultOrganization = () => {
      let defaultOrgId: string =
        authenticatedUser?.organizationIds?.[0]?.toString() ?? ""
      const isCinBell: boolean = isCinBellUser(authenticatedUser)

      // Default Cincinnati Bell users to Cincinnati Bell org view
      if (isCinBell) {
        defaultOrgId = CIN_BELL_ID
      }

      if (!storedOrganizationId) {
        const defaultOrganization: Organization | undefined =
          authorizedOrganizations?.find((org) => org.id === defaultOrgId)

        if (defaultOrganization) {
          setOrganization(defaultOrganization)
          const updatedUserInfo = updateUserSessionInfo(
            userSessionInfo,
            authenticatedUser?.id,
            defaultOrganization.id
          )
          setUserSessionInfo(updatedUserInfo)
        }
      } else {
        const activeOrganization: Organization | undefined =
          authorizedOrganizations?.find(
            (org) => org.id === storedOrganizationId
          )

        if (activeOrganization) {
          setOrganization(activeOrganization)
        }
      }
    }

    // organizations/:orgId, sites/:siteId, reporting/:orgId all handle setting the active org from the route param
    // so if a full page refresh happens on these routes, the org will set to default if not stored in local storage, or set to the stored org
    // after the first render, then those routes will kick in, and set the org to something different if the set org is different
    if (
      isAuthorizedOrganizationsFetched &&
      authorizedOrganizations?.length &&
      !organization
    ) {
      setDefaultOrganization()
    }
  }, [
    authorizedOrganizations,
    isAuthorizedOrganizationsFetched,
    organization,
    setOrganization,
    authenticatedUser,
    storedOrganizationId,
    userSessionInfo,
    setUserSessionInfo,
  ])

  if (!organization) {
    return null
  }

  return <>{children}</>
}

export default OrganizationResolver
