import React, { useMemo } from "react"
import type { FC } from "react"

import { Page, PageCard, PageHeader } from "@/components"
import type { Schemas } from "@/services/types"

import { ExpandMore } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"

import { useRepBuildingCounts } from "../../services"

type OrgProfilesByRegulation = Record<
  Schemas["RegulationName"],
  Schemas["ProfileCountByRegulationOrg"][]
>

export const AdminRepAnalyticsRoute: FC = () => {
  const { data: repBuildingCounts, isLoading: repBuildingCountsIsLoading } =
    useRepBuildingCounts()

  const descendingRegulationProfileCounts = useMemo(() => {
    return repBuildingCounts
      ? [...repBuildingCounts.profiles_by_regulation].sort(
          (a, b) =>
            b.total_profiles_per_regulation - a.total_profiles_per_regulation
        )
      : []
  }, [repBuildingCounts])

  const orgProfileCountsByRegulation: OrgProfilesByRegulation = useMemo(() => {
    if (!repBuildingCounts) {
      return {} as OrgProfilesByRegulation
    }

    const profileCountsByRegulation = {} as OrgProfilesByRegulation

    repBuildingCounts.profiles_by_regulation_org.forEach((item) => {
      const regulationName = item.regulation_name

      if (!profileCountsByRegulation[regulationName]) {
        profileCountsByRegulation[regulationName] = []
      }

      profileCountsByRegulation[regulationName].push(item)
    })

    Object.values(profileCountsByRegulation).forEach((items) => {
      items.sort(
        (a, b) =>
          b.total_profiles_per_regulation_org -
            a.total_profiles_per_regulation_org ||
          a.organization_name.localeCompare(b.organization_name)
      )
    })

    return profileCountsByRegulation
  }, [repBuildingCounts])

  return (
    <>
      <PageHeader title="REP Analytics" />
      <Page>
        <PageCard>
          <Stack gap={1}>
            <Typography variant="h2">Site Uploads by Regulation</Typography>
            <Typography mb={1}>
              Counts of all sites uploaded to REP broken down by regulation and
              organization
            </Typography>
            <div>
              {repBuildingCountsIsLoading ? (
                <Skeleton height="200px" variant="rounded" />
              ) : (
                descendingRegulationProfileCounts.map((regulation) => {
                  return (
                    <Accordion key={regulation.regulation_name}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          component="span"
                          fontWeight="fontWeightBold"
                          mr={0.5}
                        >
                          {regulation.regulation_name}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ color: (theme) => theme.palette.grey[400] }}
                        >
                          ({regulation.total_profiles_per_regulation})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {orgProfileCountsByRegulation[
                          regulation.regulation_name
                        ].map((item) => {
                          return (
                            <div key={item.organization_id}>
                              <Typography component="span" mr={0.5}>
                                {item.organization_name ? (
                                  item.organization_name
                                ) : (
                                  <em>No Organization</em>
                                )}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{
                                  color: (theme) => theme.palette.grey[400],
                                }}
                              >
                                ({item.total_profiles_per_regulation_org})
                              </Typography>
                            </div>
                          )
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              )}
            </div>
          </Stack>
        </PageCard>
      </Page>
    </>
  )
}
