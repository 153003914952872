import type { Organization } from "@/models/organization"
import { client } from "@/services/client"
import type { Paths } from "@/services/types"

type ComstockPredictBody =
  Paths["/api/v1/recommendations/comstock_predict"]["post"]["requestBody"]["content"]["application/json"] & {
    organization_id: Organization["id"]
  }

export const comstockPredict = async (
  requestBody: Omit<ComstockPredictBody, "model_id">
) => {
  const bodyInternal: ComstockPredictBody = {
    ...requestBody,
    model_id: "nextera-demo-2023DEC04-2",
  }
  const { data, error } = await client.POST(
    "/api/v1/recommendations/comstock_predict",
    {
      body: bodyInternal,
    }
  )

  if (data) {
    return data
  }
  throw error as Error
}
