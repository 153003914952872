import React from "react"
import type { FC } from "react"

import Grid from "@mui/material/Unstable_Grid2"

import { useUnit } from "../../../../components/unit/use-unit"
import { UnitName } from "../../../../models/unit"
import { FormNumericTextField } from "../../../../nzds/forms/form-numeric-text-field/form-numeric-text-field"
import { FormSelectField } from "../../../../nzds/forms/form-select-field/form-select-field"
import { buildingTypeOptions } from "../../utils/comstock-building-types-list"
import { countyOptions } from "../../utils/county-options"
import { operatingHoursOptions } from "../../utils/operating-hours-options"
import type { SiteInputSchema } from "../../utils/site-input-schema"

export const SiteExplorerSiteInformationFields: FC = () => {
  const sqFtLabel = useUnit(UnitName.SquareFoot)
  const kwhLabel = useUnit(UnitName.KilowattHour)
  const thermLabel = useUnit(UnitName.Therm)
  return (
    <Grid container spacing={2}>
      <Grid xs={3}>
        <FormSelectField<SiteInputSchema>
          fullWidth
          label="Building Type"
          name="in_comstock_building_type"
          options={buildingTypeOptions}
          placeholder="Select building type"
          required
        />
      </Grid>
      <Grid xs={3}>
        <FormNumericTextField<SiteInputSchema>
          fullWidth
          label={`Building Area (${sqFtLabel})`}
          name="in_sqft"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
          placeholder={`Enter ${sqFtLabel}`}
          required
        />
      </Grid>
      <Grid xs={3}>
        <FormSelectField<SiteInputSchema>
          fullWidth
          label="County"
          name="in_county_name"
          options={countyOptions}
          placeholder="Select County"
          required
        />
      </Grid>
      <Grid xs={3}>
        <FormNumericTextField<SiteInputSchema>
          fullWidth
          label="Year Built"
          name="in_year_built"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
            thousandsGroupStyle: "none",
            thousandSeparator: "",
          }}
          placeholder="YYYY"
        />
      </Grid>

      <Grid xs={3}>
        <FormNumericTextField<SiteInputSchema>
          fullWidth
          label={`Annual Electricity Consumption (${kwhLabel})`}
          name="annualUsageElectric"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
          placeholder="Enter Annual Electricity Consumption"
        />
      </Grid>
      <Grid xs={3}>
        <FormNumericTextField<SiteInputSchema>
          fullWidth
          label={`Annual Natural Gas Consumption (${thermLabel})`}
          name="annualUsageGas"
          NumericFormatProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
          placeholder="Enter Annual Natural Gas Consumption"
        />
      </Grid>
      <Grid xs={3}>
        <FormSelectField<SiteInputSchema>
          fullWidth
          label="Operating hours"
          name="operatingHours"
          options={operatingHoursOptions}
          placeholder="Select operating hours"
        />
      </Grid>
    </Grid>
  )
}
