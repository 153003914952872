import { generatePath } from "react-router-dom"

import { RootPath } from "../../../models/route"
import { RecommendationsPath } from "../enums"

export const buildPlanDetailsHref = (id: number, site_id: number) =>
  generatePath(
    `${RootPath.Recommendations}/${RecommendationsPath.SitePlanEdit}`,
    {
      planId: `${id}`,
      siteId: `${site_id}`,
    }
  )
