import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ApiQueryName } from "@/models/api"
import type { Paths } from "@/services/types"

import { comstockPredict } from "./comstock-predict"

interface UseComstockPredictOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
  requestBody: Omit<
    Paths["/api/v1/recommendations/comstock_predict"]["post"]["requestBody"]["content"]["application/json"],
    "model_id"
  >
}

/**
 * Generates a prediction for a given set of inputs
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useComstockPredict({
 *   parameters: { input_data_before, input_data_after },
 *   queryOptions // react query mutation options
 * });
 *
 *
 */
export const useComstockPredict = ({
  requestBody,
  queryOptions,
}: UseComstockPredictOptions) => {
  const { organization } = useOrganizationContext()
  const body = {
    ...requestBody,
    organization_id: organization?.id,
  }

  const queryKey = [ApiQueryName.ComstockPredict, "getOne", body]

  let queryParams = {
    queryKey,
    queryFn: () => comstockPredict(body),
  }

  if (queryOptions) {
    queryParams = {
      ...queryOptions,
      ...queryParams,
    }
  }

  const query = useQuery(queryParams)

  return query
}
