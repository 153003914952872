import React from "react"
import { NavLink } from "react-router-dom"

import { NzDataGrid } from "@/components/nzDataGrid"
import { useOrganizationContext } from "@/contexts"
import { useSigmaWorkbooks } from "@/services"

import { Box, Link, useTheme } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"

import { Card } from "../../models/report"
import ReportingHeader from "../reportingHeader/reportingHeader"

const columns: GridColDef[] = [
  {
    headerName: "Report Name",
    field: "name",
    width: 320,
    renderCell: (params: GridRenderCellParams) => (
      <Link component={NavLink} to={`${params.id}`}>
        {params.value}
      </Link>
    ),
  },
]

const CustomReports = () => {
  const { organization } = useOrganizationContext()
  const { sigmaWorkbooksData, sigmaWorkbooksIsFetching } = useSigmaWorkbooks(
    organization.name
  )

  const theme = useTheme()

  return (
    <>
      <Box mb={2}>
        <ReportingHeader card={Card.Custom} organizationId={organization.id} />
      </Box>
      <Box
        sx={{
          bottom: theme.spacing(3),
          position: "absolute",
          top: theme.spacing(25.5),
          left: theme.spacing(3),
          right: theme.spacing(3),
        }}
      >
        <NzDataGrid
          columns={columns}
          isLoading={sigmaWorkbooksIsFetching}
          rows={sigmaWorkbooksData?.entries || []}
        />
      </Box>
    </>
  )
}

export default CustomReports
