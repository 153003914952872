import React from "react"
import { NavLink } from "react-router-dom"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { pxToRem } from "../../../../utils/unit"
import type { ICard } from "../../models/report"

interface ReportTwoColumnLayoutProps {
  actions: JSX.Element
  details: JSX.Element
  detailsTitle: string
  filters: JSX.Element
  notifications?: JSX.Element
  organizationId: string
  report: ICard
}

const styles = {
  column1Section: {
    padding: 2,
  },
}

const ReportTwoColumnLayout = ({
  actions,
  detailsTitle,
  details,
  filters,
  notifications,
  organizationId,
  report,
}: ReportTwoColumnLayoutProps): JSX.Element => (
  <Stack
    sx={{
      position: "absolute",
      top: pxToRem(64),
      bottom: pxToRem(24),
      left: pxToRem(24),
      right: pxToRem(24),
    }}
  >
    <Box alignItems="start" mb={2}>
      <Button
        component={NavLink}
        startIcon={<ChevronLeftRoundedIcon />}
        to={`/reporting/${organizationId}`}
      >
        Report Type Selection
      </Button>
    </Box>
    <Grid
      container
      spacing={2}
      sx={{
        flex: 1,
      }}
    >
      <Grid
        item
        sx={{
          minHeight: pxToRem(240),
          position: "relative",
          width: pxToRem(472),
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: pxToRem(16),
            bottom: 0,
            left: pxToRem(16),
            right: 0,
          }}
        >
          <Box sx={styles.column1Section}>
            <Typography
              data-e2e="report-header-title"
              variant={TYPOGRAPHY_VARIANT.h1}
            >
              {report.title}
            </Typography>
          </Box>
          <Divider />
          <Box
            data-e2e="resource-summary-left-grid-container"
            sx={{
              ...styles.column1Section,
              flex: 1,
              overflow: "auto",
            }}
          >
            <Typography mb={2}>{report.description}</Typography>
            {notifications && <Box mb={2}>{notifications}</Box>}
            <Divider sx={{ marginBottom: 2 }} />
            {filters}
          </Box>
          <Divider />
          <Box sx={styles.column1Section} textAlign="right">
            {actions}
          </Box>
        </Paper>
      </Grid>
      <Grid
        item
        sx={{
          minWidth: pxToRem(240),
          display: "flex",
          flexDirection: "column",
        }}
        xs
      >
        <Typography mb={1} variant={TYPOGRAPHY_VARIANT.h2}>
          {detailsTitle}
        </Typography>
        <Box sx={{ flex: 1, position: "relative" }}>
          {/* This is necessary for the MUI Grid to take up the available height */}
          <Box
            sx={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
          >
            {details}
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Stack>
)

ReportTwoColumnLayout.defaultProps = {
  notifications: "",
}

export default ReportTwoColumnLayout
