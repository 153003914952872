import type { FC } from "react"
import React from "react"
import { Link as RRLink, generatePath } from "react-router-dom"

import { Box, Card, Link, Stack, Typography, styled } from "@mui/material"

import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { RootPath } from "../../../../models/route"
import { RecommendationsPath } from "../../enums"
import type { SiteCardProps } from "./site-card.types"

const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  fontWeight: theme.typography.fontWeightBold,
  textTransform: "none",
})) as typeof Link

export const SiteCard: FC<SiteCardProps> = ({
  name,
  portfolioImpact,
  eui,
  siteId,
  ...rest
}) => {
  const { format: formatPercentage } = useFormatPercentage()
  const { format: formatDecimal } = useFormatDecimal()

  const portfolioImpactString = formatPercentage(portfolioImpact)
  const euiString = formatDecimal(eui ?? 0)

  const goalsLink = generatePath(
    `${RootPath.Recommendations}/${RecommendationsPath.SiteGoals}`,
    { siteId: `${siteId}` }
  )

  return (
    <Card {...rest} variant="contained">
      <Box p={2}>
        <Stack alignItems="flex-start" gap={2}>
          <Stack alignItems="flex-start" gap={1}>
            <Typography
              component="h3"
              fontWeight="fontWeightBold"
              variant="body1"
            >
              {name}
            </Typography>
            <Typography variant="body1">
              This site accounts for{" "}
              <Typography
                component="span"
                fontWeight="fontWeightBold"
                variant="body1"
              >
                {portfolioImpactString}
              </Typography>{" "}
              of the total emissions{eui ? ", " : ". "}
              {eui ? (
                <>
                  with an Energy Use Intensity (EUI) of{" "}
                  <Typography
                    component="span"
                    fontWeight="fontWeightBold"
                    variant="body1"
                  >
                    {euiString}
                  </Typography>
                  .
                </>
              ) : null}
            </Typography>
          </Stack>
          <LinkButton component={RRLink} to={goalsLink}>
            Plan Now
          </LinkButton>
        </Stack>
      </Box>
    </Card>
  )
}
