import React from "react"
import { Link } from "react-router-dom"

import knowledgeImageSrc from "@/assets/images/recommendations/knowledge.png"
import { PageCard, PageLoading } from "@/components"
import { PageTitle } from "@/components/page-title/page-title"
import { useOrganizationContext } from "@/contexts"
import { RootPath } from "@/models/route"
import { HStack } from "@/nzds"
import { useGetPlans } from "@/services"

import { AddCircleOutlineRounded } from "@mui/icons-material"
import { Button, Divider, Stack, Typography } from "@mui/material"

import { GoalsProgressBar } from "../../components/goals-progress-bar/goals-progress-bar"
import { PlannedSitesGrid } from "../../components/planned-sites-grid/planned-sites-grid"
import { PortfolioOverviewStats } from "../../components/portfolio-overview-stats/portfolio-overview-stats"
import { RecommendationsPath } from "../../enums"
import { useGetGoals } from "../../services/use-get-goals/use-get-goals"
import { useGetPotentialSites } from "../../services/use-get-potential-sites/use-get-potential-sites"
import { useSiteStats } from "../../services/use-site-stats/use-site-stats"
import { RecommendationsIndexRecommendedSites } from "./recommendations-index-recommended-sites"

export const RecommendationsIndexRoute = () => {
  const { organization } = useOrganizationContext()

  const hasOrg = Boolean(organization)

  const { data: goals, isLoading: isGoalsLoading } = useGetGoals({
    query: {
      organization_id: organization?.id,
    },
    useQueryOptions: {
      enabled: hasOrg,
    },
  })

  const orgReductionGoal = goals?.find((goal) => "reduction_goal" in goal)

  const { recommendationsPlansData, recommendationsPlansIsLoading } =
    useGetPlans()
  const { data: potentialSites, isLoading: isPotentialSitesLoading } =
    useGetPotentialSites()

  const isLoading =
    !hasOrg ||
    recommendationsPlansIsLoading ||
    isPotentialSitesLoading ||
    isGoalsLoading

  const stats = useSiteStats(recommendationsPlansData, potentialSites)

  const hasPlannedASite =
    recommendationsPlansData &&
    recommendationsPlansData.length > 0 &&
    potentialSites &&
    potentialSites.length > 0

  return (
    <PageLoading isLoading={isLoading}>
      <Stack gap={3}>
        <PageTitle
          actions={
            hasPlannedASite ? (
              <Button
                color="primary"
                component={Link}
                startIcon={<AddCircleOutlineRounded />}
                to={`${RootPath.Recommendations}/${RecommendationsPath.Plan}`}
                variant="contained"
              >
                Plan a site
              </Button>
            ) : undefined
          }
          title="Planning"
        />
        <PageCard>
          <Stack gap={4}>
            {hasPlannedASite ? (
              <>
                <PortfolioOverviewStats
                  plannedSites={recommendationsPlansData}
                  potentialSites={potentialSites}
                />
                <GoalsProgressBar
                  complete={stats.emissionSavingsPercent}
                  goal={orgReductionGoal}
                  total={1}
                />
                <RecommendationsIndexRecommendedSites
                  plannedSites={recommendationsPlansData}
                  potentialSites={potentialSites}
                />

                <PlannedSitesGrid sites={recommendationsPlansData} />
              </>
            ) : (
              <Stack gap={4}>
                <HStack alignItems="stretch" gap={3}>
                  <img
                    alt="books"
                    height={150}
                    role="presentation"
                    src={knowledgeImageSrc as string}
                    width={150}
                  />
                  <Stack data-testid="rec-index-empty-hero" gap={1}>
                    <Typography variant="h1">
                      Start your emissions reduction journey
                    </Typography>
                    <Typography variant="body1">
                      Take the guesswork out of building your decarbonization
                      plan.
                    </Typography>
                    <Button
                      color="primary"
                      component={Link}
                      startIcon={<AddCircleOutlineRounded />}
                      sx={{ alignSelf: "flex-start", mt: "auto" }}
                      to={`${RootPath.Recommendations}/${RecommendationsPath.Plan}`}
                      variant="contained"
                    >
                      Plan a site
                    </Button>
                  </Stack>
                </HStack>

                <Divider />

                <RecommendationsIndexRecommendedSites
                  plannedSites={recommendationsPlansData}
                  potentialSites={potentialSites}
                />
              </Stack>
            )}
          </Stack>
        </PageCard>
      </Stack>
    </PageLoading>
  )
}
