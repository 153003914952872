/**
 * @deprecated Use theme values instead (e.g. "light" (300), "regular" (400), "medium" (500) and "bold" (700))
 */
export enum FONT_WEIGHT {
  normal = 400,
  bold = 700,
}
/**
 * @deprecated - use theme typography instead (e.g. "h1", "caption", "dataLabel", etc.)
 */
export enum TYPOGRAPHY_VARIANT {
  body1 = "body1",
  body2 = "body2",
  caption = "caption",
  dataLabel = "dataLabel",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  hero = "hero",
}
