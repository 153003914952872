import React from "react"
import { NavLink } from "react-router-dom"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"

// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import type { ICard } from "../../models/report"

interface IReportingHeaderProps {
  card: ICard
  organizationId: string
}

const ReportingHeader = ({ card, organizationId }: IReportingHeaderProps) => (
  <>
    <Stack alignItems="start">
      <Button
        component={NavLink}
        startIcon={<ChevronLeftRoundedIcon />}
        to={`/reporting/${organizationId}`}
      >
        Report Type Selection
      </Button>
    </Stack>

    <Divider />
    <Box>
      <Typography data-e2e="report-header-title" mb={0} pt={2} variant="h1">
        {card.title}
      </Typography>
      <p>{card.description}</p>
    </Box>
  </>
)

export default ReportingHeader
