import z from "zod"

export const siteInputSchema = z.object({
  in_county_name: z.string().trim().min(1, { message: "County is required" }),
  in_sqft: z.string().trim().min(1, { message: "Square footage is required" }),
  in_comstock_building_type: z
    .string()
    .trim()
    .min(1, { message: "Building type is required" }),
  in_year_built: z.string().trim().optional(),
  annualUsageGas: z.string().trim().optional(),
  annualUsageElectric: z.string().trim().optional(),
  operatingHours: z.string().trim().optional(),
})

export type SiteInputSchema = z.infer<typeof siteInputSchema>
