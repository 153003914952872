import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Route, Routes } from "react-router-dom"

import { ErrorBoundaryFallback } from "../../components/error-boundary/error-boundary-fallback"
import { logError } from "../../components/error-boundary/error-boundary-fallback-utils"
import Page404 from "../../components/nav/page404/page404"
import { useOrganizationContext } from "../../contexts/organizationProvider"
import { FeatureFlags, useFeature } from "../../services"
import { RecommendationsPath } from "./enums"
import { RecommendationsIndexRoute } from "./pages/recommendations-index-route/recommendations-index-route"
import { RecommendationsPlanRoute } from "./pages/recommendations-plan-route/recommendations-plan-route"
import { SiteGoalsRoute } from "./pages/site-goals-route/site-goals-route"
import { SitePlanDetailsEditRoute } from "./pages/site-plan-details-edit-route/site-plan-details-edit-route"
import { SitePlanDetailsRoute } from "./pages/site-plan-details-route/site-plan-details-route"
import { SiteRecommendationsRoute } from "./pages/site-recommendations-route/site-recommendations-route"
import { SiteExplorerRoute } from "./site-explorer-routes"

/**
 * All routes included under `/recommendations`
 */
export const RecommendationsRoute = () => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isJumpstartEnabled = isFeatureEnabled(
    FeatureFlags.JUMPSTART,
    organization
  )

  return isFeatureEnabled(FeatureFlags.PLANNING, organization) ? (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
      <Routes>
        <Route element={<RecommendationsIndexRoute />} index />
        <Route
          element={<RecommendationsPlanRoute />}
          path={RecommendationsPath.Plan}
        />
        <Route
          element={<SiteGoalsRoute />}
          path={RecommendationsPath.SiteGoals}
        />
        <Route
          element={<SiteRecommendationsRoute />}
          path={RecommendationsPath.Site}
        />
        <Route
          element={<SitePlanDetailsEditRoute />}
          path={RecommendationsPath.SitePlanEdit}
        />
        <Route
          element={<SitePlanDetailsRoute />}
          path={RecommendationsPath.SitePlan}
        />
        {isJumpstartEnabled ? (
          <Route
            element={<SiteExplorerRoute />}
            path={`${RecommendationsPath.JumpStart}/*`}
          />
        ) : null}
        <Route element={<Page404 />} path="*" />
      </Routes>
    </ErrorBoundary>
  ) : (
    <Routes>
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}
