import type { FC } from "react"
import React from "react"

import type { CardProps } from "@mui/material"
import { Card, Divider, Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { HStack } from "../../../../nzds"
import type { StatCardProps } from "./site-explorer-stat-card.types"

const StyledStatCard = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  display: "flex",
  height: theme.typography.pxToRem(128),
}))

export const SiteExplorerStatCard: FC<StatCardProps> = ({
  sx,
  title,
  unit,
  to,
  from,
  showTo,
  percentageChange,
}) => {
  const { format } = useFormatPercentage({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  return (
    <StyledStatCard sx={sx} variant="contained">
      <Stack flex={1} gap={1}>
        <HStack alignItems="center" gap={0.5}>
          <Typography fontWeight="fontWeightBold" variant="body1">
            {title}
          </Typography>
          <Typography variant="body2">({unit})</Typography>
          {showTo && percentageChange ? (
            <Typography ml="auto" variant="body2">
              {format(percentageChange)}
            </Typography>
          ) : null}
        </HStack>
        <Divider />
        <HStack flex={1} gap={1}>
          {/* This helps to get ellipsis working :( https://css-tricks.com/flexbox-truncated-text/ */}
          <HStack flex={1} gap={3} width={0}>
            <Stack flex={1} minWidth={0}>
              <Typography variant="body2">From</Typography>
              <Typography component="p" noWrap variant="h1">
                {from}
              </Typography>
            </Stack>
            <Stack flex={1} minWidth={0}>
              <Typography variant="body2">To</Typography>
              {showTo ? (
                <Typography component="p" noWrap variant="h1">
                  {to ?? "--"}
                </Typography>
              ) : (
                "--"
              )}
            </Stack>
          </HStack>
        </HStack>
      </Stack>
    </StyledStatCard>
  )
}
