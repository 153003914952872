import type { FC } from "react"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link as RRLink, generatePath } from "react-router-dom"

import { DataGridColumnHeader } from "@/nzds/data-grid"

import SearchRoundedIcon from "@mui/icons-material/SearchRounded"
import { InputAdornment, Link } from "@mui/material"
import type {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"
import {
  DataGridPro,
  GridLogicOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro"

import { SectionHeader } from "../../../../components/section-header/section-header"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { RootPath } from "../../../../models/route"
import { TextField } from "../../../../nzds"
import { renderRowNumber } from "../../../../utils/render-row-number"
import { useUserSettings } from "../../../settings"
import { RecommendationsPath } from "../../enums"
import { calcuateAnnualSavings } from "../../utils/calculateAnnualSavings"
import { calcuateCarbonRemovedLbs } from "../../utils/calculateCarbonRemovedLbs"
import { calculateEmissionSavingsPercentage } from "../../utils/calculateEmissionSavingsPercentage"
import type { PlannedSitesCardProps, RowData } from "./planned-sites-grid.types"

const baseColumnDef: Partial<GridColDef> = {
  disableColumnMenu: true,
  hideable: false,
  disableReorder: true,
}

export const PlannedSitesGrid: FC<PlannedSitesCardProps> = ({ sites }) => {
  const { t } = useTranslation()
  const currencySymbol = useCurrencySymbol()
  const { currency } = useUserSettings()
  const apiRef = useGridApiRef()

  const [quickFilter, setQuickFilter] = React.useState("")

  useEffect(() => {
    apiRef.current.setQuickFilterValues(quickFilter.split(" "))
  }, [apiRef, quickFilter])

  const getPlanDetailsHref = (id: number, planId: number) =>
    `${generatePath(
      `${RootPath.Recommendations}/${RecommendationsPath.SitePlan}`,
      {
        siteId: `${id}`,
        planId: `${planId}`,
      }
    )}`

  const rows: RowData[] = useMemo(
    () =>
      (sites ?? []).map((site) => ({
        id: site.site_id,
        planId: site.id,
        name: site.site_name,
        capital_cost: site.capital_cost,
        annual_cost_savings: calcuateAnnualSavings(
          site.capital_cost,
          site.payback_period
        ),
        carbon_removed_lbs: calcuateCarbonRemovedLbs(
          site.base_total_emissions,
          site.total_emissions
        ),
        emission_savings: calculateEmissionSavingsPercentage(
          site.emissions_diff,
          site.base_total_emissions
        ),
        roi_year: site.payback_period,
      })),
    [sites]
  )

  const columnDefinitions = useMemo(() => {
    const columns: GridColDef[] = [
      {
        ...baseColumnDef,
        field: "number",
        align: "center",
        headerAlign: "center",
        sortable: false,
        headerName: "#",
        width: 44,
        renderCell: renderRowNumber,
      },
      {
        ...baseColumnDef,
        flex: 1,
        field: "name",
        align: "left",
        headerName: "Site name",
        width: 200,
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Link
            component={RRLink}
            noWrap
            to={getPlanDetailsHref(params.row.id, params.row.planId)}
          >
            {params.value}
          </Link>
        ),
      },
      {
        ...baseColumnDef,
        field: "capital_cost",
        align: "right",
        headerAlign: "right",
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`Capital cost (${currencySymbol})`}
            field={params.field}
            headerName="Capital cost"
            unit={currencySymbol}
          />
        ),
        width: 150,
        valueFormatter: (params: GridValueFormatterParams<RowData>) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          t("common:money", {
            value: params.value,
            formatParams: {
              value: {
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            },
          }),
      },
      {
        ...baseColumnDef,
        field: "annual_cost_savings",
        align: "right",
        headerAlign: "right",
        headerName: `Annual cost savings (${currencySymbol})`,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description={`Annual cost savings (${currencySymbol})`}
            field={params.field}
            headerName="Annual cost savings"
            unit={currencySymbol}
          />
        ),
        width: 200,
        valueFormatter: (params) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          t("common:money", {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value: params.value,
            formatParams: {
              value: {
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            },
          }),
      },
      {
        ...baseColumnDef,
        field: "emission_savings",
        align: "right",
        headerAlign: "right",
        headerName: "Emission Savings (%)",
        width: 200,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description="Emission Savings (%)"
            field={params.field}
            headerName="Emission Savings"
            unit="%"
          />
        ),
        valueFormatter: (params) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          t("common:percentage", {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value: params.value,
            style: "percent",
          }),
      },
      {
        ...baseColumnDef,
        field: "carbon_removed_lbs",
        align: "right",
        headerAlign: "right",
        headerName: "CO2e Removed (MT)",
        width: 225,
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description="CO2e Removed (MT)"
            field={params.field}
            headerName="CO2e Removed"
            unit="MT"
          />
        ),
        valueFormatter: (params) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          t("common:decimal", {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value: params.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
      },
      {
        ...baseColumnDef,
        field: "roi_year",
        align: "right",
        headerAlign: "right",
        headerName: "ROI (Years)",
        renderHeader: (params: GridColumnHeaderParams<RowData>) => (
          <DataGridColumnHeader
            description="ROI (Years)"
            field={params.field}
            headerName="ROI"
            unit="Years"
          />
        ),
        width: 125,
        valueFormatter: (params) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          t("common:decimal", {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value: params.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
      },
    ]

    return columns
  }, [currency, currencySymbol, t])

  return (
    <>
      <SectionHeader
        actions={
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setQuickFilter(e.target.value)
            }}
            size="small"
          />
        }
        title="Planned Sites"
      />
      <DataGridPro
        apiRef={apiRef}
        columnHeaderHeight={62}
        columns={columnDefinitions}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableVirtualization
        initialState={{
          sorting: {
            sortModel: [{ field: "emission_savings", sort: "desc" }],
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLogicOperator.Or,
            },
          },
        }}
        rowHeight={48}
        rows={rows}
      />
    </>
  )
}
