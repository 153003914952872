import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Link, TableBody, TableCell, TableRow } from "@mui/material"
import type { ClassNameMap } from "@mui/styles"

import { RootPath } from "../../models/route"
import type { ISitePreview } from "../../models/site"
import { If } from "../if/if"

interface TableBodyContentProps {
  classes: ClassNameMap
  isUseFlexibleHierarchyEnabled: boolean
  searchResults: ISitePreview[]
  searchTerm: string
}

export const SiteSearchResultsTableContent = ({
  searchResults,
  searchTerm,
  classes,
  // TODO: Remove this prop once the feature flag is removed
  isUseFlexibleHierarchyEnabled,
}: TableBodyContentProps) => (
  <TableBody>
    <If condition={searchResults?.length > 0}>
      {searchResults.map((row) => (
        <TableRow key={row.name}>
          <TableCell
            align="left"
            className={`${classes.leftCell} ${classes.alignLeft}`}
            data-e2e="site-search-results"
          >
            <Link component={RouterLink} to={`${RootPath.Site}/${row.id}`}>
              {row.name}
            </Link>
          </TableCell>
          <If condition={row.departmentName}>
            <TableCell
              align="left"
              className={`${classes.rightCell} ${classes.alignLeft}`}
            >
              <p className={`paragraph--medium__regular ${classes.cellText}`}>
                {isUseFlexibleHierarchyEnabled
                  ? row.topOrgUnitName
                  : row.departmentName}
              </p>
            </TableCell>
          </If>
          <If condition={!row.departmentName}>
            <TableCell
              align="left"
              className={`${classes.rightCell} ${classes.alignLeft}`}
            >
              <p className={`paragraph--medium__regular ${classes.cellText}`}>
                None
              </p>
            </TableCell>
          </If>
        </TableRow>
      ))}
    </If>
    <If condition={searchResults?.length === 0}>
      <TableRow>
        <TableCell
          align="left"
          className={`${classes.leftCell} ${classes.alignLeft}`}
        >
          <p className={`paragraph--medium__regular ${classes.cellText}`}>
            Sorry, no results for &quot;{searchTerm}&quot;
          </p>
        </TableCell>
        <TableCell
          align="left"
          className={`${classes.rightCell} ${classes.alignLeft}`}
        />
      </TableRow>
    </If>
  </TableBody>
)
