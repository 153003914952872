import React, { useMemo, useState } from "react"
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom"

import { PageHeader } from "@/components"
import { useToggle } from "@/services/toggle"
import moment from "moment"
import "moment-timezone"

import { Box, Slide, Tab, Tabs } from "@mui/material"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import { SitePath } from "../../models/route"
import type Site from "../../models/site"
import SiteInfo from "../../modules/site/components/siteInfo/siteInfo"
import SiteInfoButton from "../../modules/site/components/siteInfo/siteInfoButton/siteInfoButton"
import { useSiteExtraAttributes } from "../../modules/site/services/useSiteExtraAttributes/useSiteExtraAttributes"
import { TIME_RANGES } from "../../utils/constants"
import { pxToRem } from "../../utils/unit"
import type { DateRangeWithType } from "../graph/dateRangeWithType"
import type { DashboardContext } from "./types"

interface DashboardProps {
  site?: Site | null
  siteHasNoData: boolean
  siteTracksAndHasElectricity: boolean
  siteTracksAndHasGas: boolean
}

const currentTimeRange = (
  start: moment.Moment = null,
  end: moment.Moment = null
) => {
  if (window.location.search === "") {
    return null
  }
  return Math.abs(start.diff(end, "hours")) >= 48
    ? TIME_RANGES.MONTH
    : TIME_RANGES.DAY
}

/**
 *
 * @deprecated Delete after site_explorer is permanently live
 */
export const Dashboard: React.FC<DashboardProps> = ({
  site,
  siteTracksAndHasGas,
  siteTracksAndHasElectricity,
  siteHasNoData,
}) => {
  const params = useParams()
  const { organization } = useOrganizationContext()

  const isElectricityMatch = useMatch(`sites/:siteId/${SitePath.Electricity}`)
  const isEmissionsMatch = useMatch(`sites/:siteId/${SitePath.Emissions}`)
  const isNaturalGasMatch = useMatch(`sites/:siteId/${SitePath.NaturalGas}`)

  let selectedTab: SitePath | boolean = false

  if (isElectricityMatch) {
    selectedTab = SitePath.Electricity
  } else if (isEmissionsMatch) {
    selectedTab = SitePath.Emissions
  } else if (isNaturalGasMatch) {
    selectedTab = SitePath.NaturalGas
  }

  const { isOn: isSiteInfoOpen, toggle: toggleSiteInfo } = useToggle()
  const activeLocation = useLocation()
  const locationSearch = activeLocation.search
  const urlSearchParams = new URLSearchParams(locationSearch)
  const searchStartDate: moment.Moment = moment(
    urlSearchParams.get("start"),
    "YYYY-MM-DD"
  ).isValid()
    ? moment(urlSearchParams.get("start"), "YYYY-MM-DD")
    : moment(moment().startOf("month").format("YYYY-MM-DD"))
  const searchEndDate: moment.Moment = moment(
    urlSearchParams.get("end"),
    "YYYY-MM-DD"
  )
    .endOf("day")
    .isValid()
    ? moment(urlSearchParams.get("end"), "YYYY-MM-DD").endOf("day")
    : moment(moment().endOf("month").endOf("day").format("YYYY-MM-DD"))
  const [timeWindow, setTimeWindow] = useState<DateRangeWithType>({
    startDate: searchStartDate,
    endDate: searchEndDate,
    timeRange: currentTimeRange(searchStartDate, searchEndDate),
  })
  // Mass lint disable
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
  const [energyAllocations, setEnergyAllocations] = useState(null)
  const {
    siteExtraAttributes: {
      hasAmiMeter,
      hasAmrMeter,
      hasSubMeter,
      probeMeter,
    } = {
      hasAmiMeter: null,
      hasAmrMeter: null,
      hasSubMeter: null,
      probeMeter: null,
    },
  } = useSiteExtraAttributes(params.siteId)

  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDateRangeOver35Days, setIsDateRangeOver35Days] =
    useState<boolean>(false)

  const isMonthlySummaryVisible: boolean = useMemo(
    () => (hasAmrMeter && !hasAmiMeter) || isDateRangeOver35Days,
    [isDateRangeOver35Days, hasAmiMeter, hasAmrMeter]
  )

  if (!selectedTab) {
    return (
      <Navigate
        replace
        to={`${SitePath.Electricity}${activeLocation.search}`}
      />
    )
  }

  if (siteHasNoData && !isEmissionsMatch) {
    return (
      <Navigate replace to={`${SitePath.Emissions}${activeLocation.search}`} />
    )
  }

  if (isNaturalGasMatch && !siteTracksAndHasGas) {
    return null
  }
  if (isElectricityMatch && !siteTracksAndHasElectricity) {
    return null
  }

  if (siteHasNoData) {
    selectedTab = false
  }

  const outletContext: DashboardContext = {
    dateRange: {
      start: timeWindow.startDate,
      end: timeWindow.endDate,
    },
    isSiteInfoOpen,
    orgId: organization?.id,
    site,
    siteHasNoData,
    siteTracksAndHasGas,
    siteTracksAndHasElectricity,
    siteInfoButton: (
      <SiteInfoButton isOpen={isSiteInfoOpen} onClick={toggleSiteInfo} />
    ),
    electricity: {
      isMonthlySummaryVisible,
      siteHasSubMeter: hasSubMeter,
      siteIsAmi: hasAmiMeter,
      siteIsAmr: hasAmrMeter,
      siteIsProbe: probeMeter,
      setEnergyAllocations,
      setTimeWindow,
    },
  }

  return (
    site !== null &&
    // Avoid flashing and processing of previous site data when switching between sites
    // TODO: Need to refactor to use new site service to avoid this (useSite not working properly)
    site?.id === params.siteId &&
    hasAmrMeter !== null && (
      <>
        <PageHeader
          navigation={
            <Tabs
              aria-label="Site navigation"
              role="navigation"
              sx={{ px: 3 }}
              value={selectedTab}
            >
              <Tab
                component={Link}
                data-e2e={"emissions-tab"}
                disabled={siteHasNoData}
                label="Emissions"
                to={SitePath.Emissions}
                value={SitePath.Emissions}
              />
              {siteHasNoData || siteTracksAndHasElectricity ? (
                <Tab
                  component={Link}
                  data-e2e="electricity-tab"
                  disabled={siteHasNoData}
                  label="Electricity"
                  to={SitePath.Electricity}
                  value={SitePath.Electricity}
                />
              ) : null}
              {siteHasNoData || siteTracksAndHasGas ? (
                <Tab
                  component={Link}
                  data-e2e="natural-gas-tab"
                  disabled={siteHasNoData}
                  label="Natural Gas"
                  to={SitePath.NaturalGas}
                  value={SitePath.NaturalGas}
                />
              ) : null}
            </Tabs>
          }
          title={`${site?.name ?? ""}${
            site?.departmentName ? ", " + site.departmentName : ""
          }`}
        />
        <Outlet context={outletContext} />
        <Slide direction="left" in={isSiteInfoOpen}>
          <Box
            sx={{
              bottom: "24px",
              width: pxToRem(320),
              top: "72px",
              right: 0,
              position: "fixed",
              zIndex: 1200,
            }}
          >
            <SiteInfo onClose={toggleSiteInfo} site={site} />
          </Box>
        </Slide>
      </>
    )
  )
}
