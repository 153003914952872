export const operatingHoursOptions = [
  {
    label: "M-F 8-5",
    value: "M-F 8-5",
  },
  {
    label: "8-5 7 days a week",
    value: "8-5 7 days a week",
  },
  {
    label: "24/7",
    value: "24/7",
  },
]

export const operatingHoursMap = {
  "M-F 8-5": {
    in_weekday_operating_hours__hr: 9,
    in_weekend_operating_hours__hr: 0,
  },
  "8-5 7 days a week": {
    in_weekday_operating_hours__hr: 9,
    in_weekend_operating_hours__hr: 9,
  },
  "24/7": {
    in_weekday_operating_hours__hr: 24,
    in_weekend_operating_hours__hr: 24,
  },
}
