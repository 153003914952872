import type { FC } from "react"
import React from "react"
import { useParams } from "react-router-dom"

import { Paper } from "@mui/material"

import { DeveloperAdminPath, RootPath } from "../../../../models/route"
import { useOrganization } from "../../../../services"
import OrgViewDetail from "../../components/tamAdminOnboarding/orgView/orgViewDetail/orgViewDetail"
import OrgViewOrganizationDetail from "../../components/tamAdminOnboarding/orgView/orgViewOrganizationDetail/orgViewOrganizationDetail"

export const OverviewRoute: FC = () => {
  const params = useParams()
  const { orgId } = params
  const { organization } = useOrganization(orgId)

  return (
    <Paper
      sx={{
        flex: 1,
        maxWidth: { lg: "calc(100% - 8px)" },
        padding: (theme) => theme.typography.pxToRem(24),
      }}
      variant="outlined"
    >
      <OrgViewDetail
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dateCreated={organization?.dateCreated}
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dateUpdated={organization?.dateUpdated}
        datUrl={`${RootPath.DeveloperAdmin}${DeveloperAdminPath.Organization}/${organization?.id}`}
        entityName="Organization"
        webAppUrl={`${RootPath.Dashboard}/?org=${organization?.id}`}
      >
        <OrgViewOrganizationDetail organizationId={orgId} />
      </OrgViewDetail>
    </Paper>
  )
}
