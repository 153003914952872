import { useMemo } from "react"
import type { InfiniteData } from "react-query"

import type { Schemas } from "@/services/types"
import { random } from "lodash-es"

import { freeUserAllowedRows, freeUserBlurredRowsMax } from "../../constants"
import type { RowData } from "../../pages.types"
import { useResultsCta } from "../use-results-cta"

const generateBlurredRows = (count: number, records: RowData[]) => {
  const blurredRows: RowData[] = new Array(
    Math.min(count, freeUserBlurredRowsMax)
  )
    .fill(null)
    .map((row, index) => {
      return {
        ...records[random(0, records.length - 1)],
        id: (index + 1) * -1,
        pageIndex: index + freeUserAllowedRows, // For correct row number in grid
      }
    })

  return blurredRows
}

export function useComputedRows(
  data?: InfiniteData<Schemas["EmissionProfilePage"]>
) {
  const { isResultsCtaVisible } = useResultsCta(data)

  const computedRows = useMemo(() => {
    const pagesMap: Record<string, RowData[]> = {}
    if (data && data.pages.length > 0) {
      for (const page of data.pages) {
        const rows: RowData[] = []
        page.items.forEach((item, index) => {
          rows.push({
            ...item,
            page: page.page,
            pageSize: page.size,
            pageIndex: index,
            total: page.total,
          })
        })
        if (page.page) {
          pagesMap[page.page] = rows
        }
      }
      // return the rows in order
      const orderedRows: RowData[] = []
      for (const key of Object.keys(pagesMap).sort((a, b) =>
        a.localeCompare(b)
      )) {
        orderedRows.push(...pagesMap[key])
      }

      // Add blurred rows for free users
      if (isResultsCtaVisible) {
        const blurredRows = generateBlurredRows(
          data.pages[0].total - freeUserAllowedRows,
          orderedRows
        )

        blurredRows.forEach((row) => {
          orderedRows.push(row)
        })
      }

      return orderedRows
    }
    return []
  }, [data, isResultsCtaVisible])

  return computedRows
}
