import React from "react"
import { Link as RouterLink } from "react-router-dom"

import type { Moment } from "moment"

import { Link } from "@mui/material"

import DateFilter from "../../../models/filter/dateFilter"
import type { IDateFilters } from "../../../models/filter/filter"
import type { ISiteSummary } from "../../../models/monthSummary"
// Mass eslint disable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IRange } from "../../../models/range"
import { RootPath } from "../../../models/route"
import { UnitName } from "../../../models/unit"
import { CENTS_PER_DOLLAR } from "../../../utils/constants"
import {
  formatEnergyVolumeInUnit,
  transformPricePerWhIntoUnit,
} from "../../../utils/formatters"
import { getQueryStringFromFilters } from "../../../utils/navigation"
import { CurrencyWithPlaceholder } from "../../currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { DecimalWithPlaceholder } from "../../decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { Unit } from "../../unit/unit"

/**
 * @deprecated This UI is slated to be replaced and this component will be deleted
 */
export const SiteRow = (props: {
  dateRange: Moment[]
  hasBundled: boolean
  siteSummaryDatum: ISiteSummary
  whDisplayUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const { siteSummaryDatum, hasBundled, whDisplayUnit, dateRange } = props

  const totalCost: number = siteSummaryDatum.costMajor
  const energyCost: number = hasBundled
    ? totalCost
    : siteSummaryDatum.energyCostMinor / CENTS_PER_DOLLAR
  const meteredEnergy: number = formatEnergyVolumeInUnit(
    siteSummaryDatum.meteredKwh,
    UnitName.KilowattHour,
    whDisplayUnit
  )
  const avgEnergyCost: number = transformPricePerWhIntoUnit(
    !meteredEnergy ? 0 : energyCost / meteredEnergy,
    whDisplayUnit,
    UnitName.KilowattHour
  )
  const imbalanceCost: number =
    siteSummaryDatum.imbalanceCostMinor / CENTS_PER_DOLLAR
  const dateFilters: IDateFilters = {
    start: new DateFilter(dateRange[0]),
    end: new DateFilter(dateRange[1]),
  }

  return (
    <tr className="sites-table__site-row">
      <td data-site="name">
        <Link
          component={RouterLink}
          data-e2e="data-site-name-link"
          sx={{ textDecoration: "none" }}
          to={`${RootPath.Site}/${
            siteSummaryDatum.siteId
          }${getQueryStringFromFilters(dateFilters)}`}
        >
          {siteSummaryDatum.siteName}
        </Link>
      </td>
      <td data-e2e="data-site-cost-value" data-site="cost">
        <CurrencyWithPlaceholder format={{ precision: 0 }} value={totalCost} />
      </td>
      <td data-e2e="data-site-volume-value" data-site="volume">
        <DecimalWithPlaceholder
          format={{ precision: 0 }}
          value={meteredEnergy}
        />
        <span className="units-text">
          {" "}
          <Unit unit={whDisplayUnit} />
        </span>
      </td>
      <td data-e2e="data-site-avg-cost-value" data-site="avg-cost">
        <CurrencyWithPlaceholder value={avgEnergyCost} />
        <span className="units-text">
          <Unit unit={UnitName.PerKilowattHour} />
        </span>
      </td>
      {!hasBundled && (
        <td data-site="imbalance">
          <CurrencyWithPlaceholder
            format={{ precision: 0 }}
            value={imbalanceCost}
          />
        </td>
      )}
    </tr>
  )
}
