import React from "react"
import { Link } from "react-router-dom"

import { PageCard, PageLoading } from "@/components"

import { Button, Stack, Typography } from "@mui/material"

import knowledgeImageSrc from "../../../../assets/images/recommendations/knowledge.png"
import { PageTitle } from "../../../../components/page-title/page-title"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { RootPath } from "../../../../models/route"
import { HStack } from "../../../../nzds"
import { PotentialSitesGrid } from "../../components/potential-sites-card/potential-sites-grid"
import { useGetPotentialSites } from "../../services/use-get-potential-sites/use-get-potential-sites"

export const RecommendationsPlanRoute = () => {
  const { organization } = useOrganizationContext()

  const hasOrg = Boolean(organization)

  const { data: potentialSites, isLoading: isPotentialSitesLoading } =
    useGetPotentialSites()

  const isLoading = !hasOrg || isPotentialSitesLoading

  return (
    <PageLoading isLoading={isLoading}>
      <Stack gap={3}>
        <PageTitle
          back={{
            label: "Back to planning overview",
            to: `${RootPath.Recommendations}`,
          }}
          title="Select a Site to Start Decarbonization Planning"
        />
        {potentialSites && potentialSites.length > 0 ? (
          <PotentialSitesGrid sites={potentialSites} />
        ) : (
          <PageCard>
            <Stack gap={4}>
              <HStack alignItems="stretch" gap={3}>
                <img
                  alt="books"
                  height={150}
                  role="presentation"
                  src={knowledgeImageSrc as string}
                  width={150}
                />
                <Stack data-testid="rec-index-empty-hero" gap={1}>
                  <Typography variant="h1">No sites found</Typography>

                  <Typography variant="body1">
                    We are working on creating recommendations for your sites.
                    Please check back later.
                  </Typography>

                  <Button
                    color="primary"
                    component={Link}
                    sx={{ alignSelf: "flex-start", mt: "auto" }}
                    to={`${RootPath.Recommendations}`}
                    variant="outlined"
                  >
                    View planning overview
                  </Button>
                </Stack>
              </HStack>
            </Stack>
          </PageCard>
        )}
      </Stack>
    </PageLoading>
  )
}
