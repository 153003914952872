import { useMutation } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import type { AxiosError } from "axios"

import { ApiQueryName } from "../../../models/api"
import { postUtilityCredentials } from "../../../utils"

export interface UtilityCredentialsBody {
  data: {
    authentication_option?: string
    password?: string
    provider_type?: string
    security_question?: {
      answer?: string
      question?: string
    }
    token?: string
    two_factor_auth_notes?: string
    username?: string
    utility_provider?: string
  }
}

export const useUtilityCredentialsSignUp = (onError?: () => void) => {
  const mutationKey = useQueryKey(
    ApiQueryName.UtilityCredentialsSignUp,
    "createOne"
  )

  const mutation = useMutation<void, AxiosError, UtilityCredentialsBody>({
    mutationFn: async (data) => postUtilityCredentials(data),
    mutationKey,
    onError: () => {
      if (onError) {
        onError()
      }
    },
  })

  return mutation
}
