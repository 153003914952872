import React from "react"
import type { FC } from "react"

import moment from "moment"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { Box, Typography, useTheme } from "@mui/material"

import { useUnit } from "../../../../components/unit/use-unit"
import { UnitName } from "../../../../models/unit"
import type { SiteExplorerResultChartProps } from "./site-explorer-result-chart.types"

export const SiteExplorerResultChart: FC<SiteExplorerResultChartProps> = ({
  predictions,
  showAfter,
}) => {
  const predictionData = predictions ?? []
  const chartData: { after: number; before: number; day: number }[] = []
  for (const pData of predictionData) {
    chartData.push({
      day: moment(pData.usage_hour).valueOf(),
      before: Number(pData.energy_usage_kwh_before),
      after: Number(pData.energy_usage_kwh_after),
    })
  }

  const kwHlabel = useUnit(UnitName.KilowattHour)

  const theme = useTheme()
  return (
    <Box pt={3} width="100%">
      <ResponsiveContainer height={400} width="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke={theme.palette.grey[100]} />
          <XAxis
            dataKey="day"
            domain={["auto", "auto"]}
            label={{
              fill: theme.palette.text.primary,
              position: "bottom",
              style: {
                textAnchor: "middle",
                fontWeight: theme.typography.fontWeightBold,
              },
              value: "Date",
            }}
            minTickGap={40}
            name="Date"
            scale="time"
            stroke={theme.palette.neutral.light}
            style={{
              fontSize: theme.typography.dataLabel?.fontSize,
            }}
            tick={{ fill: theme.palette.neutral.dark }}
            tickFormatter={(value: string) => moment(value).format("MMM D YY")}
            type="number"
          />
          <YAxis
            label={{
              fill: theme.palette.text.primary,
              position: "left",
              style: {
                textAnchor: "middle",
                fontWeight: theme.typography.fontWeightBold,
              },
              angle: -90,
              value: `Enery Usage (${kwHlabel})`,
            }}
            name={`Enery Usage (${kwHlabel})`}
            stroke={theme.palette.neutral.light}
            style={{
              fontSize: theme.typography.dataLabel?.fontSize,
            }}
            tick={{ fill: theme.palette.neutral.dark }}
            tickFormatter={(value: string) => `${value} ${kwHlabel}`}
            type="number"
          />
          <Tooltip
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            labelFormatter={(label) => moment(label).format("MMM Do YYYY")}
          />
          <Legend
            align="left"
            formatter={(value) => (
              <Typography
                color="text.primary"
                component="span"
                fontWeight="fontWeightBold"
                variant="body2"
              >
                {value}
              </Typography>
            )}
            iconSize={28}
            iconType="plainline"
            verticalAlign="top"
            wrapperStyle={{
              top: "0",
            }}
          />
          <Line
            dataKey="before"
            dot={false}
            name="Usage Before"
            stroke={theme.palette.primary.main}
            strokeWidth="3"
            type="monotone"
          />
          {showAfter ? (
            <Line
              dataKey="after"
              dot={false}
              name="Usage After"
              stroke={theme.palette.spectrum.peach[300]}
              strokeWidth="3"
              type="monotone"
            />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}
