import { useCallback } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { ApiQueryName } from "@/models/api"
import type User from "@/models/user"
import { UserRoleName } from "@/models/user"
import { doLogin, doLogout, getUser } from "@/utils"

import { useActiveOrganizationId } from "../organization/useActiveOrganizationId"
import { getSessionTimeout } from "./useAuthenticationUtils"

export const getAuthenticatedUserQueryKey: string[] = [
  ApiQueryName.Authentication,
  "getAuthenticatedUser",
] as const
const signInMutationKey = [ApiQueryName.Authentication, "signIn"] as const
const signOutMutationKey = [ApiQueryName.Authentication, "signOut"] as const

export const useAuthentication = () => {
  const queryClient = useQueryClient()

  const { activeOrganizationId } = useActiveOrganizationId()

  // TODO: Set automatic refresh time.

  const signOutMutation = useCallback(async () => {
    await queryClient.cancelQueries(getAuthenticatedUserQueryKey)
    queryClient.setQueryData(ApiQueryName.IsSessionValid, false)
    queryClient.setQueryData(getAuthenticatedUserQueryKey, undefined)
    await doLogout()
    // TODO: Implement window.location.replace("/session_timeout") within respective component(s).
  }, [queryClient])

  const { data: isSessionValid, isLoading: isSessionValidLoading } = useQuery({
    cacheTime: Infinity,
    queryKey: ApiQueryName.IsSessionValid,
    queryFn: async () => {
      const sessionTimeout = await getSessionTimeout()
      if (sessionTimeout > 0) {
        return true
      }
      return false
    },
    staleTime: Infinity,
  })

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: authenticatedUser, isLoading: isAuthenticatedUserLoading } =
    useQuery({
      cacheTime: Infinity,
      queryKey: getAuthenticatedUserQueryKey,
      queryFn: async () => {
        const userResult: User = await getUser()
        if (!userResult) {
          // TODO: Are there any other checks we need here?
          // await signOutMutation()
          return undefined
        }
        return userResult
      },
      staleTime: Infinity,
    })

  const { mutateAsync: signOut } = useMutation({
    mutationKey: signOutMutationKey,
    mutationFn: signOutMutation,
  })

  const { mutateAsync: signIn, isLoading: isAuthenticating } = useMutation({
    mutationKey: signInMutationKey,
    mutationFn: async (credentials: { email: string; password: string }) => {
      await queryClient.cancelQueries(getAuthenticatedUserQueryKey) // TODO: Test this
      const loginResult = await doLogin({ data: credentials })
      if (!loginResult?.success) {
        return false
      }
      // Combine getUser and signIn so we don't have a re-render where both return isLoading: false
      const userResult: User = await getUser()
      if (!userResult) {
        // await signOutMutation()
        return false
      }
      queryClient.setQueryData(ApiQueryName.IsSessionValid, true)
      queryClient.setQueryData(getAuthenticatedUserQueryKey, userResult)
      return !!userResult
    },
  })

  const canEditSite: boolean = !authenticatedUser
    ? false
    : authenticatedUser.role === UserRoleName.Admin ||
      authenticatedUser.role === UserRoleName.BuildingManager

  const isGetAuthenticatedUserLoading =
    isSessionValidLoading || isAuthenticatedUserLoading || isAuthenticating

  // This is purposely a tri-state boolean
  // When the app first loads, we don't know if a user is authenticated until the api returns
  const isAuthenticated: boolean | undefined = isGetAuthenticatedUserLoading
    ? undefined
    : isSessionValid && !!authenticatedUser

  const isFreeUser: boolean | undefined = !authenticatedUser
    ? undefined
    : authenticatedUser.freeUser

  const isLimitedAccessUser: boolean | undefined =
    !authenticatedUser || !activeOrganizationId
      ? undefined
      : authenticatedUser.limitedAccessOrganizationIds.includes(
          Number(activeOrganizationId)
        )

  return {
    signIn,
    signOut,
    authenticatedUser,
    canEditSite,
    isFreeUser,
    isLimitedAccessUser,
    isLoading: isGetAuthenticatedUserLoading,
    isUserLoading: isGetAuthenticatedUserLoading,
    isGetAuthenticatedUserLoading,
    isAuthenticated,
    isAuthenticating,
  }
}
