import React from "react"
import type { FC } from "react"
import { Link } from "react-router-dom"

import { useAuthentication } from "@/services"

import AddRoundedIcon from "@mui/icons-material/AddRounded"
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded"
import { Button } from "@mui/material"

import { useDisclosure } from "../../nzds/utils/use-disclosure/use-disclosure"
import { ContactDialog } from "../contact-dialog"
import { If } from "../if/if"
import type { PlanningCellProps } from "./planning-cell.types"

export const PlanningCell: FC<PlanningCellProps> = ({ siteId, planId }) => {
  const { isFreeUser } = useAuthentication()

  const {
    isOpen: contactDialogIsOpen,
    onClose: contactDialogOnClose,
    onOpen: contactDialogOnOpen,
  } = useDisclosure()

  const handleFreeUserDecarbonizeSiteClick = (): void => {
    contactDialogOnOpen()
  }

  return (
    <>
      <If condition={isFreeUser}>
        <Button
          color="primary"
          onClick={handleFreeUserDecarbonizeSiteClick}
          size="small"
          startIcon={<AddRoundedIcon />}
          variant="outlined"
        >
          Decarbonize Site
        </Button>
        <ContactDialog
          open={contactDialogIsOpen}
          onClose={contactDialogOnClose}
          type="decarbonize"
        />
      </If>
      <If condition={!isFreeUser}>
        {planId ? (
          <Button
            color="neutral"
            component={Link}
            size="small"
            startIcon={<ExitToAppRoundedIcon />}
            to={`/recommendations/sites/${siteId}/plans/${planId}`}
            variant="text"
          >
            View Plan
          </Button>
        ) : (
          <Button
            color="primary"
            component={Link}
            size="small"
            startIcon={<AddRoundedIcon />}
            to={`/recommendations/sites/${siteId}/goals`}
            variant="outlined"
          >
            Decarbonize Site
          </Button>
        )}
      </If>
    </>
  )
}
