import React, { useCallback, useState } from "react"
import type { FC, MouseEvent } from "react"

import AddIcon from "@mui/icons-material/Add"
import { Button, Menu, MenuItem } from "@mui/material"

import { assistants } from "../../assistant.constants"
import type { NewThreadButtonProps } from "./new-thread-button.types"

export const NewThreadButton: FC<NewThreadButtonProps> = ({
  onMenuItemClick,
}) => {
  const [newThreadMenuEl, setNewThreadMenuEl] = useState<null | HTMLElement>(
    null
  )
  const isNewThreadMenuOpen = Boolean(newThreadMenuEl)

  const closeMenu = useCallback(() => {
    setNewThreadMenuEl(null)
  }, [])

  const handleNewThreadButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setNewThreadMenuEl(event.currentTarget)
    },
    []
  )

  const handleNewThreadMenuClose = useCallback(() => {
    closeMenu()
  }, [closeMenu])

  return (
    <>
      <Button
        onClick={handleNewThreadButtonClick}
        variant="outlined"
        startIcon={<AddIcon />}
      >
        New Conversation
      </Button>
      <Menu
        anchorEl={newThreadMenuEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isNewThreadMenuOpen}
        onClose={handleNewThreadMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {assistants.map((assistant) => (
          <MenuItem
            key={assistant.type}
            onClick={() => {
              closeMenu()
              onMenuItemClick(assistant.type)
            }}
          >
            {assistant.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
