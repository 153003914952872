import { useEffect } from "react"
import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"

import { useAuthentication } from "@/services/authentication"
import { useQueryKey } from "@/services/utils/useQueryKey"
import { useLocalStorage } from "@uidotdev/usehooks"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { ApiQueryName } from "../../../../models/api"
import type { UserInfo } from "../../../../utils/localStorage"
import { updateUserSessionInfo } from "../../../../utils/localStorage"
import { getSite } from "./get-site"

interface UseGetSiteOptions {
  queryOptions?: Omit<UseQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns a site by id
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetSite({ queryOptions }); // react query options
 */
export const useGetSite = (siteId: string, options: UseGetSiteOptions = {}) => {
  const { organization, setOrganization } = useOrganizationContext()
  const { authenticatedUser } = useAuthentication()
  const [userSessionInfo, setUserSessionInfo] = useLocalStorage<
    Record<string, UserInfo>
  >(`${process.env.REACT_APP_ENV}UserSessionInfo`, {})

  const hasOrg = Boolean(organization)

  const queryKey = useQueryKey(ApiQueryName.Site, "getOne", siteId)

  let queryParams = {
    queryKey,
    queryFn: () => getSite(siteId),
    enabled: hasOrg,
  }

  if (options.queryOptions) {
    queryParams = {
      ...options.queryOptions,
      ...queryParams,
    }
  }

  const queryResult = useQuery(queryParams)

  useEffect(() => {
    if (
      organization &&
      queryResult.data &&
      organization.id !== queryResult.data.organization.id
    ) {
      setOrganization(queryResult.data.organization)
      const updatedUserInfo = updateUserSessionInfo(
        userSessionInfo,
        authenticatedUser?.id,
        queryResult.data.organization.id
      )
      setUserSessionInfo(updatedUserInfo)
    }
  }, [
    authenticatedUser?.id,
    organization,
    queryResult.data,
    setOrganization,
    setUserSessionInfo,
  ])

  return queryResult
}
