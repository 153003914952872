import React, { useMemo, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

import { FeatureFlags } from "@/services/feature"

import FeatureGuard from "../../../../components/route/featureGuard/featureGuard"
import { useUtilityCredentials } from "../../services/useUtilityCredentials"
import AuthorizationGuard from "../authorizationGuard/authorizationGuard"
import UtilityCredentials from "../utilityCredentials/utilityCredentials"

const UtilityCredentialsRoute = (): JSX.Element => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const token: string = useMemo(() => searchParams.get("token"), [searchParams])
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const {
    utilityCredentials,
    isUtilityCredentialsError,
    isUtilityCredentialsFetched,
  } = useUtilityCredentials(
    token,
    () => {
      setIsDisabled(true)
    },
    { isDisabled }
  )

  return (
    <FeatureGuard feature={FeatureFlags.UTILITY_CREDENTIALS}>
      <Routes>
        <Route
          element={
            <AuthorizationGuard
              isError={isUtilityCredentialsError}
              isFetched={isUtilityCredentialsFetched}
            >
              <UtilityCredentials
                credentials={utilityCredentials}
                token={token}
              />
            </AuthorizationGuard>
          }
          path="/"
        />
      </Routes>
    </FeatureGuard>
  )
}

export default UtilityCredentialsRoute
