import React from "react"
import type { FC } from "react"

import { NewLayout } from "@/components/nav/appLayout/newLayout"
import Page404 from "@/components/nav/page404/page404"
import { useAuthentication } from "@/services"

export const AdminRoute: FC = () => {
  const { authenticatedUser } = useAuthentication()

  return authenticatedUser?.admin ? <NewLayout /> : <Page404 />
}
