import React from "react"
import { Navigate } from "react-router-dom"

import { useOrganizationContext } from "@/contexts/organizationProvider"

import { RootPath } from "../../models/route"

/**
 * A component that handles the "/organizations" route
 *
 * @param props - The component properties
 * @param props.organizationId - The active organization's id
 * @example
 * return (
 *   <OrganizationsRoute organizationId={activeOrganization.id} />
 * )
 */

const OrganizationsRoute = () => {
  const { organization } = useOrganizationContext()

  if (organization?.id) {
    return <Navigate to={`${RootPath.Organization}/${organization.id}`} />
  }

  // Don't render anything if there's no org id
  return <></>
}
export default OrganizationsRoute
