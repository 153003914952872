import React from "react"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"

import { useAuthentication } from "@/services/authentication"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import { RootPath } from "../../models/route"
import { FeatureFlags, useFeature } from "../../services"
import { If } from "../if/if"
import { Login } from "../login"
import OrganizationResolver from "../organizationResolver/organizationResolver"

const SignIn = (): JSX.Element => {
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation()
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isAuthenticated, isFreeUser, authenticatedUser } = useAuthentication()
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  const activeOrganizationId = organization?.id

  const [searchParams] = useSearchParams()

  if (isAuthenticated === undefined) {
    return null
  }

  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isLimitedAccessUser: boolean =
    authenticatedUser &&
    !isFreeUser &&
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    !!authenticatedUser.limitedAccessOrganizationIds.includes(
      Number(activeOrganizationId)
    )

  const isLimitedAccessUserBasicsEnabled = isFeatureEnabled(
    FeatureFlags.LIMITED_ACCESS_USER_BASICS,
    organization
  )

  const isUserRedirectedToSite =
    isLimitedAccessUser && !isLimitedAccessUserBasicsEnabled

  let landingPage: string = RootPath.Dashboard

  if (isUserRedirectedToSite) {
    landingPage = RootPath.Site
  }

  if (isFreeUser) {
    landingPage = RootPath.Rep
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const redirectPath = searchParams.get("from")
    ? searchParams.get("from")
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      state?.from?.pathname

  // Don't go back to "/not-found" after a login because it's not helpful and user can get stuck in a loop
  // migration to strict mode batch disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const landingPath: string =
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    redirectPath && redirectPath !== RootPath.NotFound
      ? // Mass lint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        redirectPath
      : landingPage

  return (
    <>
      <If condition={!isAuthenticated}>
        <Login />
      </If>
      <If condition={isAuthenticated}>
        <If condition={!activeOrganizationId}>
          <OrganizationResolver />
        </If>
        <If condition={activeOrganizationId}>
          <Navigate to={landingPath} />
        </If>
      </If>
    </>
  )
}

export default SignIn
