import React from "react"
import { Route, Routes } from "react-router-dom"

import SiteRoute from "../../components/site/siteRoute/siteRoute"
import OldSites from "../../modules/site/components/sites/oldSites"

/**
 * A component that handles the "/sites/*" route
 *
 * @deprecated Delete after site_explorer is permanently live
 * @example
 * return (
 *   <OldSitesRoute />
 * )
 */
export const OldSitesRoutes = () => {
  return (
    <Routes>
      <Route element={<OldSites />} index />
      <Route element={<SiteRoute />} path=":siteId/*" />
    </Routes>
  )
}
