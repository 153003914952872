import React, { createContext, useCallback, useContext, useMemo } from "react"

import { useAuthentication } from "@/services/authentication"

import { useInterval } from "../nzds/utils/use-interval/use-interval"
import { getCurrentAppUrl, httpClient } from "../utils"
import { queryClient } from "./queryClient"

interface LogoutContextValue {
  logout: () => Promise<void> | undefined
}

interface LogoutProviderProps {
  children: React.ReactNode
}

// TODO: Refactor to be part of useAuthentication
// instead of this separate LogoutProvider
const LogoutContext = createContext<LogoutContextValue>(undefined)

export const LogoutProvider = ({ children }: LogoutProviderProps) => {
  const { signOut } = useAuthentication()

  const logout = useCallback(async (): Promise<void> => {
    await queryClient.cancelQueries()
    await signOut()
    queryClient.clear()

    window.location.replace(
      `/session_timeout?from=${encodeURIComponent(getCurrentAppUrl())}`
    )
  }, [signOut])

  const checkSessionTimeout = useCallback<() => void>((): void => {
    httpClient
      .get("/check_session_timeout")
      .then((response) => {
        if (response.status === 200 && response.data <= 0) {
          logout().catch((e) => {
            console.error(e)
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [logout])

  const pollMinutes: number =
    Number.parseFloat(
      process.env.REACT_APP_EXPIRATION_CHECK_INTERVAL_MINUTES
    ) || 5
  const pollMilliseconds: number = pollMinutes * 60 * 1000

  useInterval(checkSessionTimeout, pollMilliseconds)

  const value: LogoutContextValue = useMemo(
    () => ({
      logout,
    }),
    [logout]
  )

  return (
    <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>
  )
}

export const useLogoutContext = (): LogoutContextValue => {
  const context = useContext(LogoutContext)
  if (context === undefined) {
    throw new Error("useLogoutContext must be used within a LogoutProvider")
  }
  return context
}
