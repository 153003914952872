import React from "react"

import { Box, Paper, Stack, Typography } from "@mui/material"

import AuthErrorImage from "../../../../assets/images/utilityCredentials/auth-error-image.png"
import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import { pxToRem } from "../../../../utils/unit"

const UnAuthorizationPage = (): JSX.Element => (
  <Box
    alignItems="center"
    display="flex"
    height="100vh"
    justifyContent="center"
    width="100vw"
  >
    <Paper
      elevation={4}
      sx={{
        height: pxToRem(452),
        width: pxToRem(452),
        p: 4.5,
      }}
    >
      <Stack alignItems="center">
        <Box
          alt="Error"
          component="img"
          height={176}
          mb={3}
          // migration to strict mode batch disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src={AuthErrorImage}
          width={176}
        />

        <Typography
          color="error"
          sx={{ mb: 2 }}
          variant={TYPOGRAPHY_VARIANT.h1}
        >
          Expired Link
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          Please contact support at support email to generate a new link.
        </Typography>
      </Stack>
    </Paper>
  </Box>
)

export default UnAuthorizationPage
