import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Link, Stack, Typography } from "@mui/material"

interface ErrorPageProps {
  errorCode: number
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentUrl = `${location.pathname}${location.search}`

  return (
    <Stack alignItems="center" direction="column" spacing={2} width="100%">
      <Typography
        align="center"
        color="neutral.main"
        sx={{ fontSize: 64 }}
        variant="h1"
        width="80%"
      >
        {errorCode}
      </Typography>
      <Typography align="center" color="grey.900" width="80%">
        Sorry, our server has encountered an error.
      </Typography>
      <Link
        href={currentUrl}
        onClick={() => {
          navigate(0)
        }}
      >
        Refresh Page
      </Link>
    </Stack>
  )
}
