import { useQuery } from "react-query"
import type { UseQueryOptions } from "react-query"

import { ApiQueryName } from "@/models/api"
import type { Schemas } from "@/services/types"
import { useRenameQueryResult } from "@/services/utils"
import { createQueryKey } from "@/services/utils/createQueryKey"

import type { Thread } from "../../assistant.types"

export type GetThreadsParams = Schemas["ThreadRequest"]

const placeholderThreads: Thread[] = [
  {
    assistantId: "1",
    messages: [
      {
        content: "Placeholder conversation description text that is long",
        role: "user",
      },
      {
        content: "Hi, I'm here to help!",
        role: "assistant",
      },
    ],
    organizationId: "1",
    specialty: "default",
    status: "active",
    threadId: "1",
    userId: "1",
  },
  {
    assistantId: "2",
    messages: [
      {
        content: "Placeholder conversation description text that is long",
        role: "user",
      },
      {
        content: "Hi, I'm here to help!",
        role: "assistant",
      },
    ],
    organizationId: "2",
    specialty: "default",
    status: "active",
    threadId: "2",
    userId: "2",
  },
  {
    assistantId: "3",
    messages: [
      {
        content: "Placeholder conversation description text that is long",
        role: "user",
      },
      {
        content: "Hi, I'm here to help!",
        role: "assistant",
      },
    ],
    organizationId: "3",
    specialty: "default",
    status: "active",
    threadId: "3",
    userId: "3",
  },
  {
    assistantId: "4",
    messages: [
      {
        content: "Placeholder conversation description text that is long",
        role: "user",
      },
      {
        content: "Hi, I'm here to help!",
        role: "assistant",
      },
    ],
    organizationId: "4",
    specialty: "default",
    status: "active",
    threadId: "4",
    userId: "4",
  },
  {
    assistantId: "5",
    messages: [
      {
        content: "Placeholder conversation description text that is long",
        role: "user",
      },
      {
        content: "Hi, I'm here to help!",
        role: "assistant",
      },
    ],
    organizationId: "5",
    specialty: "default",
    status: "active",
    threadId: "5",
    userId: "5",
  },
]

/**
 * Gets a list of threads
 */
export const getThreads = async (
  params: GetThreadsParams
): Promise<Thread[]> => {
  console.warn("getThreads is not implemented")
  console.warn("getThreads params is not implemented", params)

  return new Promise<Thread[]>((resolve) => {
    setTimeout(() => {
      resolve(placeholderThreads)
    }, 2000)
  })
}

interface UseThreadsOptions {
  params: GetThreadsParams
  useQueryOptions?: Omit<UseQueryOptions<Thread[]>, "queryFn" | "queryKey">
}

/**
 * Initializes and returns an assistant thread
 */
export const useThreads = (options: UseThreadsOptions) => {
  const queryKey = createQueryKey(
    ApiQueryName.AssistantThreads,
    "getMany",
    null,
    options.params
  )

  const queryResult = useQuery<Thread[]>({
    ...options.useQueryOptions,
    // Initialization is a one time operation
    cacheTime: Infinity,
    keepPreviousData: true,
    placeholderData: placeholderThreads,
    queryKey,
    queryFn: async () => getThreads(options.params),
    // We don't want to auto re-fresh the data
    staleTime: Infinity,
  })

  return useRenameQueryResult(queryResult, ApiQueryName.AssistantThreads)
}
