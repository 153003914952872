import type { Assistant } from "./assistant.types"

export const assistants: Assistant[] = [
  {
    description: "Analyze your portfolio",
    name: "Portfolio",
    type: "default",
  },
  {
    description: "Analyze EUI and benchmarks",
    name: "EUI & Benchmarks",
    type: "eui-analysis",
  },
  {
    description: "Analyze trends",
    name: "Trends",
    type: "trend-analysis",
  },
  {
    description: "Normalize your weather data",
    name: "Weather",
    type: "weather-normalization",
  },
]
