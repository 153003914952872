import { useQuery } from "react-query"

import { useQueryKey } from "@/services/utils/useQueryKey"
import { useRenamedQueryResult } from "@/services/utils/useRenamedQueryResult/useRenamedQueryResult"

import { ApiQueryName } from "../../../models/api"
import { fetchUtilityCredentials } from "../../../utils"

export const useUtilityCredentials = (
  token: string,
  onError: () => void,
  options: { isDisabled: boolean }
) => {
  const queryKey = useQueryKey(ApiQueryName.UtilityCredentials, "getOne", token)

  const queryResult = useQuery({
    keepPreviousData: true,
    retry: false,
    queryKey,
    queryFn: async () => fetchUtilityCredentials(token),
    enabled: !!token && !options.isDisabled,
    onError: () => {
      onError()
    },
  })

  return useRenamedQueryResult(queryKey, queryResult)
}
