import type { Context, ReactNode } from "react"
import React, { createContext, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, useFeature } from "@/services/feature"

import type Organization from "../models/organization"

export type OrganizationsById = Record<string, Organization>

export interface IOrganizationContext {
  organization: Organization | null
  setOrganization: (organization: Organization | null) => void
}

export interface OrganizationProviderProps
  extends Partial<IOrganizationContext> {
  children: ReactNode
}

// Mass lint disable
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const OrganizationContext: Context<IOrganizationContext> =
  createContext(undefined)

/**
 * A component that provides the organization context/service
 * @deprecated
 * @param props - The component properties
 * @param props.children - The child nodes to render
 * @example
 * return (
 *   <OrganizationProvider>
 *     <Home />
 *   </OrganizationProvider>
 * )
 */

export const OrganizationProvider = (props: OrganizationProviderProps) => {
  const { i18n } = useTranslation()
  const { isFeatureEnabled } = useFeature()

  const [organization, _setOrganization] = useState<Organization | null>(
    props.organization
  )

  // Custom org setter to ensure language is set whenever the active org is set
  const setOrganization = useCallback(
    (org: Organization | null): void => {
      if (
        !isFeatureEnabled(FeatureFlags.SETTINGS_LANGUAGE_SELECTOR, organization)
      ) {
        if (org?.locale) {
          i18n.changeLanguage(org.locale.name).catch((e) => {
            console.error(e)
          })
        }
      }

      _setOrganization(org)
    },
    [_setOrganization, i18n, isFeatureEnabled, organization]
  )

  // Don't bother fixing because this provider will be deleted in the future
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: IOrganizationContext = {
    organization,
    setOrganization: props.setOrganization || setOrganization,
  }

  return (
    <OrganizationContext.Provider value={value}>
      {props.children}
    </OrganizationContext.Provider>
  )
}

export const useOrganizationContext = () =>
  React.useContext(OrganizationContext)
