import { createAndSortOptions } from "./sort-options"

const energyCodeValues = [
  "ComStock DOE Ref Pre-1980",
  "ComStock DOE Ref 1980-2004",
  "ComStock 90.1-2007",
  "ComStock 90.1-2010",
  "ComStock 90.1-2013",
  "__MISSING__",
]

const energyCodeLabels = [
  "Pre-1980",
  "1980-2004",
  "2004-2007",
  "2007-2010",
  "2010-2013",
  "Unknown",
]

export const energyCodeOptions = createAndSortOptions(
  energyCodeValues,
  energyCodeLabels,
  { sort: false }
)
