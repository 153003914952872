import React from "react"

import type { FeatureFlags } from "@/services/feature"
import { useFeature } from "@/services/feature"
import {
  useActiveOrganizationId,
  useOrganization,
} from "@/services/organization"

import Page404 from "../../nav/page404/page404"

interface FeatureGuardProps {
  children: JSX.Element
  feature: FeatureFlags
}

const FeatureGuard = ({
  children,
  feature,
}: FeatureGuardProps): JSX.Element => {
  const { activeOrganizationId } = useActiveOrganizationId()
  const { organization } = useOrganization(activeOrganizationId)
  const { isFeatureEnabled } = useFeature()
  if (activeOrganizationId && !organization) {
    return <></>
  }
  // TODO: Remove any type when isFeatureEnabled has new types
  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  const isFeaturePresent = isFeatureEnabled(feature, organization as any)
  if (isFeaturePresent) {
    return children
  }

  return <Page404 />
}

export default FeatureGuard
