import { useQuery } from "react-query"

import { ApiQueryName } from "../../models/api"
import { useRenameQueryResult } from "../utils"
import { createQueryKey } from "../utils/createQueryKey"
import type { SigmaWorkbookEmbed } from "./sigma.types"
import { useSigmaApi } from "./useSigmaApi"

/**
 *
 * @returns Results of the Sigma workbookId embeds endpoint (workbook embed url available for the organization, based on
 * the organization's Sigma client ID and API secret)
 */
export const useSigmaWorkbookEmbedUrl = (workbookId: string) => {
  const { fetchSigmaWorkbookEmbedUrl, sigmaAccessTokenIsFetched } =
    useSigmaApi()
  const enabled = sigmaAccessTokenIsFetched && !!workbookId
  const queryKey = createQueryKey(
    ApiQueryName.SigmaWorkbookEmbedUrl,
    "getOne",
    workbookId
  )
  const queryResult = useQuery<SigmaWorkbookEmbed>({
    queryKey: [queryKey],
    queryFn: () => fetchSigmaWorkbookEmbedUrl(workbookId),
    enabled,
  })

  return useRenameQueryResult(queryResult, ApiQueryName.SigmaWorkbookEmbedUrl)
}
