import React from "react"
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"

import { PageLoading } from "@/components"

import { Stack } from "@mui/material"

import { StyledPageCard } from "../../../../components/page-card/styled-page-card"
import { PageTitle } from "../../../../components/page-title/page-title"
import { RootPath } from "../../../../models/route"
import { HStack } from "../../../../nzds"
import { useDisclosure } from "../../../../nzds/utils/use-disclosure/use-disclosure"
import SiteInfoButton from "../../../site/components/siteInfo/siteInfoButton/siteInfoButton"
import { EditSiteConfirmation } from "../../components/edit-site-information/edit-site-confirmation"
import { PlanningSteps } from "../../components/planning-steps/planning-steps"
import { SiteGoalsForm } from "../../components/site-goals-form/site-goals-form"
import { SiteInfoDrawer } from "../../components/site-info-drawer/site-info-drawer"
import {
  drawerContainerGap,
  drawerWidth,
} from "../../components/site-info-drawer/site-info-drawer.constants"
import { RecommendationsPath } from "../../enums"
import { useGetGoals } from "../../services/use-get-goals/use-get-goals"
import { useGetPhysicalDetailTypes } from "../../services/useGetPhysicalDetailTypes/use-get-physical-detail-types"
import { useGetSite } from "../../services/useGetSite/use-get-site"

export const SiteGoalsRoute = () => {
  const { siteId } = useParams<{ siteId: string }>()

  const {
    onToggle: onToggleDrawer,
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: site, isLoading: isSiteLoading } = useGetSite(siteId!)

  const { data: goals, isLoading: isGoalsLoading } = useGetGoals({
    query: {
      site_id: siteId,
    },
    useQueryOptions: {
      enabled: Boolean(siteId),
    },
  })

  const { data: physicalDetailTypes, isLoading: isPhysicalDetailTypesLoading } =
    useGetPhysicalDetailTypes()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isLoading =
    isSiteLoading || isPhysicalDetailTypesLoading || isGoalsLoading

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const siteRecommendationsHref = generatePath(
    `${RootPath.Recommendations}/${RecommendationsPath.Site}`,
    { siteId: siteId ?? null }
  )

  return (
    <PageLoading isLoading={isLoading}>
      {site && goals ? (
        <Stack gap={3}>
          <PageTitle
            actions={
              <SiteInfoButton isOpen={isDrawerOpen} onClick={onToggleDrawer} />
            }
            back={{
              to: `${RootPath.Recommendations}/${RecommendationsPath.Plan}`,
              label: "Go back to plan a site",
            }}
            title={`Set goals for ${site.name}`}
          />
          <HStack gap={2}>
            <StyledPageCard
              offset={drawerWidth + drawerContainerGap}
              open={isDrawerOpen}
            >
              <PlanningSteps activeStep={1} siteId={siteId} sx={{ mb: 3 }} />
              <EditSiteConfirmation
                onClose={onClose}
                onContinue={() => {
                  navigate(
                    `${siteRecommendationsHref}?${searchParams.toString()}`
                  )
                }}
                open={isOpen}
              />
              {physicalDetailTypes ? (
                <SiteGoalsForm
                  goals={goals}
                  onSubmitSuccess={() => {
                    onOpen()
                  }}
                  physicalDetailTypes={physicalDetailTypes}
                  site={site}
                />
              ) : null}
            </StyledPageCard>
            <SiteInfoDrawer
              onClose={onDrawerClose}
              open={isDrawerOpen}
              site={site}
            />
          </HStack>
        </Stack>
      ) : null}
    </PageLoading>
  )
}
