import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import { ApiQueryName } from "@/models/api"

import { useRenameQueryResult } from "../../utils"
import { createQueryKey } from "../../utils/createQueryKey"
import type { SigmaAccessToken } from "../sigma.types"
import { getSigmaAccessToken } from "./useSigmaAccessTokenUtils"
import { useSigmaRefreshAccessToken } from "./useSigmaRefreshAccessToken"

/**
 * Service that provides a sigma access token and refreshes it when it expires
 */
export const useSigmaAccessToken = () => {
  const [accessToken, setAccessToken] = useState<SigmaAccessToken | undefined>(
    undefined
  )

  const queryKey = createQueryKey(ApiQueryName.SigmaAccessToken, "getOne")

  const accessTokenQueryResult = useQuery<SigmaAccessToken>({
    cacheTime: Infinity,
    queryKey,
    queryFn: async (): Promise<SigmaAccessToken> => getSigmaAccessToken(),
    staleTime: Infinity,
  })

  const refreshAccessTokenQueryResult = useSigmaRefreshAccessToken(
    accessToken?.refreshToken,
    {
      enabled: Boolean(accessToken),
      // Prevent unnecessary initial fetch from happening
      initialData: null,
      // Refresh token after every expiration (seconds * 1000 = milliseconds)
      refetchInterval: () =>
        accessToken ? accessToken.expiresIn * 1000 : false,
      refetchIntervalInBackground: true,
    }
  )

  // Update accessToken whenever we have a new one
  useEffect(() => {
    const token: SigmaAccessToken | undefined =
      refreshAccessTokenQueryResult.data
        ? refreshAccessTokenQueryResult.data
        : accessTokenQueryResult.data

    if (token) {
      setAccessToken(token)
    }
  }, [accessTokenQueryResult.data, refreshAccessTokenQueryResult.data])

  return useRenameQueryResult(
    refreshAccessTokenQueryResult.data
      ? refreshAccessTokenQueryResult
      : accessTokenQueryResult,
    ApiQueryName.SigmaAccessToken
  )
}
