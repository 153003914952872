import React from "react"
import { createRoot } from "react-dom/client"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider as ReduxProvider } from "react-redux"

import { OrganizationProvider } from "@/contexts"
import { datadogRum } from "@datadog/browser-rum"
import type { i18n } from "i18next"
import { ConfirmProvider } from "material-ui-confirm"

import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license-pro"

import { App } from "../../../web-ui/src/components/app/app"
import I18nResolver from "../../../web-ui/src/components/i18nResolver/i18nResolver"
import type { EnvironmentName } from "../../../web-ui/src/models/environment.types"
import createStore from "../../../web-ui/src/state/store"
import MainTheme from "../../../web-ui/src/styles/theme"
import {
  createI18nService,
  initializeI18nService,
} from "../../../web-ui/src/utils/i18n"
import { queryClient } from "../../../web-ui/src/utils/queryClient"

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

// Initialize RUM
if (
  process.env.REACT_APP_ENV === ("development" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("acceptance" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("fed" satisfies EnvironmentName) ||
  process.env.REACT_APP_ENV === ("production" satisfies EnvironmentName)
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "puma",
    env: process.env.REACT_APP_ENV,
    version: APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    actionNameAttribute: "data-analytics-action-name",
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/.*\.(nzero|ledger8760)\.com/],
  })
}

// Set up internationalization service
const i18nService: i18n = createI18nService()
initializeI18nService(i18nService).catch((e) => {
  console.error(e)
})

// Create Redux store
const store = createStore({
  sagaMiddlewareContext: {
    i18n: i18nService,
  },
})

const root = createRoot(document.getElementById("root"))

root.render(
  // TODO: Turn on StrictMode when codebase is ready
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <I18nResolver i18nService={i18nService}>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MainTheme}>
            <ConfirmProvider>
              <CssBaseline />
              <OrganizationProvider>
                <App />
              </OrganizationProvider>
            </ConfirmProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </I18nResolver>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
)
