import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ToggleButtonList } from "@/components/toggleButtonList/toggleButtonList"
import { WeightUnitToggle } from "@/components/weightUnitToggle/weightUnitToggle"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import useFilters from "@/hooks/useFilters/useFilters"
import type Department from "@/models/department"
import DateFilter from "@/models/filter/dateFilter"
import MultiSelectStringFilter from "@/models/filter/multiSelectStringFilter"
import SingleSelectStringFilter from "@/models/filter/singleSelectStringFilter"
import type { LanguageRegion } from "@/models/i18n"
import { Metric, metrics } from "@/models/metric"
import type { IRange } from "@/models/range"
import { resources } from "@/models/resource"
import {
  ElectricityEmissionsFactor,
  ElectricityEmissionsFactorIds,
} from "@/models/scopeTwo"
import { Order } from "@/models/sort"
import { GhgWeightUnit } from "@/models/unit"
import { MonthRangePicker } from "@/nzds/inputs/month-range-picker"
import { useDepartments } from "@/services/department"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useOrganizationalUnits } from "@/services/organizationalUnit"
import { useAvailableReportDates } from "@/services/useAvailableReportDates"
import { solidGray } from "@/utils/colors"
import { clampDateRange, isValidCrossYearDateRange } from "@/utils/date"
import { isNil } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"

import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Box, Divider, FormLabel, IconButton, Stack } from "@mui/material"
import type { DatePicker } from "@mui/x-date-pickers-pro"

import { useUserSettings } from "../../../settings"
import { Card } from "../../models/report"
import type {
  IReportFilters,
  ResourceSummaryRecord,
  ResourceSummaryReportTableColumn,
} from "../../models/resourceSummary"
import { useResourceSummaryReport } from "../../services/useResourceSummaryReport/useResourceSummaryReport"
import DownloadCsv from "../downloadCsv/downloadCsv"
import ReportTwoColumnLayout from "../reportTwoColumnLayout/reportTwoColumnLayout"
import AdvancedReportingTools from "./advancedReportingTools/advancedReportingTools"
import { DepartmentSelector } from "./departmentSelector/departmentSelector"
import { getVisibleColumns } from "./reportSummaryUtils"
import { VirtualizedReportingTable } from "./virtualizedReportingTable/virtualizedReportingTable"

const defaultWeightUnit = GhgWeightUnit.Mt

const styles = {
  fieldContainer: {
    marginBottom: 2,
  },
  fieldLabel: {
    marginBottom: 0.5,
    color: solidGray.toString(),
  },
  gridActions: {
    top: "-34px",
    display: "flex",
    height: 0,
    justifyContent: "flex-end",
    position: "relative",
    zIndex: 3,
  },
}

const ReportSummary = () => {
  const { filters, setFilters } = useFilters<IReportFilters>({
    start: DateFilter,
    end: DateFilter,
    department: MultiSelectStringFilter,
    group: MultiSelectStringFilter,
    metric: MultiSelectStringFilter,
    resource: MultiSelectStringFilter,
    weightUnit: SingleSelectStringFilter,
    electricityEmissionsFactor: SingleSelectStringFilter,
  })
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()
  // TODO: remove all department logic once OU is fully on
  const isUseFlexibleHierarchyEnabled: boolean = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  const { departments, isDepartmentsFetched, isDepartmentsFetching } =
    useDepartments({
      sort: { key: "name", order: "asc" },
      // TODO: Verify / fix typing for where clause.
      // migration to strict mode batch disable
      // Mass eslint disable @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      where: { organization_id: organization?.id } as any,
      disabled: isNil(organization?.id) || isUseFlexibleHierarchyEnabled,
    })
  const {
    organizationalUnits,
    isOrganizationalUnitsFetched,
    isOrganizationalUnitsFetching,
  } = useOrganizationalUnits(organization?.id, {
    clause: { level: 1 },
    disabled: isNil(organization?.id) || !isUseFlexibleHierarchyEnabled,
  })

  const { availableReportDatesData } = useAvailableReportDates(organization?.id)
  const { i18n, t } = useTranslation()
  const { currency } = useUserSettings()

  const [order, setOrder] = useState<Order>(Order.desc)
  const [orderBy, setOrderBy] = useState<keyof ResourceSummaryRecord>("kwh")
  const buttonRef = useRef(null)
  const [showToggle, setShowToggle] = useState(false)

  const timeWindow: IRange<Moment> = useMemo(
    () => ({
      start: filters.start.value,
      end: filters.end.value,
    }),
    [filters.end.value, filters.start.value]
  )

  const selectedElectricityEmissionsFactor: string =
    filters.electricityEmissionsFactor.value ??
    ElectricityEmissionsFactor.AdvancedGridStudy.id

  const columns: ResourceSummaryReportTableColumn[] = useMemo(
    () =>
      getVisibleColumns(
        filters.resource.value ?? [],
        filters.metric.value ?? [],
        selectedElectricityEmissionsFactor,
        isUseFlexibleHierarchyEnabled
      ),
    [
      filters.metric.value,
      filters.resource.value,
      selectedElectricityEmissionsFactor,
    ]
  )

  const { resourceSummaryReport, isResourceSummaryReportLoading } =
    useResourceSummaryReport(organization?.id, timeWindow, {
      columns,
      currency,
      language: i18n.language as LanguageRegion,
      departments: filters?.department?.value ?? [],
      groupIds: filters?.group?.value ?? [],
      order,
      orderBy,
      translateFn: t,
      weightUnit: filters?.weightUnit?.value as GhgWeightUnit,
      electricityEmissionsFactor: selectedElectricityEmissionsFactor,
    })

  const onWeightUnitChange = useCallback(
    (weightUnit: GhgWeightUnit): void => {
      setFilters({
        weightUnit: new SingleSelectStringFilter(weightUnit),
      })
    },
    [setFilters]
  )

  const handleWeightUnitSelect = useCallback(
    (selectedWeightUnit: GhgWeightUnit) => {
      onWeightUnitChange(selectedWeightUnit)
      setShowToggle(false)
    },
    [onWeightUnitChange]
  )

  const setToggle = useCallback(() => {
    setShowToggle(!showToggle)
  }, [showToggle])

  const handleClickOutside = (event) => {
    // Mass lint disable
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowToggle(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  // Check current filters and set default filters
  useEffect(() => {
    // check and set default date range
    let dateRange: IRange<Moment> = {
      start: filters.start.value,
      end: filters.end.value,
    }

    if (
      availableReportDatesData?.start.isValid() &&
      availableReportDatesData.end.isValid() &&
      // Don't rewrite valid date ranges
      !isValidCrossYearDateRange(
        {
          start: filters.start.value,
          end: filters.end.value,
        },
        availableReportDatesData
      )
    ) {
      const mostRecent12Months: IRange<Moment> = clampDateRange(
        {
          start: availableReportDatesData.end.clone().subtract(1, "year"),
          end: availableReportDatesData.end,
        },
        availableReportDatesData
      )

      dateRange = mostRecent12Months
    }
    // TODO: remove isUseFlexibleHierarchyEnabled logic once OU feature flag is fully implemented
    if (isUseFlexibleHierarchyEnabled) {
      // check and set default params
      if (
        !organization ||
        !organizationalUnits ||
        isOrganizationalUnitsFetching ||
        !isOrganizationalUnitsFetched
      ) {
        return
      }

      const defaultResources = resources.map((resource) => resource.id)
      const defaultMetrics = metrics
        .map((metric) => metric.id)
        .filter((metricId) => metricId !== Metric.GJ.id)

      let selectedGroupIds: string[] = filters.group.value

      if (filters.group.value) {
        // migration to strict mode batch disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        selectedGroupIds = organizationalUnits
          .filter((group) =>
            filters.group.value.some(
              (filteredGroup) => filteredGroup === group.id
            )
          )
          .map((group) => group.id)
      } else {
        // migration to strict mode batch disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        selectedGroupIds = organizationalUnits.map((dept) => dept.id)
      }

      // Default selected resources
      const selectedResourceIds: string[] = filters.resource.value?.length
        ? filters.resource.value
        : defaultResources

      // Default selected metrics
      const selectedMetricIds: string[] = filters.metric.value?.length
        ? filters.metric.value
        : defaultMetrics

      const selectedWeightUnit = filters.weightUnit.value ?? defaultWeightUnit

      // Write initial filters to url
      setFilters(
        {
          electricityEmissionsFactor: new SingleSelectStringFilter(
            selectedElectricityEmissionsFactor
          ),
          end: new DateFilter(dateRange.end),
          department: new MultiSelectStringFilter(null),
          group: new MultiSelectStringFilter(selectedGroupIds),
          metric: new MultiSelectStringFilter(selectedMetricIds),
          resource: new MultiSelectStringFilter(selectedResourceIds),
          start: new DateFilter(dateRange.start),
          weightUnit: new SingleSelectStringFilter(selectedWeightUnit),
        },
        { replace: true }
      )
    } else {
      // TODO: remove all department logic once OU feature flag is fully implemented
      // The nearly identical logic to above is intentional. We want to make it simple to remove
      // all logic associated with departments when flexible hierarchy is fully on.
      if (
        !organization ||
        !departments ||
        isDepartmentsFetching ||
        !isDepartmentsFetched
      ) {
        return
      }

      const defaultResources = resources.map((resource) => resource.id)
      const defaultMetrics = metrics
        .map((metric) => metric.id)
        .filter((metricId) => metricId !== Metric.GJ.id)

      let selectedDepartmentIds: string[] = filters.department.value

      if (filters.department.value) {
        // migration to strict mode batch disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        selectedDepartmentIds = departments
          .filter((dept) =>
            filters.department.value.some(
              (filteredDept) => filteredDept === dept.id
            )
          )
          .map((dept) => dept.id)
      } else {
        // migration to strict mode batch disable
        // Mass eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        selectedDepartmentIds = departments.map((dept) => dept.id)
      }

      // Default selected resources
      const selectedResourceIds: string[] = filters.resource.value?.length
        ? filters.resource.value
        : defaultResources

      // Default selected metrics
      const selectedMetricIds: string[] = filters.metric.value?.length
        ? filters.metric.value
        : defaultMetrics

      const selectedWeightUnit = filters.weightUnit.value ?? defaultWeightUnit

      const validElectricityEmissionsFactor =
        ElectricityEmissionsFactorIds.includes(
          selectedElectricityEmissionsFactor
        )
          ? selectedElectricityEmissionsFactor
          : ElectricityEmissionsFactor.AdvancedGridStudy.id

      // Write initial filters to url
      setFilters(
        {
          department: new MultiSelectStringFilter(selectedDepartmentIds),
          electricityEmissionsFactor: new SingleSelectStringFilter(
            validElectricityEmissionsFactor
          ),
          end: new DateFilter(dateRange.end),
          group: new MultiSelectStringFilter(null),
          metric: new MultiSelectStringFilter(selectedMetricIds),
          resource: new MultiSelectStringFilter(selectedResourceIds),
          start: new DateFilter(dateRange.start),
          weightUnit: new SingleSelectStringFilter(selectedWeightUnit),
        },
        { replace: true }
      )
    }
  }, [
    availableReportDatesData,
    departments,
    isDepartmentsFetching,
    isDepartmentsFetched,
    organization,
    selectedElectricityEmissionsFactor,
    isUseFlexibleHierarchyEnabled,
    organizationalUnits,
    isOrganizationalUnitsFetching,
    isOrganizationalUnitsFetched,
  ])

  const onDateChange = useCallback(
    (
      value: IRange<Moment>,
      context: Parameters<Parameters<typeof DatePicker>[0]["onChange"]>[1]
    ): void => {
      if (context?.validationError) {
        return
      }
      setFilters({
        start: new DateFilter(moment(value.start)),
        end: new DateFilter(moment(value.end)),
      })
    },
    [setFilters]
  )

  const onGroupsChange = useCallback(
    (groupIds: string[]) => {
      // TODO: remove all department logic once OU is fully on
      isUseFlexibleHierarchyEnabled
        ? setFilters({
            group: new MultiSelectStringFilter(groupIds),
          })
        : setFilters({
            department: new MultiSelectStringFilter(groupIds),
          })
    },
    [setFilters]
  )

  const onSelectedResourcesChange = useCallback(
    (resourceIds: string[]): void => {
      setFilters({
        resource: new MultiSelectStringFilter(resourceIds),
      })
    },
    [setFilters]
  )

  const onSelectedMetricsChange = useCallback(
    (metricIds: string[]): void => {
      setFilters({
        metric: new MultiSelectStringFilter(metricIds),
      })
    },
    [setFilters]
  )

  const onSort = useCallback(
    (newOrder: Order, newOrderBy: keyof ResourceSummaryRecord): void => {
      setOrder(newOrder)
      setOrderBy(newOrderBy)
    },
    []
  )

  const onSelectedElectricityEmissionsFactorChange = useCallback(
    (electricityEmissionsFactor: string): void => {
      setFilters({
        electricityEmissionsFactor: new SingleSelectStringFilter(
          electricityEmissionsFactor
        ),
      })
    },
    [setFilters]
  )

  return (
    <ReportTwoColumnLayout
      actions={
        <DownloadCsv
          isDisabled={
            isResourceSummaryReportLoading ||
            !resourceSummaryReport.data.rows.length
          }
          isLoading={isResourceSummaryReportLoading}
          report={resourceSummaryReport}
        />
      }
      details={
        <>
          <Box ref={buttonRef} sx={styles.gridActions}>
            <IconButton
              color="neutral"
              onClick={setToggle}
              size="small"
              title="Metric Unit Toggle"
            >
              <MoreVertIcon />
            </IconButton>
            <WeightUnitToggle
              showToggle={showToggle}
              weightUnit={filters.weightUnit.value as GhgWeightUnit}
              weightUnitHandler={handleWeightUnitSelect}
            />
          </Box>
          <VirtualizedReportingTable
            columns={resourceSummaryReport.data.columns}
            isUseFlexibleHierarchyEnabled={isUseFlexibleHierarchyEnabled}
            onSort={onSort}
            order={order}
            orderBy={orderBy}
            rows={resourceSummaryReport.data.rows}
          />
        </>
      }
      detailsTitle="Data Preview"
      filters={
        <>
          <Stack mb={1}>
            <FormLabel sx={styles.fieldLabel}>Date Range</FormLabel>
            <MonthRangePicker
              availableMaxMinMonths={availableReportDatesData}
              fullWidth
              onChange={onDateChange}
              value={timeWindow}
            />
          </Stack>
          <Stack mb={1}>
            <FormLabel sx={styles.fieldLabel}>
              {isUseFlexibleHierarchyEnabled
                ? "Filter Sites By Group"
                : "Sites By Departments"}
            </FormLabel>
            {isUseFlexibleHierarchyEnabled && (
              <DepartmentSelector
                initialValues={filters.group.value}
                isUseFlexibleHierarchyEnabled={isUseFlexibleHierarchyEnabled}
                onChange={onGroupsChange}
                options={(organizationalUnits as unknown as Department[]) || []}
              />
            )}
            {!isUseFlexibleHierarchyEnabled && (
              <DepartmentSelector
                initialValues={filters.department.value}
                isUseFlexibleHierarchyEnabled={isUseFlexibleHierarchyEnabled}
                onChange={onGroupsChange}
                options={(departments as unknown as Department[]) || []}
              />
            )}
          </Stack>
          <Stack>
            <FormLabel sx={styles.fieldLabel}>Resources</FormLabel>
            <ToggleButtonList
              items={resources}
              labelledBy="resources"
              onChange={onSelectedResourcesChange}
              value={filters.resource.value}
            />
          </Stack>
          <Stack>
            <FormLabel sx={styles.fieldLabel}>Metrics</FormLabel>
            <ToggleButtonList
              items={metrics}
              labelledBy="metrics"
              onChange={onSelectedMetricsChange}
              value={filters.metric.value}
            />
          </Stack>
          {isFeatureEnabled(
            FeatureFlags.REPORT_RESOURCE_SUMMARY_ADVANCED_REPORTING_TOOLS,
            organization
          ) && (
            <Box mt={1}>
              <Divider sx={{ marginBottom: 2 }} />
              <AdvancedReportingTools
                onChange={onSelectedElectricityEmissionsFactorChange}
                value={selectedElectricityEmissionsFactor}
              />
            </Box>
          )}
        </>
      }
      organizationId={organization?.id}
      report={Card.ResourceSummary}
    />
  )
}

export default ReportSummary
