import React, { useState } from "react"

import { Box, Divider, Paper, useMediaQuery, useTheme } from "@mui/material"

import { deprecatedGray400 } from "../../../../utils/colors"
import type {
  UtilityCredentialsData,
  currentFormStateType,
} from "../../model/utilityCredentials"
import {
  UtilityFormStateType,
  UtilityFormType,
} from "../../model/utilityCredentials"
import CredentialSelection from "../credentialSelection/credentialSelection"
import CredentialSignUpForm from "../credentialSignUpForm/credentialSignUpForm"

interface UtilityCredentialsProps {
  credentials: UtilityCredentialsData
  token: string
}

const UtilityCredentials = ({
  credentials,
  token,
}: UtilityCredentialsProps): JSX.Element => {
  const [currentForm, setCurrentForm] = useState<UtilityFormType>(
    UtilityFormType.Electric
  )
  const [currentFormState, setCurrentFormState] =
    useState<currentFormStateType>({
      electric: UtilityFormStateType.Incomplete,
      naturalGas: UtilityFormStateType.Incomplete,
    })

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const handleCurrentFormSubmit = (
    curForm: UtilityFormType,
    curFormState: UtilityFormStateType,
    nextForm: UtilityFormType
  ) => {
    if (curForm === UtilityFormType.Electric) {
      setCurrentFormState({ ...currentFormState, electric: curFormState })
    } else if (curForm === UtilityFormType.NaturalGas) {
      setCurrentFormState({ ...currentFormState, naturalGas: curFormState })
    }
    setCurrentForm(nextForm)
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100vw"
    >
      <Paper
        elevation={4}
        sx={{
          height: "80%",
          width: {
            xs: "90%", // theme.breakpoints.up('xs')
            md: "80%", // theme.breakpoints.up('md')
            lg: "60%", // theme.breakpoints.up('lg')
          },
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          overflow: {
            xs: "auto",
            md: "unset",
          },
        }}
      >
        <CredentialSelection
          currentForm={currentForm}
          currentFormState={currentFormState}
          orgData={credentials}
        />
        <Divider
          flexItem
          orientation={mdUp ? "vertical" : "horizontal"}
          sx={{ borderColor: deprecatedGray400.toString() }}
        />
        <CredentialSignUpForm
          currentForm={currentForm}
          handleCurrentFormSubmit={handleCurrentFormSubmit}
          token={token}
        />
      </Paper>
    </Box>
  )
}

export default UtilityCredentials
