import React, { useMemo } from "react"

interface SiteSearchResultsActiveContext {
  isSiteSearchResultsActive: boolean
  setIsSiteSearchResultsActive: (siteSearch: boolean) => void
}

const siteSearchResultsActiveContext =
  React.createContext<SiteSearchResultsActiveContext>({
    isSiteSearchResultsActive: null,
    // Mass eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsSiteSearchResultsActive: (isSiteSearchResultsActive: boolean) =>
      undefined,
  })

export const SiteSearchResultsActiveProvider = ({ children }) => {
  const [isSiteSearchResultsActive, setIsSiteSearchResultsActive] =
    React.useState<boolean>(false)

  const value = useMemo(
    () => ({
      isSiteSearchResultsActive,
      setIsSiteSearchResultsActive,
    }),
    [isSiteSearchResultsActive]
  )

  return (
    <siteSearchResultsActiveContext.Provider value={value}>
      {children}
    </siteSearchResultsActiveContext.Provider>
  )
}

export const useSiteSearchResultsActiveContext = () =>
  React.useContext(siteSearchResultsActiveContext)
