import type { FC } from "react"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import type { Schemas } from "@/services/types"
import type { DotProps, TooltipProps } from "recharts"
import {
  CartesianGrid,
  Dot,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { Card, Stack, Typography, alpha, useTheme } from "@mui/material"
import { Box } from "@mui/system"

import { SectionHeader } from "../../../../components/section-header/section-header"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useFormatPercentage } from "../../../../formatters/useFormatPercentage"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { buildPlanDetailsHref } from "../../utils/build-plan-details-href"
import { buildPlanName } from "../../utils/build-plan-name"
import { calculateEmissionSavingsPercentage } from "../../utils/calculateEmissionSavingsPercentage"
import type {
  PlanChartData,
  PlansScatterChartProps,
} from "./plans-scatter-chart.types"

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  const { format: formatPercentage } = useFormatPercentage({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  //
  if (active && payload?.length) {
    const nameItems = (payload[0].payload as PlanChartData).name
    const displayItems = nameItems.slice(0, 2)
    const displayItemsString = displayItems
      .map(
        (intervention) =>
          `${intervention.name}: ${
            intervention.percentage
              ? formatPercentage(intervention.percentage)
              : ""
          }`
      )
      .join(" | ")
    return (
      <Card variant="outlined">
        <Stack
          bgcolor="common.white"
          height={100}
          justifyContent="space-between"
          maxWidth={300}
          p={1}
        >
          <Typography noWrap variant="h5">
            {/* eslint-disable-next-line
            @typescript-eslint/no-unsafe-member-access */}
            {displayItemsString}
          </Typography>
          <Box>
            {payload.map((dataPoint) => (
              <Stack key={dataPoint.value} direction="row" gap={1}>
                <Typography variant="body2">{dataPoint.name}:</Typography>
                <Typography variant="body2">
                  {dataPoint.dataKey === "capitalCost"
                    ? formatMoney(dataPoint.value)
                    : formatPercentage(dataPoint.value)}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Stack>
      </Card>
    )
  }

  return null
}

function createPlanChartDataPoint(
  plan: Schemas["BuildSimResponse"]
): PlanChartData {
  return {
    name: buildPlanName(plan) ?? [],
    capitalCost: plan.capital_cost,
    emissionReduction: calculateEmissionSavingsPercentage(
      plan.emissions_diff,
      plan.base_total_emissions
    ),
    planId: plan.id,
    siteId: plan.site_id,
  }
}

const RenderDot: FC<DotProps & { transition: string }> = ({
  cx,
  cy,
  stroke,
  fill,
  transition,
}) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <Dot
      cx={cx}
      cy={cy}
      fill={fill}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      r={isHovered ? 14 : 7}
      stroke={stroke}
      style={{ transition }}
    />
  )
}

export const PlansScatterChart: FC<PlansScatterChartProps> = ({
  allPlans,
  recommendedPlans,
}) => {
  const theme = useTheme()
  const currencySymbol = useCurrencySymbol()
  const { format: formatPercentage } = useFormatPercentage({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const { format: formatMoney } = useFormatMoney({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const chartData = useMemo(() => {
    const allPlansChartData: PlanChartData[] = []

    if (recommendedPlans.length > 0 && allPlans.length > 0) {
      for (const plan of allPlans) {
        const isRecommended = recommendedPlans.some((rP) => rP.id === plan.id)
        if (!isRecommended) {
          allPlansChartData.push(createPlanChartDataPoint(plan))
        }
      }
    }
    return allPlansChartData
  }, [recommendedPlans, allPlans])

  const recommendedChartData = useMemo(() => {
    const recommendedPlansChartData: PlanChartData[] = []

    if (recommendedPlans.length > 0) {
      for (const plan of recommendedPlans) {
        recommendedPlansChartData.push(createPlanChartDataPoint(plan))
      }
    }

    return recommendedPlansChartData
  }, [recommendedPlans])

  const transition = theme.transitions.create(["r"], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  })

  const navigate = useNavigate()

  return (
    <Box>
      <Stack gap={2} mb={2}>
        <SectionHeader title="Capital Cost vs. Emission Reduction: Highlighting Optimal Plans" />
      </Stack>

      <Stack direction="row" gap={2}>
        <Stack alignItems="center" direction="row" gap={1}>
          <Box
            bgcolor="spectrum.blue.400"
            border={1}
            borderColor="spectrum.blue.700"
            borderRadius={5}
            height="1rem"
            width="1rem"
          />
          <Typography variant="body2">Recommended plans</Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap={1}>
          <Box
            bgcolor={alpha(theme.palette.spectrum.blue[400], 0.1)}
            border={1}
            borderColor="spectrum.blue.400"
            borderRadius={5}
            height="1rem"
            width="1rem"
          />
          <Typography variant="body2">All plans</Typography>
        </Stack>
      </Stack>
      <ResponsiveContainer height={420} width="100%">
        <ScatterChart
          accessibilityLayer
          margin={{
            top: 20,
            right: 20,
            bottom: 40,
            left: 40,
          }}
        >
          <CartesianGrid stroke={theme.palette.grey[200]} />
          <XAxis
            dataKey="capitalCost"
            domain={["auto", "dataMax"]}
            label={{
              fill: theme.palette.text.primary,
              position: "bottom",
              style: {
                textAnchor: "middle",
                fontWeight: theme.typography.fontWeightBold,
              },
              value: `Capital Cost (${currencySymbol})`,
              offset: 10,
            }}
            name={`Capital Cost(${currencySymbol})`}
            stroke={theme.palette.neutral.light}
            style={{ fontSize: theme.typography.dataLabel?.fontSize }}
            tick={{ fill: theme.palette.neutral.main }}
            tickCount={10}
            tickFormatter={(tick: number) => formatMoney(tick)}
            type="number"
          />
          <YAxis
            dataKey="emissionReduction"
            domain={["auto", "dataMax"]}
            label={{
              fill: theme.palette.text.primary,
              position: "left",
              style: {
                textAnchor: "middle",
                fontWeight: theme.typography.fontWeightBold,
              },
              angle: -90,
              offset: 10,
              value: "Emission Reduction (%)",
            }}
            name="Emission Reduction (%)"
            stroke={theme.palette.neutral.light}
            style={{ fontSize: theme.typography.dataLabel?.fontSize }}
            tick={{ fill: theme.palette.neutral.main }}
            tickCount={5}
            tickFormatter={(tick: number) => formatPercentage(tick)}
            type="number"
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ strokeDasharray: "3 3" }}
          />
          <Scatter
            data={chartData}
            name="All Cost vs Emission reductions"
            onClick={({ payload }: { payload: PlanChartData }) => {
              navigate(buildPlanDetailsHref(payload.planId, payload.siteId))
            }}
            shape={
              <RenderDot
                fill={alpha(theme.palette.spectrum.blue[400], 0.1)}
                stroke={theme.palette.spectrum.blue[400]}
                transition={transition}
              />
            }
          />
          <Scatter
            data={recommendedChartData}
            name="Recommended Cost vs Emission reductions"
            onClick={({ payload }: { payload: PlanChartData }) => {
              navigate(buildPlanDetailsHref(payload.planId, payload.siteId))
            }}
            shape={
              <RenderDot
                fill={theme.palette.spectrum.blue[400]}
                stroke={theme.palette.spectrum.blue[700]}
                transition={transition}
              />
            }
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  )
}
