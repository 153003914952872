import React from "react"
import { NavLink } from "react-router-dom"

import { Page, PageHeader } from "@/components"
import { NzDataGrid, UNITLESS_ROW_HEIGHT } from "@/components/nzDataGrid"
import { FeatureFlags, useFeature } from "@/services/feature"
import { useActiveOrganizationId } from "@/services/organization/useActiveOrganizationId"
import { useSitePreviews } from "@/services/useSitePreviews/useSitePreviews"

import { Link, Tooltip } from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"

import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { RootPath } from "../../../../models/route"
import { getDataE2EAttribute } from "../../../../utils/e2e"

/**
 * @deprecated Delete after site_explorer is permanently live
 */
const OldSites = () => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  // TO-DO: Delete when we remove the use_flexible_hierarchy_for_site_ownerships feature flag
  const groupHeader = isUseFlexibleHierarchyEnabled ? "Group" : "Department"

  // TO-DO: Move outside the component when we remove the use_flexible_hierarchy_for_site_ownerships feature flag
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Site",
      renderCell: (params: GridRenderCellParams) => (
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Tooltip title={params.value}>
          <Link
            component={NavLink}
            // Mass lint disable
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            data-e2e={`site-name-${getDataE2EAttribute(params.value)}`}
            to={`${RootPath.Site}/${params.id}`}
          >
            {params.value}
          </Link>
        </Tooltip>
      ),
      width: 360,
    },
    {
      field: "id",
      headerName: "Site Id",
      width: 128,
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "departmentName",
      flex: 1,
      headerName: groupHeader,
    },
  ]

  const { activeOrganizationId } = useActiveOrganizationId()
  const {
    sitePreviews = [],
    isSitePreviewsLoading,
    isSitePreviewsFetched,
  } = useSitePreviews(activeOrganizationId)

  return (
    <>
      <PageHeader title="Sites"></PageHeader>
      <Page fullHeight>
        <NzDataGrid
          columnHeaderHeight={UNITLESS_ROW_HEIGHT}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
          columns={columns as any}
          initialState={{
            sorting: {
              sortModel: [{ field: "departmentName", sort: "asc" }],
            },
          }}
          isLoading={isSitePreviewsLoading && !isSitePreviewsFetched}
          rows={sitePreviews}
        />
      </Page>
    </>
  )
}

export default OldSites
