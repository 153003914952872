import { createAndSortOptions } from "./sort-options"

const buildingTypeValues = [
  "Small Office",
  "Medium Office",
  "Large Office",
  "Stand-alone Retail",
  "Strip Mall",
  "Primary School",
  "Secondary School",
  "Outpatient Healthcare",
  "Hospital",
  "Small Hotel",
  "Large Hotel",
  "Warehouse (non-refrigerated)",
  "Quick Service Restaurant",
  "Full Service Restaurant",
  "Mid-rise Apartment",
  "High-rise Apartment",
  "__MISSING__",
]

const buildingTypeLabels = [
  "Small Office",
  "Medium Office",
  "Large Office",
  "Stand-alone Retail",
  "Strip Mall",
  "Primary School",
  "Secondary School",
  "Outpatient Healthcare",
  "Hospital",
  "Small Hotel",
  "Large Hotel",
  "Warehouse (non-refrigerated)",
  "Quick Service Restaurant",
  "Full Service Restaurant",
  "Mid-rise Apartment",
  "High-rise Apartment",
  "Unknown",
]

export const buildingTypeOptions = createAndSortOptions(
  buildingTypeValues,
  buildingTypeLabels
)
