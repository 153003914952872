import React from "react"
import type { FC } from "react"

import { Divider, Stack, Typography } from "@mui/material"

import { FormSelectField } from "../../../../nzds/forms/form-select-field/form-select-field"
import type { SiteExplorerRetrofitFieldProps } from "./site-explorer-retrofit-field.types"

export const SiteExplorerRetrofitField: FC<SiteExplorerRetrofitFieldProps> = ({
  title,
  field,
  options,
}) => (
  <Stack gap={1}>
    <Typography fontWeight="fontWeightBold" variant="body1">
      {title}
    </Typography>
    <Divider />
    <FormSelectField
      label="From"
      name={`before.${field}`}
      options={options}
      placeholder={`Select ${title}`}
      size="small"
    />
    <FormSelectField
      label="To"
      name={`after.${field}`}
      options={options}
      placeholder={`Select ${title}`}
      size="small"
    />
  </Stack>
)
