import { createAndSortOptions } from "./sort-options"

const values = [
  "gen1_t12_incandescent",
  "gen2_t8_halogen",
  "gen3_t5_cfl",
  "gen5_led",
  "__MISSING__",
]

const labels = [
  "Incandescent",
  "Halogen",
  "Compact Fluorescent Lamp",
  "LED",
  "Unknown",
]

export const lightingOptions = createAndSortOptions(values, labels, {
  sort: false,
})
