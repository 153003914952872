import React from "react"

import UnAuthorizationPage from "../unAuthorizationPage/unAuthorizationPage"

interface AuthorizationGuardProps {
  children: JSX.Element
  isError: boolean
  isFetched: boolean
}

const AuthorizationGuard = ({
  isError,
  isFetched,
  children,
}: AuthorizationGuardProps): JSX.Element => {
  if (!isFetched) {
    return <></>
  }
  if (isError) {
    return <UnAuthorizationPage />
  }

  return children
}

export default AuthorizationGuard
