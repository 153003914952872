// @ts-strict-ignore
import React, { useMemo } from "react"

import { PerformanceCell } from "@/components/performance"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useFormatDecimal } from "@/formatters/useFormatDecimal"
import { DataGridColumnHeader } from "@/nzds/data-grid"
import { useAuthentication } from "@/services/authentication"
import { FeatureFlags, useFeature } from "@/services/feature"

import { Stack, Typography } from "@mui/material"
import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro"

import { baseSiteNumberColumnDef } from "../../../../models"
import type { SiteRowData } from "../../../../services/use-get-site-explorer"
import { useCommonGridColumns } from "../../../utils/use-common-grid-columns/use-common-grid-columns"
import { PerformanceTooltipLegend } from "../../components/performance-tooltip-legend/performance-tooltip-legend"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  // default columns
  benchmark_electric_eui_percentile: true,
  benchmark_gas_eui_percentile: true,
  benchmark_majorfuel_eui_percentile: true,
  // non-default columns
  benchmark_electric_eui_mean: false, // Electricity Mean
  benchmark_gas_eui_mean: false, // Natural Gas Mean
  benchmark_majorfuel_eui_mean: false, // Combined Mean
  electricity_kbtu_per_sq_ft: false, // Electricity EUI
  electricity_mt_co2e: false, // Electricity Emissions
  nat_gas_kbtu_per_sq_ft: false, // Natural Gas EUI
  nat_gas_mt_co2e: false, // Natural Gas Emissions
  total_emissions_mt_co2e: false, // Combined Emissions
}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "site_name"],
  right: ["actions", "isPlanned"],
}

export const defaultSortModel: GridSortModel = [
  {
    field: "site_name",
    sort: "desc",
  },
]

interface UsePerformanceGridColumnsProps {
  buildingTypes: string[] | undefined
  handleRowEditCancelClick: (id: GridRowId) => void
  handleRowEditClick: (id: GridRowId) => void
  handleRowEditSaveClick: (id: GridRowId) => void
  rowModesModel: GridRowModesModel
}

export const usePerformanceGridColumns = (
  props: UsePerformanceGridColumnsProps
): GridColDef<SiteRowData>[] => {
  const { canEditSite } = useAuthentication()

  const { format: formatDecimal } = useFormatDecimal()
  const commonGridColumnDefs = useCommonGridColumns(props)

  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const columns: GridColDef<SiteRowData>[] = useMemo(() => {
    return [
      {
        ...commonGridColumnDefs.rowNumber,
      },
      {
        ...commonGridColumnDefs.siteName,
      },
      {
        ...commonGridColumnDefs.group,
      },
      {
        ...commonGridColumnDefs.buildingType,
      },
      {
        ...commonGridColumnDefs.buildingArea,
      },
      {
        ...commonGridColumnDefs.combinedEnergyUsage,
      },
      {
        ...commonGridColumnDefs.combinedEmissions,
      },
      {
        ...commonGridColumnDefs.combinedEui,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_majorfuel_eui_mean",
        headerName: "Combined EUI Benchmark",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Combined EUI Benchmark"
            tooltipContent={
              <Stack gap={1}>
                <Typography display="block" variant="body2">
                  Mean EUI for the combined consumption of natural gas and
                  electricity in buildings similar to yours (building type and
                  climate), based on the ENERGY STAR dataset.
                </Typography>
                <Typography display="block" variant="body2">
                  It serves as a benchmark to assess the energy efficiency of
                  your building&rsquo;s total energy usage.
                </Typography>
              </Stack>
            }
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDecimal(params.value),
        width: 240,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_majorfuel_eui_percentile",
        headerName: "Combined Performance",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Combined Performance"
            tooltipContent={
              <Stack gap={1}>
                Percentile indicates how the combined EUI of your building
                compares to similar building types in the ENERGY STAR dataset.
                <PerformanceTooltipLegend />
              </Stack>
            }
            unit="Percentile"
          />
        ),
        renderCell: (
          params: GridRenderCellParams<SiteRowData, Nullable<string>>
        ) => <PerformanceCell value={Number.parseFloat(params.value)} />,
        sortable: false,
        width: 240,
      },
      {
        ...commonGridColumnDefs.electricityUsage,
      },
      {
        ...commonGridColumnDefs.electricityEmissions,
      },
      {
        ...commonGridColumnDefs.electricityEui,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_electric_eui_mean",
        headerName: "Electricity EUI Benchmark",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Electricity EUI Benchmark"
            tooltipContent={
              <Stack gap={1}>
                <Typography display="block" variant="body2">
                  Mean electricity EUI in buildings similar to yours (building
                  type and climate), based on the ENERGY STAR dataset.
                </Typography>
                <Typography display="block" variant="body2">
                  It serves as a benchmark to assess the energy efficiency of
                  your building&rsquo;s total energy usage.
                </Typography>
              </Stack>
            }
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDecimal(params.value),
        width: 260,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_electric_eui_percentile",
        headerName: "Electricity Performance",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Electricity Performance"
            tooltipContent={
              <Stack gap={1}>
                Percentile indicates how the electricity EUI of your building
                compares to similar building types in the ENERGY STAR dataset
                <PerformanceTooltipLegend />
              </Stack>
            }
            unit="Percentile"
          />
        ),
        renderCell: (
          params: GridRenderCellParams<SiteRowData, Nullable<string>>
        ) => <PerformanceCell value={Number.parseFloat(params.value)} />,
        sortable: false,
        width: 240,
      },
      {
        ...commonGridColumnDefs.naturalGasUsage,
      },
      {
        ...commonGridColumnDefs.naturalGasEmissions,
      },
      {
        ...commonGridColumnDefs.naturalGasEui,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_gas_eui_mean",
        headerName: "Natural Gas EUI Benchmark",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Natural Gas EUI Benchmark"
            tooltipContent={
              <Stack gap={1}>
                <Typography display="block" variant="body2">
                  Mean natural gas EUI in buildings similar to yours (building
                  type and climate), based on the ENERGY STAR dataset.
                </Typography>
                <Typography display="block" variant="body2">
                  It serves as a benchmark to assess the energy efficiency of
                  your building&rsquo;s total energy usage.
                </Typography>
              </Stack>
            }
            unit="kBtu / sq ft" // Purposely not translated per locale
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDecimal(params.value),
        sortable: false,
        width: 260,
      },
      {
        ...baseSiteNumberColumnDef,
        field: "benchmark_gas_eui_percentile",
        headerName: "Natural Gas Performance",
        renderHeader: () => (
          <DataGridColumnHeader
            headerName="Natural Gas Performance"
            tooltipContent={
              <Stack gap={1}>
                Percentile indicates how the natural gas EUI of your building
                compares to similar building types in the ENERGY STAR dataset.
                <PerformanceTooltipLegend />
              </Stack>
            }
            unit="Percentile"
          />
        ),
        renderCell: (
          params: GridRenderCellParams<SiteRowData, Nullable<string>>
        ) => <PerformanceCell value={Number.parseFloat(params.value)} />,
        sortable: false,
        width: 240,
      },
      ...(canEditSite
        ? [
            {
              ...commonGridColumnDefs.actions,
            },
          ]
        : []),
      ...(isFeatureEnabled(FeatureFlags.PLANNING, organization)
        ? [{ ...commonGridColumnDefs.planning }]
        : []),
    ]
  }, [
    commonGridColumnDefs.rowNumber,
    commonGridColumnDefs.siteName,
    commonGridColumnDefs.group,
    commonGridColumnDefs.buildingType,
    commonGridColumnDefs.buildingArea,
    commonGridColumnDefs.combinedEnergyUsage,
    commonGridColumnDefs.combinedEmissions,
    commonGridColumnDefs.combinedEui,
    commonGridColumnDefs.electricityUsage,
    commonGridColumnDefs.electricityEmissions,
    commonGridColumnDefs.electricityEui,
    commonGridColumnDefs.naturalGasUsage,
    commonGridColumnDefs.naturalGasEmissions,
    commonGridColumnDefs.naturalGasEui,
    commonGridColumnDefs.actions,
    commonGridColumnDefs.planning,
    canEditSite,
    isFeatureEnabled,
    organization,
    formatDecimal,
  ])

  return columns
}
