import type { Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"
import type { AxiosResponse } from "axios"

import type { Thread } from "../../assistant.types"

export type InitThreadParams = Schemas["ThreadRequest"]

/**
 * Initializes an assistant thread and returns prior messages
 */
export const initThread = async (params: InitThreadParams): Promise<Thread> => {
  const response: AxiosResponse<Thread> = await httpClient.post(
    "/api/v1/recommendations/assistant/init_thread",
    params
  )

  return response.data
}

export type SendMessageParams = Schemas["AssistantRequest"]

/**
 * Sends a message to an assistant thread
 */
export const sendMessage = (params: SendMessageParams): Promise<string> => {
  console.warn("sendMessage is not implemented")
  console.warn("sendMessage params is not implemented", params)

  return Promise.resolve(
    new Response(
      JSON.stringify({
        message: `I don't yet know how to respond to “${params.messages?.[0]?.content}”`,
      })
    )
  )
    .then((response) => response.json())
    .then((json) => (json as { message: string }).message)
}
