import type { FC } from "react"
import React from "react"
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom"

import { ApiQueryName } from "@/models/api"
import type { Feature } from "@/models/feature"
import { AdminPath, RootPath } from "@/models/route"
import type User from "@/models/user"
import { AboutIndex } from "@/modules/about"
import { AdminRepAnalyticsRoute, AdminRoute } from "@/modules/admin"
import { AssistantIndexRoute } from "@/modules/assistant/pages"
import RecommendationsRoute from "@/modules/recommendations"
import RepRoute from "@/modules/rep"
import ReportingRoute from "@/modules/report/routes"
import Settings from "@/modules/settings/module"
import { SitesRoute } from "@/modules/site/routes"
import TamAdmin from "@/modules/tamAdmin/module"
import UtilityCredentialsRoute from "@/modules/utilityCredentials/components/utilityCredentialsRoute/utilityCredentialsRoute"
import { getAuthenticatedUserQueryKey, useGtag } from "@/services"
import { fetchFeatures, getUser } from "@/utils"
import { queryClient } from "@/utils/queryClient"

import { ForgotPassword } from "../forgot-password/forgot-password"
import { NewLayout } from "../nav/appLayout/newLayout"
import Page404 from "../nav/page404/page404"
import WithNavigation from "../nav/withNavigation"
import OrganizationsRoute from "../organization/organizationsRoute"
import OrganizationDashboard from "../organizationDashboard/organizationDashboard"
import { OrganizationDashboardResolver } from "../organizationDashboard/organizationDashboard.resolver"
import OrganizationResolver from "../organizationResolver/organizationResolver"
import { OrganizationSummary } from "../organizationSummary/organizationSummary"
import { ResetPassword } from "../reset-password/reset-password"
import AdminGuard from "../route/adminGuard/adminGuard"
import { AuthenticatedRoute } from "../route/authenticated-route/authenticated-route"
import OrgGuard from "../route/orgGuard/orgGuard"
import { UnauthenticatedRoute } from "../route/unauthenticated-route/unauthenticated-route"
import UserGuard from "../route/userGuard/userGuard"
import { SignUp } from "../sign-up/sign-up"
import SignIn from "../signIn/signIn"
import { ValidateAccount } from "../validate-account/validate-account"

import "@/styles/application.scss"

const appLoader = async (): Promise<Feature[]> => {
  return queryClient.fetchQuery([ApiQueryName.Feature], fetchFeatures)
}

const authenticatedRouteLoader = async (): Promise<User> => {
  return await queryClient.fetchQuery(getAuthenticatedUserQueryKey, getUser)
}

const Root: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <AdminGuard>
            <TamAdmin />
          </AdminGuard>
        }
        path={`${RootPath.TamAdmin}/*`}
      />

      <Route
        element={
          <WithNavigation>
            <OrganizationResolver>
              <Routes>
                <Route
                  element={
                    <UserGuard>
                      <OrganizationsRoute />
                    </UserGuard>
                  }
                  path={RootPath.Organization}
                />
                <Route
                  element={
                    <OrgGuard>
                      <UserGuard>
                        <div className="authorized-organization">
                          <OrganizationSummary />
                        </div>
                      </UserGuard>
                    </OrgGuard>
                  }
                  path={`${RootPath.Organization}/:orgId`}
                />
                <Route
                  element={<ReportingRoute />}
                  path={`${RootPath.Report}/*`}
                />
                <Route
                  element={<RecommendationsRoute />}
                  path={`${RootPath.Recommendations}/*`}
                />
                <Route element={<Page404 />} path="*" />
              </Routes>
            </OrganizationResolver>
          </WithNavigation>
        }
        path="*"
      />
    </Routes>
  )
}

// Migrate from BrowserRouter to v6 data router
// https://reactrouter.com/en/main/upgrading/v6-data#migrating
const router = createBrowserRouter([
  {
    element: <Outlet />,
    loader: appLoader,
    children: [
      {
        path: RootPath.About,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <AboutIndex />,
          },
        ],
      },
      {
        path: RootPath.Root,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignIn />,
          },
        ],
      },
      {
        path: RootPath.SignUp,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <SignUp />,
          },
        ],
      },
      {
        path: `${RootPath.ValidateAccount}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ValidateAccount />,
          },
        ],
      },
      {
        path: RootPath.ForgotPassword,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: `${RootPath.ResetPassword}/:token`,
        element: <UnauthenticatedRoute />,
        children: [
          {
            index: true,
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: `${RootPath.UtilityCredentials}/*`,
        element: <UtilityCredentialsRoute />,
      },
      {
        element: <AuthenticatedRoute />,
        loader: authenticatedRouteLoader,
        children: [
          {
            path: RootPath.Admin,
            element: <AdminRoute />,
            children: [
              {
                index: true,
                element: <AdminRepAnalyticsRoute />,
              },
              {
                path: AdminPath.Rep,
                element: <AdminRepAnalyticsRoute />,
              },
            ],
          },
          {
            path: RootPath.Assistant,
            element: <NewLayout />,
            children: [
              {
                index: true,
                element: <AssistantIndexRoute />,
              },
            ],
          },
          {
            path: RootPath.Dashboard,
            element: <NewLayout />,
            children: [
              {
                index: true,
                element: (
                  <UserGuard>
                    <OrganizationDashboardResolver>
                      <OrganizationDashboard />
                    </OrganizationDashboardResolver>
                  </UserGuard>
                ),
              },
              {
                path: `${RootPath.Dashboard}/:orgId`,
                element: (
                  <UserGuard>
                    <OrganizationDashboardResolver>
                      <OrganizationDashboard />
                    </OrganizationDashboardResolver>
                  </UserGuard>
                ),
              },
            ],
          },
          {
            path: RootPath.Rep,
            element: <NewLayout />,
            children: [
              {
                index: true,
                element: <RepRoute />,
              },
              {
                path: "*",
                element: <RepRoute />,
              },
            ],
          },
          {
            path: RootPath.Settings,
            element: <NewLayout />,
            children: [
              {
                index: true,
                element: <Settings />,
              },
            ],
          },
          {
            path: RootPath.Site,
            element: <NewLayout />,
            children: [
              {
                index: true,
                element: <SitesRoute />,
              },
              {
                path: "*",
                element: <SitesRoute />,
              },
            ],
          },
          {
            path: "*",
            element: <Root />,
          },
        ],
      },
    ],
  },
])

export const App: FC = () => {
  useGtag()

  return <RouterProvider router={router} />
}
