import type { Feature } from "@/models/feature"
import type { User, UserCreate } from "@/models/user"
import type { UtilityCredentialsData } from "@/modules/utilityCredentials/model/utilityCredentials"
import type { AxiosInstance, AxiosResponse } from "axios"
import axios from "axios"

import { initCaseResponseInterceptor } from "./interceptors"

export interface SuccessData {
  success: boolean
}

export interface TokenUser {
  email: string
  id: string
}

export interface GetUserByPasswordResetTokenData extends SuccessData {
  user: TokenUser
}

export interface LoginResponse {
  authenticationToken: string
  success: boolean
}

export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? "",
})

// Set up interceptors on the Axios instance
initCaseResponseInterceptor(httpClient)

export const fetchFeatures = async (): Promise<Feature[]> => {
  const response = await httpClient.get("/flipper/api/features")

  // Mass lint disable
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return response.data.features
}

export const createUser = async (
  newUser: UserCreate
): Promise<AxiosResponse<SuccessData>> => {
  const response: AxiosResponse<SuccessData> = await httpClient.post(
    "/api/v1/users",
    { user: newUser }
  )

  return response
}

// We are not returning a User class instance but instead a pojo that represents a user
export const getUser = async (params = {}): Promise<User> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient
    .get("/api/v1/user", { params })
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((response) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ...response.data.data.attributes,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      id: response.data.data.id as string,
    }))
    .catch(() => null)

export const doLogin = async ({
  data = {},
  params = {},
}): Promise<LoginResponse | null> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient
    .post("/api/v1/sessions", data, params)
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((response) => response.data)
    .catch(() => null)

export const doLogout = async (params = {}) =>
  httpClient
    .delete("/api/v1/sessions", params)
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((response) => response.data)
    .catch(() => null)

export const sendForgotPasswordEmail = async (params): Promise<SuccessData> =>
  httpClient.post("/api/v1/forgot_password/", params).then((response) => {
    return response.data as SuccessData
  })

export const getUserByPasswordResetToken = async (
  token
): Promise<TokenUser | null> => {
  const response = await httpClient.get(`/api/v1/password_reset/${token}`)
  const data = response.data as GetUserByPasswordResetTokenData

  return data.success ? data.user : null
}

export const fetchUtilityCredentials = async (
  token
): Promise<UtilityCredentialsData> =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient
    .get(`/credentials/validate_token?token=${token}`)
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((response) => response.data)

export const postUtilityCredentials = async (data) =>
  // Mass eslint disable @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  httpClient.post("/api/v1/credentials", data).then((response) => response.data)

export const updateUserPassword = async (
  token: string,
  params: { password: string }
): Promise<SuccessData> =>
  httpClient
    .patch(`/api/v1/password_reset/${token}`, params)
    .then((response) => response.data as SuccessData)

export const parseFloatMetaStatSum = (metaStatSum: string | undefined) =>
  metaStatSum ? parseFloat(metaStatSum) : 0
