export interface UtilityCredentialsData {
  aud?: string
  aud_short_name?: string
  exp?: number
  iss?: string
  sub?: number
}

export enum UtilityFormType {
  Electric = "electricity",
  Error = "error",
  NaturalGas = "natural gas",
  Success = "success",
}

export enum UtilityFormStateType {
  Complete = "complete",
  Error = "error",
  Incomplete = "incomplete",
}

export enum PostRequestStatusType {
  Error = "error",
  Success = "success",
}

export interface currentFormStateType {
  electric: UtilityFormStateType
  naturalGas: UtilityFormStateType
}

export enum AuthenticationOptionType {
  SecurityQuestion = "security_question",
  TwoFactorAuthentication = "2FA",
}

export enum UtilityProviderType {
  NationalGrid = "national grid",
  conEdison = "conedison",
}

export const electricityProviders = ["conedison"] as const

export const naturalGasProviders = ["conedison", "national grid"] as const

export const securityQuestions = [
  "What is the food you least liked as a child?",
  "What is the name of your first stuffed animal?",
  "What did you earn your first medal or award for?",
  "What is your favorite security question?",
  "What is the toy/stuffed animal you liked the most as a kid?",
  "What was the first computer game you played?",
  "What is your favorite movie quote?",
  "What was the mascot of the first sports team you played on?",
  "What music album or song did you first purchase?",
  "What is your favorite piece of art?",
  "What was your grandmother's favorite dessert?",
  "What was the first thing you learned to cook?",
  "What was your dream job as a child?",
  "Where did you meet your spouse/significant other?",
  "Where did you go for your favorite vacation?",
  "Where were you on New Year's Eve in the year 2000?",
  "Who is your favorite speaker/orator?",
  "Who is your favorite book/movie character?",
  "Who is your favorite sports player?",
] as const

export const AuthenticationOption = ["security_question", "2FA"] as const
