import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { PerformanceCell } from "@/components/performance"
import { Blur } from "@/nzds"
import {
  DataGridColumnHeader,
  baseColumnDef,
  baseNumberColumnDef,
} from "@/nzds/data-grid"
import { FeatureFlags, useFeature } from "@/services/feature"

import { Autocomplete, TextField } from "@mui/material"
import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro"

import { DecimalWithPlaceholder } from "../../../../components/decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { useOrganizationContext } from "../../../../contexts/organizationProvider"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { UnitName } from "../../../../models/unit"
import { translateUnit } from "../../../../utils"
import { CombinedPerformanceCellPopover } from "../components/combined-performance-cell-popover/combined-performance-cell-popover"
import type { RowData } from "../pages.types"
import { buildingTypeOptions } from "../rep-details-route/utils/comstock-building-types-list"
import {
  calculateIsBlurred,
  useCommonResultsColumns,
} from "./use-common-results-columns"

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {}

export const defaultPinnedColumns: GridPinnedColumns = {
  left: ["rowNumber", "name", "benchmark_annual_combined_percentile"],
  right: ["planning"],
}

export const defaultSortModel: GridSortModel = [
  { field: "benchmark_annual_combined_percentile", sort: "asc" },
]

export const usePerformanceResultsColumns = (): GridColDef<RowData>[] => {
  const { organization } = useOrganizationContext()
  const { i18n } = useTranslation()
  const { isFeatureEnabled } = useFeature()

  const isEditingEnabled = isFeatureEnabled(
    FeatureFlags.REP_EDITABLE_RESULTS_GRID,
    organization
  )

  const { format: formatDecimalToTenths } = useFormatDecimal({
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  })

  const commonResultsColumns = useCommonResultsColumns()

  const columns: GridColDef<RowData>[] = useMemo(() => {
    return [
      {
        ...commonResultsColumns.rowNumber,
      },
      {
        ...commonResultsColumns.siteName,
      },
      {
        ...baseColumnDef,
        field: "department",
        headerName: "Group",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {params.row.department}
          </Blur>
        ),
        width: 160,
      },
      {
        ...baseColumnDef,
        field: "location",
        headerName: "Location",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {params.row.location}
          </Blur>
        ),
        width: 192,
      },
      {
        ...baseColumnDef,
        editable: isEditingEnabled,
        field: "building_type",
        headerName: "Building Type",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {params.row.building_type}
          </Blur>
        ),
        renderEditCell: isEditingEnabled
          ? (params: GridRenderCellParams) => (
              <Autocomplete
                fullWidth
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.label
                }
                onChange={(_, newValue) => {
                  if (!newValue) {
                    return
                  }
                  void params.api.setEditCellValue({
                    id: params.id,
                    field: params.field,
                    value:
                      typeof newValue === "string" ? newValue : newValue.label,
                  })
                }}
                options={buildingTypeOptions}
                renderInput={(inputParams) => (
                  <TextField {...inputParams} fullWidth />
                )}
                value={buildingTypeOptions.find(
                  (option) => option.value === params.value
                )}
              />
            )
          : undefined,
        width: 176,
      },
      {
        ...commonResultsColumns.buildingArea,
      },
      {
        ...baseNumberColumnDef,
        field: "combined_eui",
        headerName: "Combined EUI",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            {formatDecimalToTenths(
              params.row.combined_volume && params.row.sq_ft
                ? params.row.combined_volume / params.row.sq_ft
                : null
            )}
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Combined EUI"
            field="combined_eui"
            headerName="Combined EUI"
            unit={translateUnit(
              i18n.language,
              UnitName.KiloBritishThermalUnitPerSquareFoot
            )}
          />
        ),
        sortable: false,
      },
      {
        ...baseNumberColumnDef,
        field: "benchmark_annual_usage_combined",
        headerName: "Combined Benchmark",
        renderCell: (params: GridRenderCellParams<RowData>) => (
          <Blur isBlurred={calculateIsBlurred(params.row.id)}>
            <DecimalWithPlaceholder
              format={{
                precision: 1,
              }}
              value={
                (params.row.benchmark_annual_usage_electricity ?? 0) +
                (params.row.benchmark_annual_usage_ng ?? 0)
              }
            />
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Combined Benchmark"
            field="benchmark_annual_usage_combined"
            headerName="Combined Benchmark"
            tooltipContent="Aggregated sum of electricity
          and natural gas ENERGY STAR
          Benchmark."
            unit={`${translateUnit(
              i18n.language,
              UnitName.KiloBritishThermalUnitPerSquareFoot
            )}`}
          />
        ),
        width: 250,
      },
      {
        ...commonResultsColumns.combinedVolume,
      },
      {
        ...baseNumberColumnDef,
        field: "benchmark_annual_combined_percentile",
        headerName: "Building Performance",
        hideable: false,
        pinnable: false,
        renderCell: ({
          row,
          value,
        }: GridRenderCellParams<
          RowData,
          RowData["benchmark_annual_combined_percentile"]
        >) => (
          <Blur isBlurred={calculateIsBlurred(row.id)}>
            <CombinedPerformanceCellPopover
              benchmark_annual_usage_electricity_percentile_value={
                row.benchmark_annual_usage_electricity_percentile
              }
              benchmark_annual_usage_ng_percentile_value={
                row.benchmark_annual_usage_ng_percentile
              }
            >
              <PerformanceCell value={value} />
            </CombinedPerformanceCellPopover>
          </Blur>
        ),
        renderHeader: () => (
          <DataGridColumnHeader
            description="Building Performance (Percentile)"
            field="benchmark_annual_combined_percentile"
            headerName="Building Performance"
            tooltipContent="Percentile indicates how the
          combined EUI of your
          building compares to
          similar building types in the
          ENERGY STAR dataset."
            unit="Percentile"
          />
        ),
        width: 250,
      },
      {
        ...commonResultsColumns.planning,
      },
    ]
  }, [commonResultsColumns, i18n.language, isEditingEnabled])

  return columns
}
