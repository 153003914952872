import { z } from "zod"

import {
  AuthenticationOption,
  naturalGasProviders,
  securityQuestions,
} from "../model/utilityCredentials"

export const baseUtilityCredentialsSchema = z.object({
  username: z.string().trim().email(),
  password: z.string().trim().min(6),
  utility_provider: z
    .enum(naturalGasProviders)
    .or(z.literal(""))
    // Mass lint disable
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    .refine((val) => naturalGasProviders.includes(val as any), {
      message: "Utility provider is required",
    }),
  two_factor_auth_notes: z.string().optional(),
})

const securityQuestionSchema = baseUtilityCredentialsSchema.extend({
  authentication_option: z.literal(AuthenticationOption[0]),
  question: z.enum(securityQuestions).or(z.literal("")),
  answer: z.string(),
})

const twoFactorSchema = baseUtilityCredentialsSchema.extend({
  authentication_option: z.literal(AuthenticationOption[1]),
  two_factor_auth_notes: z.string().optional(),
})

export const utilityCredentialsSchema = z
  .discriminatedUnion("authentication_option", [
    securityQuestionSchema,
    twoFactorSchema,
  ])
  .refine(
    (data) => {
      if (
        data.utility_provider === "conedison" &&
        data.authentication_option === "security_question"
      ) {
        // Mass lint disable
        // Mass eslint disable @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        return securityQuestions.includes(data.question as any)
      }

      return true
    },
    {
      message: "Security question is required",
      path: ["question"],
    }
  )
  .refine(
    (data) => {
      if (
        data.utility_provider === "conedison" &&
        data.authentication_option === "security_question"
      ) {
        return data.answer.trim().length > 0
      }

      return true
    },
    {
      message: "Answer is required",
      path: ["answer"],
    }
  )

// if utility_provider === 'conEdison'
// if security question
// require question and answer
// if 2fa
// notes field is available

export type UtilityCredentialsSchemaType = z.infer<
  typeof utilityCredentialsSchema
>
