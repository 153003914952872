import React from "react"
import { NavLink } from "react-router-dom"

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"

import { primary600, primary700 } from "../../../../../../utils"

interface NavItemButtonProps {
  // eslint-disable-next-line react/require-default-props
  dataE2e?: string
  icon: JSX.Element
  id: string
  isOpen: boolean
  title: string
  to: string
}

export const NavItemButton = ({
  dataE2e,
  icon,
  isOpen,
  id,
  title,
  to,
}: NavItemButtonProps) => (
  <ListItem key={title} disablePadding sx={{ display: "block" }}>
    <Tooltip disableHoverListener={isOpen} placement="right" title={title}>
      <ListItemButton
        component={NavLink}
        data-e2e={dataE2e}
        id={id}
        sx={{
          color: (theme) => theme.palette.common.white,
          minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          "&.active": {
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: (theme) => theme.palette.common.white,
            ":hover": {
              backgroundColor: primary600.toString(),
            },
          },
          "&.MuiListItemButton-root:not(.active):hover": {
            backgroundColor: primary700.toString(),
          },
        }}
        to={to}
      >
        <ListItemIcon
          sx={{
            color: (theme) => theme.palette.common.white,
            minWidth: 0,
            mr: isOpen ? 1 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        {isOpen ? (
          <ListItemText
            primary={<Typography variant="body2">{title}</Typography>}
          />
        ) : null}
      </ListItemButton>
    </Tooltip>
  </ListItem>
)
