import React from "react"
import type { FC } from "react"
import { NavLink, useParams } from "react-router-dom"

import { useOrganizationContext } from "@/contexts"
import { ReportPath, RootPath } from "@/models/route"
import {
  useAuthentication,
  useSigmaWorkbookEmbedUrl,
  useSigmaWorkbookSecureEmbedUrl,
} from "@/services"

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded"
import { Box, Button, Divider, useTheme } from "@mui/material"

const CustomReport: FC = () => {
  const { authenticatedUser } = useAuthentication()
  const { organization } = useOrganizationContext()
  const { workbookId } = useParams<{ workbookId: string }>()
  const { sigmaWorkbookEmbedUrlData } = useSigmaWorkbookEmbedUrl(workbookId)
  const {
    sigmaWorkbookSecureEmbedUrlData,
    sigmaWorkbookSecureEmbedUrlIsFetched,
  } = useSigmaWorkbookSecureEmbedUrl({
    email: authenticatedUser?.email,
    embedPath: sigmaWorkbookEmbedUrlData?.embedUrl,
    externalUserTeam: organization?.name,
    organizationId: organization?.id,
  })

  const theme = useTheme()

  return (
    <>
      <Button
        component={NavLink}
        startIcon={<ChevronLeftRoundedIcon />}
        to={`${RootPath.Report}/${organization?.id}/${ReportPath.CustomReports}`}
      >
        Back to Custom Reports
      </Button>
      <Divider sx={{ mb: 2 }} />
      {sigmaWorkbookSecureEmbedUrlIsFetched && (
        <Box
          sx={{
            bottom: theme.spacing(3),
            position: "absolute",
            top: theme.spacing(15),
            left: theme.spacing(3),
            right: theme.spacing(3),
          }}
        >
          <iframe
            height="100%"
            src={sigmaWorkbookSecureEmbedUrlData?.secureEmbedUrl}
            style={{ border: "none" }}
            title="Custom Report"
            width="100%"
          />
        </Box>
      )}
    </>
  )
}

export default CustomReport
