import React from "react"
import type { FC } from "react"

import nzeroSymbol from "@/assets/images/nzero-symbol.svg"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { useAuthentication } from "@/services"
import { AiChat, AiChatUI, useAsBatchAdapter } from "@nlux/react"
import "@nlux/themes/unstyled.css"

import { CircularProgress } from "@mui/material"

import { assistants } from "../../assistant.constants"
import type { Assistant } from "../../assistant.types"
import { initThread, sendMessage } from "../../services/assistant"
import "./assistant-chat-theme.css"
import type { AssistantChatProps } from "./assistant-chat.types"

export const AssistantChat: FC<AssistantChatProps> = ({
  api,
  initialConversation,
  onThreadInit,
  thread,
  type,
}) => {
  const { authenticatedUser } = useAuthentication()
  const { organization } = useOrganizationContext()

  const assistant: Assistant | undefined = assistants.find(
    (a) => a.type === type
  )

  const assistantAdapter = useAsBatchAdapter(
    async (message: string): Promise<string> => {
      if (!initialConversation) {
        try {
          const response = await initThread({
            assistant_user_id: "-1", // authenticatedUser?.id ?? "",
            organization_id: organization?.id ?? "",
            specialty: type,
          })

          onThreadInit(response)

          return sendMessage({
            assistant_id: response.assistantId,
            messages: [
              {
                content: message,
                role: "user",
              },
            ],
            organization_id: organization?.id ?? "",
            organization_name: organization?.name ?? "",
            specialty: type,
            thread_id: response.threadId,
          })
        } catch {
          console.error("Failed to initialize assistant or send a message")

          return "Sorry, I ran into an unexpected error. Please try again later."
        }
      }

      return sendMessage({
        assistant_id: thread?.assistantId ?? "",
        messages: [
          {
            content: message,
            role: "user",
          },
        ],
        organization_id: organization?.id ?? "",
        organization_name: organization?.name ?? "",
        specialty: type,
        thread_id: thread?.threadId ?? "",
      })
    },
    [authenticatedUser, organization, thread, type]
  )

  return (
    <AiChat
      adapter={assistantAdapter}
      api={api}
      composerOptions={{
        placeholder: "How can I help you?",
      }}
      conversationOptions={{
        historyPayloadSize: "max",
      }}
      displayOptions={{
        themeId: "nzero",
      }}
      initialConversation={initialConversation}
      personaOptions={{
        assistant: {
          name: `${assistant?.name} Assistant`,
          avatar: nzeroSymbol as string,
          tagline: assistant?.description,
        },
      }}
    >
      <AiChatUI.Loader>
        <CircularProgress color="primary" size={24} />
      </AiChatUI.Loader>
    </AiChat>
  )
}
