import { useMemo } from "react"
import type { InfiniteData } from "react-query"

import { useAuthentication } from "@/services"
import type { Schemas } from "@/services/types"

import { freeUserAllowedRows } from "../constants"

interface UseResultsCtaResult {
  isResultsCtaVisible: boolean
}

export const useResultsCta = (
  data?: InfiniteData<Schemas["EmissionProfilePage"]>
): UseResultsCtaResult => {
  const { isFreeUser } = useAuthentication()
  let isResultsCtaVisible = false

  if (
    data &&
    data.pages.length > 0 &&
    isFreeUser &&
    data.pages[0].total > freeUserAllowedRows
  ) {
    isResultsCtaVisible = true
  }

  return useMemo(
    () => ({
      isResultsCtaVisible,
    }),
    [isResultsCtaVisible]
  )
}
