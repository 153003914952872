import React from "react"
import type { SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { PageCard, PageLoading } from "@/components"

import { Box, Stack } from "@mui/material"

import { PageTitle } from "../../../../components/page-title/page-title"
import { SectionHeader } from "../../../../components/section-header/section-header"
import { RootPath } from "../../../../models/route"
import { Form } from "../../../../nzds/forms/form/form"
import { SubmitButton } from "../../../../nzds/forms/submit-button/submit-button"
import { useForm } from "../../../../nzds/forms/use-form/use-form"
import { SiteExplorerSiteInformationFields } from "../../components/site-explorer-site-information-fields/site-explorer-site-information-fields"
import { RecommendationsPath } from "../../enums"
import type { SiteInputSchema } from "../../utils/site-input-schema"
import { siteInputSchema } from "../../utils/site-input-schema"

export const SiteExplorerIndexRoute = () => {
  const isLoading = false

  const navigate = useNavigate()

  const form = useForm<typeof siteInputSchema>({
    schema: siteInputSchema,
    defaultValues: {
      annualUsageGas: "",
      annualUsageElectric: "",
      in_comstock_building_type: "",
      in_county_name: "",
      in_sqft: "",
      operatingHours: "",
    },
  })

  const handleSubmit: SubmitHandler<SiteInputSchema> = ({
    in_comstock_building_type,
    in_county_name,
    in_sqft,
    in_year_built,
    annualUsageGas,
    annualUsageElectric,
    operatingHours,
  }) => {
    navigate(
      `${RootPath.Recommendations}/${RecommendationsPath.JumpStartResult}`,
      {
        state: {
          in_comstock_building_type,
          in_county_name,
          in_sqft,
          in_year_built,
          annualUsageGas,
          annualUsageElectric,
          operatingHours,
        },
      }
    )
  }

  return (
    <PageLoading isLoading={isLoading}>
      <Stack gap={3}>
        <PageTitle title="Preliminary Site Assessment" />
        <PageCard>
          <Form form={form} onSubmit={handleSubmit}>
            <Stack gap={2}>
              <SectionHeader title="Step 1: Add Site Information" />
              <SiteExplorerSiteInformationFields />
              <Box justifyContent="flex-start" mt={1}>
                <SubmitButton disableFormStateCheck>
                  Generate Assessment
                </SubmitButton>
              </Box>
            </Stack>
          </Form>
        </PageCard>
      </Stack>
    </PageLoading>
  )
}
