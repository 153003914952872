import React, { useCallback, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

import { PageCard, PageLoading } from "@/components"
import type { Paths } from "@/services/types"

import { Box, Button, Stack } from "@mui/material"

import { PageTitle } from "../../../../components/page-title/page-title"
import { SectionHeader } from "../../../../components/section-header/section-header"
import { useUnit } from "../../../../components/unit/use-unit"
import { useFormatDecimal } from "../../../../formatters/useFormatDecimal"
import { useFormatMoney } from "../../../../formatters/useFormatMoney"
import { useCurrencySymbol } from "../../../../hooks/useCurrencySymbol/useCurrencySymbol"
import { RootPath } from "../../../../models/route"
import { UnitName } from "../../../../models/unit"
import { HStack } from "../../../../nzds"
import { Form } from "../../../../nzds/forms/form/form"
import { useForm } from "../../../../nzds/forms/use-form/use-form"
import { SiteExplorerResultChart } from "../../components/site-explorer-result-chart/site-explorer-result-chart"
import { SiteExplorerRetrofitField } from "../../components/site-explorer-retrofit-field/site-explorer-retrofit-field"
import { SiteExplorerSiteInformationFields } from "../../components/site-explorer-site-information-fields/site-explorer-site-information-fields"
import { SiteExplorerStatCard } from "../../components/site-explorer-stat-card/site-explorer-stat-card"
import { RecommendationsPath } from "../../enums"
import { useComstockPredict } from "../../services/use-comstock-predict/use-comstock-predict"
import { energyCodeOptions } from "../../utils/energy-code-options"
import { lightingOptions } from "../../utils/lighting-options"
import type { SiteInputSchema } from "../../utils/site-input-schema"
import { siteInputSchema } from "../../utils/site-input-schema"
import { buildCommonMeta } from "./build-common-meta"
import type { ModelRetrofitsSchema } from "./model-retrofits-schema"
import { modelRetrofitsSchema } from "./model-retrofits-schema"

const retrofitHasValues = (values: ModelRetrofitsSchema): boolean => {
  const { before, after } = values
  const beforeValues = Object.values(before)
  const afterValues = Object.values(after)

  return (
    beforeValues.some((value) => value !== "") ||
    afterValues.some((value) => value !== "")
  )
}

export const SiteExplorerResultRoute = () => {
  const location = useLocation()

  const { format } = useFormatMoney({
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    notation: "compact",
  })

  const isRetrofitApplied = useRef(false)

  const { format: formatDecimal } = useFormatDecimal()

  const siteInputState = location.state
    ? (location.state as SiteInputSchema)
    : undefined

  const siteInputForm = useForm<typeof siteInputSchema>({
    schema: siteInputSchema,
    defaultValues: {
      annualUsageGas: siteInputState?.annualUsageGas ?? "",
      annualUsageElectric: siteInputState?.annualUsageElectric ?? "",
      in_comstock_building_type:
        siteInputState?.in_comstock_building_type ?? "",
      in_county_name: siteInputState?.in_county_name ?? "",
      in_sqft: siteInputState?.in_sqft ?? "",
      in_year_built: siteInputState?.in_year_built ?? "",
      operatingHours: siteInputState?.operatingHours ?? "",
    },
  })

  const retrofitForm = useForm<typeof modelRetrofitsSchema>({
    schema: modelRetrofitsSchema,
    defaultValues: {
      before: {
        in_interior_lighting_generation: "",
        in_energy_code_followed_during_last_hvac_replacement: "",
        in_energy_code_followed_during_last_roof_replacement: "",
        in_energy_code_followed_during_last_svc_water_htg_replacement: "",
      },
      after: {
        in_interior_lighting_generation: "",
        in_energy_code_followed_during_last_hvac_replacement: "",
        in_energy_code_followed_during_last_roof_replacement: "",
        in_energy_code_followed_during_last_svc_water_htg_replacement: "",
      },
    },
  })

  const kWattHourLabel = useUnit(UnitName.KilowattHour)
  const MegawattHourLabel = useUnit(UnitName.MegawattHour)
  const currencySymbol = useCurrencySymbol()
  const kwhsqftLabel = useUnit(UnitName.KilowattHourPerSquareFoot)

  const combineFormValues = useCallback(
    (
      siteInputValues: SiteInputSchema,
      retrofitValues?: ModelRetrofitsSchema
    ) => {
      const commonMeta = buildCommonMeta(siteInputValues)
      let beforeMeta: Record<string, string | number | undefined> = {
        ...commonMeta,
      }
      let afterMeta: Record<string, string | number | undefined> = {
        ...commonMeta,
      }
      if (retrofitValues) {
        const { before, after } = retrofitValues
        beforeMeta = {
          ...beforeMeta,
          in_interior_lighting_generation:
            before.in_interior_lighting_generation,
          in_energy_code_followed_during_last_hvac_replacement:
            before.in_energy_code_followed_during_last_hvac_replacement,
          in_energy_code_followed_during_last_roof_replacement:
            before.in_energy_code_followed_during_last_roof_replacement,
          in_energy_code_followed_during_last_svc_water_htg_replacement:
            before.in_energy_code_followed_during_last_svc_water_htg_replacement,
        }

        afterMeta = {
          ...afterMeta,
          in_interior_lighting_generation:
            after.in_interior_lighting_generation,
          in_energy_code_followed_during_last_hvac_replacement:
            after.in_energy_code_followed_during_last_hvac_replacement,
          in_energy_code_followed_during_last_roof_replacement:
            after.in_energy_code_followed_during_last_roof_replacement,
          in_energy_code_followed_during_last_svc_water_htg_replacement:
            after.in_energy_code_followed_during_last_svc_water_htg_replacement,
        }
      }

      const combinedMeta = {
        input_data_before: beforeMeta,
        input_data_after: afterMeta,
      }

      return combinedMeta
    },
    []
  )

  const [predictRequestBody, setpredictRequestBody] = useState<
    Omit<
      Paths["/api/v1/recommendations/comstock_predict"]["post"]["requestBody"]["content"]["application/json"],
      "model_id"
    >
  >(() => combineFormValues(siteInputForm.getValues()))

  const onSubmitSiteInputForm = (siteInputValues: SiteInputSchema) => {
    const retrofitValues = retrofitForm.getValues()
    const combinedMeta = combineFormValues(siteInputValues, retrofitValues)

    setpredictRequestBody(combinedMeta)
  }

  const onSubmitRetrofitForm = (retrofitValues: ModelRetrofitsSchema) => {
    const siteInputValues = siteInputForm.getValues()
    const combinedMeta = combineFormValues(siteInputValues, retrofitValues)

    setpredictRequestBody(combinedMeta)
  }

  const { data: prediction, isFetching } = useComstockPredict({
    requestBody: predictRequestBody,
    queryOptions: {
      keepPreviousData: true,
      onSuccess: () => {
        isRetrofitApplied.current = retrofitHasValues(retrofitForm.getValues())
      },
    },
  })

  const costPercentageChange =
    prediction?.annual_cost_usd_after && prediction.annual_cost_usd_before
      ? (prediction.annual_cost_usd_after - prediction.annual_cost_usd_before) /
        prediction.annual_cost_usd_before
      : undefined

  const usagePercentageChange =
    prediction?.annual_usage_kwh_after && prediction.annual_usage_kwh_before
      ? (prediction.annual_usage_kwh_after -
          prediction.annual_usage_kwh_before) /
        prediction.annual_usage_kwh_before
      : undefined

  const euiPercentageChange =
    prediction?.annual_eui_kwh_per_sqft_after &&
    prediction.annual_eui_kwh_per_sqft_before
      ? (prediction.annual_eui_kwh_per_sqft_after -
          prediction.annual_eui_kwh_per_sqft_before) /
        prediction.annual_eui_kwh_per_sqft_before
      : undefined

  return (
    <PageLoading isLoading={isFetching} showContent={Boolean(prediction)}>
      <Stack gap={3}>
        <PageTitle
          back={{
            // add state as an option?
            label: "Back to enter building information",
            to: `${RootPath.Recommendations}/${RecommendationsPath.JumpStart}`,
          }}
          title="Preliminary Site Assessment Results"
        />
        <PageCard>
          <Form form={siteInputForm}>
            <Stack gap={2}>
              <SectionHeader
                actions={
                  <Button
                    onClick={() =>
                      void siteInputForm.handleSubmit(onSubmitSiteInputForm)()
                    }
                    variant="contained"
                  >
                    Update
                  </Button>
                }
                title="Step 1: Add Site Information"
              />
              <SiteExplorerSiteInformationFields />
            </Stack>
          </Form>
        </PageCard>
        <PageCard>
          <Stack gap={2}>
            <SectionHeader
              actions={
                <Button
                  onClick={() =>
                    void retrofitForm.handleSubmit(onSubmitRetrofitForm)()
                  }
                  variant="contained"
                >
                  Update
                </Button>
              }
              title="Step 2: Model Retrofits"
            />
            <Box display="flex" position="relative">
              <Box
                height="100%"
                overflow="auto"
                position="absolute"
                width={316}
              >
                <Form form={retrofitForm}>
                  <Stack gap={2} pr={2}>
                    <SiteExplorerRetrofitField
                      field="in_interior_lighting_generation"
                      options={lightingOptions}
                      title="Interior Lighting"
                    />
                    <SiteExplorerRetrofitField
                      field="in_energy_code_followed_during_last_hvac_replacement"
                      options={energyCodeOptions}
                      title="HVAC Replacement"
                    />
                    <SiteExplorerRetrofitField
                      field="in_energy_code_followed_during_last_roof_replacement"
                      options={energyCodeOptions}
                      title="Roof Replacement"
                    />
                    <SiteExplorerRetrofitField
                      field="in_energy_code_followed_during_last_svc_water_htg_replacement"
                      options={energyCodeOptions}
                      title="Water Heater Replacement"
                    />
                  </Stack>
                </Form>
              </Box>
              <Box flex="1 0 calc(100% - 340px)" ml="340px">
                <Stack gap={2}>
                  <HStack gap={3}>
                    <SiteExplorerStatCard
                      from={
                        prediction?.annual_usage_kwh_before
                          ? formatDecimal(
                              prediction.annual_usage_kwh_before / 1000
                            )
                          : undefined
                      }
                      percentageChange={usagePercentageChange}
                      showTo={isRetrofitApplied.current}
                      title="Annual Usage"
                      to={
                        prediction?.annual_usage_kwh_after
                          ? formatDecimal(
                              prediction.annual_usage_kwh_after / 1000
                            )
                          : undefined
                      }
                      unit={
                        prediction?.annual_usage_kwh_after &&
                        prediction.annual_usage_kwh_after > 1000
                          ? MegawattHourLabel
                          : kWattHourLabel
                      }
                    />
                    <SiteExplorerStatCard
                      from={
                        prediction?.annual_cost_usd_before
                          ? format(prediction.annual_cost_usd_before)
                          : undefined
                      }
                      percentageChange={costPercentageChange}
                      showTo={isRetrofitApplied.current}
                      title="Operational Cost"
                      to={
                        prediction?.annual_cost_usd_after
                          ? format(prediction.annual_cost_usd_after)
                          : undefined
                      }
                      unit={currencySymbol}
                    />
                    <SiteExplorerStatCard
                      from={
                        prediction?.annual_eui_kwh_per_sqft_before
                          ? formatDecimal(
                              prediction.annual_eui_kwh_per_sqft_before
                            )
                          : undefined
                      }
                      percentageChange={euiPercentageChange}
                      showTo={isRetrofitApplied.current}
                      title="Annual EUI"
                      to={
                        prediction?.annual_eui_kwh_per_sqft_after
                          ? formatDecimal(
                              prediction.annual_eui_kwh_per_sqft_after
                            )
                          : undefined
                      }
                      unit={kwhsqftLabel}
                    />
                  </HStack>
                  <SiteExplorerResultChart
                    predictions={prediction?.predictions}
                    showAfter={isRetrofitApplied.current}
                  />
                </Stack>
              </Box>
            </Box>
          </Stack>
        </PageCard>
      </Stack>
    </PageLoading>
  )
}
