import type { ReactNode } from "react"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { useAuthentication } from "@/services/authentication"
import { useAuthorizedOrganizations } from "@/services/organization"
import { useLocalStorage } from "@uidotdev/usehooks"

import { useOrganizationContext } from "../../../contexts/organizationProvider"
import type Organization from "../../../models/organization"
import type { UserInfo } from "../../../utils/localStorage"
import { updateUserSessionInfo } from "../../../utils/localStorage"
import { LoadingSpinner } from "../../loadingSpinner"
import Page404 from "../../nav/page404/page404"

interface OrgGuardProps {
  children: ReactNode
}

const OrgGuard: React.FC<OrgGuardProps> = ({ children }) => {
  const params = useParams()
  const { organization, setOrganization } = useOrganizationContext()
  const { authenticatedUser } = useAuthentication()
  const {
    data: authorizedOrganizations,
    isFetched: isAuthorizedOrganizationsFetched,
  } = useAuthorizedOrganizations()
  const [userSessionInfo, setUserSessionInfo] = useLocalStorage<
    Record<string, UserInfo>
  >(`${process.env.REACT_APP_ENV}UserSessionInfo`, {})

  useEffect(() => {
    if (isAuthorizedOrganizationsFetched && params.orgId) {
      const newOrganization: Organization | undefined =
        authorizedOrganizations?.find((org) => org.id === params.orgId)
      const activeOrganization = newOrganization ?? null
      setOrganization(activeOrganization)
      const updatedUserInfo = updateUserSessionInfo(
        userSessionInfo,
        authenticatedUser?.id,
        activeOrganization?.id
      )
      setUserSessionInfo(updatedUserInfo)
    }
  }, [isAuthorizedOrganizationsFetched, params.orgId])

  if (!isAuthorizedOrganizationsFetched) {
    return (
      <LoadingSpinner className="authorized-organization__loading-spinner" />
    )
  }
  if (organization === null) {
    return <Page404 />
  }
  if (organization) {
    return <>{children}</>
  }

  // Don't rendering anything by default
  return null
}

export default OrgGuard
