import React from "react"

import { FeatureFlags, useFeature } from "@/services/feature"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Button, Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import makeStyles from "@mui/styles/makeStyles"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import { useSiteSearchContext } from "../../contexts/siteSearchProvider"
import { useSiteSearchResultsActiveContext } from "../../contexts/siteSearchResultsActiveProvider"
import {
  bgColor,
  darkGray,
  lightGray,
  offWhite,
  solidGray,
} from "../../utils/colors"
import { pxToRem } from "../../utils/unit"
import { SiteSearchResultsTableContent } from "./siteSearchResultsTableContent"

const useStyles = makeStyles({
  table: {
    maxWidth: 900,
    margin: "auto",
    overflow: "hidden",
    border: `1px solid ${lightGray.toString()}`,
    borderRadius: "4px",
  },
  head: {
    backgroundColor: offWhite.toString(),
    color: darkGray.toString(),
  },
  paper: {
    backgroundColor: bgColor.toString(),
    height: "calc(100vh - 120px)",
    paddingBottom: "24px",
    boxSizing: "border-box",
  },
  leftCell: {
    width: "50%",
    padding: "12px 24px 12px 24px",
  },
  rightCell: {
    width: "50%",
    padding: "12px 24px 12px 0px",
  },
  cellText: {
    margin: "0",
  },
  title: {
    margin: "6px auto 22px auto",
    color: solidGray.toString(),
  },
  tableContainer: {
    height: "100vh",
  },
  searchResultsHeader: {
    display: "flex",
  },
})

interface SiteSearchResultsProps {
  searchTerm: string
}

export const SiteSearchResultsTable = ({
  searchTerm,
}: SiteSearchResultsProps) => {
  const classes = useStyles()
  const { searchResults } = useSiteSearchContext()
  const { setIsSiteSearchResultsActive } = useSiteSearchResultsActiveContext()
  const { organization } = useOrganizationContext()
  const { isFeatureEnabled } = useFeature()

  const isUseFlexibleHierarchyEnabled = isFeatureEnabled(
    FeatureFlags.USE_FLEXIBLE_HIERARCHY_FOR_SITE_OWNERSHIPS,
    organization
  )

  return (
    <>
      <div className={classes.searchResultsHeader}>
        <Stack ml={pxToRem(24)} position="absolute">
          <Button
            color="neutral"
            onClick={() => {
              setIsSiteSearchResultsActive(false)
            }}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
        </Stack>
        <h1 className={classes.title}>Search Results</h1>
      </div>
      <div className={classes.tableContainer}>
        <TableContainer className={classes.paper} component={Paper}>
          <Table
            aria-label="site-search-results"
            className={classes.table}
            stickyHeader
          >
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.leftCell}>
                  <p className={`${classes.cellText} paragraph--medium__heavy`}>
                    Site Name
                  </p>
                </TableCell>
                <TableCell align="left" className={classes.rightCell}>
                  <p className={`${classes.cellText} paragraph--medium__heavy`}>
                    {isUseFlexibleHierarchyEnabled ? "Group" : "Department"}
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <SiteSearchResultsTableContent
              classes={classes}
              isUseFlexibleHierarchyEnabled={isUseFlexibleHierarchyEnabled}
              searchResults={searchResults}
              searchTerm={searchTerm}
            />
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
