import type { ReactNode } from "react"
import React, { useEffect } from "react"

import { useLocalStorage } from "@uidotdev/usehooks"
import moment from "moment-timezone"

import { useOrganizationContext } from "../../contexts/organizationProvider"
import useFilters from "../../hooks/useFilters/useFilters"
import DateFilter from "../../models/filter/dateFilter"
import SingleSelectStringFilter from "../../models/filter/singleSelectStringFilter"
import type Organization from "../../models/organization"
import type { OrganizationDashboardFilters } from "../../modules/dashboard/models/dashboard"
import { useAuthentication, useAuthorizedOrganizations } from "../../services"
import type { UserInfo } from "../../utils/localStorage"
import { updateUserSessionInfo } from "../../utils/localStorage"

interface OrganizationDashboardResolverProps {
  children: ReactNode
}

/**
 * A component that ensures the organization dashboard url and form data
 * are set up before rendering the organization dashboard
 *
 * @param props - The component properties
 * @param props.children - The child nodes to render
 * @example
 * return (
 *   <OrganizationDashboardResolver>
 *     <OrganizationDashboard />
 *   </OrganizationDashboardResolver>
 * )
 */
export const OrganizationDashboardResolver = ({
  children,
}: OrganizationDashboardResolverProps) => {
  const { organization, setOrganization } = useOrganizationContext()
  const { data: authorizedOrganizations } = useAuthorizedOrganizations()
  const [userSessionInfo, setUserSessionInfo] = useLocalStorage<
    Record<string, UserInfo>
  >(`${process.env.REACT_APP_ENV}UserSessionInfo`, {})
  const { authenticatedUser } = useAuthentication()
  const { filters, setFilters } = useFilters<OrganizationDashboardFilters>({
    org: SingleSelectStringFilter,
    start: DateFilter,
    end: DateFilter,
  })

  // Ensure timezone is the client's timezone
  moment.tz.setDefault()

  // Ensure active org is set and active org is in url query params
  useEffect(() => {
    if (!organization) {
      return
    }

    const queryParamOrg: Organization | undefined =
      authorizedOrganizations?.find((org) => org.id === filters.org.value)

    // If org from filter is valid and not the active org, make it the active org
    if (queryParamOrg && queryParamOrg.id !== organization.id) {
      setOrganization(queryParamOrg)
      const updatedUserInfo = updateUserSessionInfo(
        userSessionInfo,
        authenticatedUser?.id,
        queryParamOrg.id
      )
      setUserSessionInfo(updatedUserInfo)
    }

    // If we don't have an org from filter or the org from filter is NOT a valid org
    // update query params to reflect the active org
    if (!queryParamOrg) {
      setFilters(
        {
          org: new SingleSelectStringFilter(organization.id),
        },
        { replace: true }
      )
    }
  }, [filters, organization])

  return <>{children}</>
}

export default OrganizationDashboardResolver
