import React from "react"
import ReactDOMServer from "react-dom/server"

import { filter, includes, map, sortBy, sum } from "lodash-es"
import type { Moment } from "moment"
import moment from "moment"

import { Box } from "@mui/material"

import type { IRange } from "../models/range"
import { UnitName } from "../models/unit"
import { kwhToMwh } from "../utils"
import { gray100, gray500 } from "../utils/colors"
import {
  CENTS_PER_DOLLAR,
  GRAPH_DETAIL_MODAL_ORIENTATION,
  METERED_LINE,
  TIME_RANGES,
} from "../utils/constants"
import {
  transformKwhToWattUnit,
  transformPricePerWhIntoUnit,
} from "../utils/formatters"
import { CurrencyRange } from "./currency/currencyRange/currencyRange"
import { CurrencyWithPlaceholder } from "./currency/currencyWithPlaceholder/currencyWithPlaceholder"
import { CurrencyWithPreciseFraction } from "./currency/currencyWithPreciseFraction/currencyWithPreciseFraction"
import { DateDisplay } from "./date/dateDisplay/dateDisplay"
import { DecimalWithPlaceholder } from "./decimal/decimalWithPlaceholder/decimalWithPlaceholder"
import { EnergyUnitDecimal } from "./decimal/energyUnitDecimal/energyUnitDecimal"
import type { DateRangeWithType } from "./graph/dateRangeWithType"
import type Product from "./graph/product"
import { If } from "./if/if"
import { Unit } from "./unit/unit"

export const getAverageCostPerKwh = ({
  totalCost,
  lineValue,
  isMetered,
}: {
  isMetered: boolean
  lineValue: number
  totalCost: number | string
}): number | null => {
  if (!isMetered) {
    return null
  }

  // Electricity price is always displayed in $/kWh - lineValue is in kWh unit
  return Number(totalCost) / Math.abs(lineValue)
}

export const getFlyoutData = (
  products: Product[],
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour,
  timeRange: TIME_RANGES = TIME_RANGES.MONTH
): {
  costSum: number | string
  imbalanceClass
  isMetered: null
  productCosts: {
    productCost: number
    productId: string
    productOrder: number
  }[]
  productPrices: {
    productId: string
    productOrder: number
    productPrice: IRange<number | string>
  }[]
  productSummaries: {
    displayName: string
    productColor: string
    productCount: string
    productId: string
    productOrder: number
    strokeColor: string
  }[]
  volumeSumKwh: number
} => {
  const productSummaries: {
    displayName: string
    productColor: string
    productCount: string
    productId: string
    productOrder: number
    strokeColor: string
  }[] = []
  const productPrices = []
  const productCosts = []
  const rawCosts = []
  const productVolumes = []
  const sortedProducts = sortBy(products, (product) => product.order)
  const filteredProducts = filter(
    sortedProducts,
    (product) =>
      (includes(product.id, "confirm") && product.kwh > 0) ||
      !includes(product.id, "confirm")
  )
  const ENERGY_UNIT_DECIMAL_PLACEHOLDER = "--"

  filteredProducts.forEach((product) => {
    const {
      id,
      price,
      priceRange,
      cost,
      kwh,
      imbalanceKwh,
      order: productOrder,
      displayName,
      dailyAvgKwh,
    } = product
    const curtailmentImbalance = id === "curtailment" && imbalanceKwh === 0
    const productColor = product.fillColor().rgb().string()
    const strokeColor = product.strokeColor().rgb().string()

    // Electricity price is always displayed in $/kWh
    let productPrice: IRange<number | string>

    if (!curtailmentImbalance) {
      // "price" is currently in cents
      const unformattedPrice: number =
        transformPricePerWhIntoUnit(
          price,
          UnitName.MegawattHour,
          UnitName.KilowattHour
        ) / CENTS_PER_DOLLAR

      productPrice = { start: unformattedPrice, end: null }

      if (priceRange && priceRange.max !== priceRange.min) {
        const unformattedMinPrice: number =
          transformPricePerWhIntoUnit(
            priceRange.min,
            UnitName.MegawattHour,
            UnitName.KilowattHour
          ) / CENTS_PER_DOLLAR

        const unformattedMaxPrice: number =
          transformPricePerWhIntoUnit(
            priceRange.max,
            UnitName.MegawattHour,
            UnitName.KilowattHour
          ) / CENTS_PER_DOLLAR

        productPrice = { start: unformattedMinPrice, end: unformattedMaxPrice }
      }
    }

    let productCost: number

    if (!curtailmentImbalance) {
      // "cost" is currently in cents
      productCost = cost / CENTS_PER_DOLLAR
    }

    let productCount = ReactDOMServer.renderToString(
      EnergyUnitDecimal({
        value: transformKwhToWattUnit(kwh, wattUnit),
        placeholder: ENERGY_UNIT_DECIMAL_PLACEHOLDER,
      })
    )

    if (timeRange === TIME_RANGES.YEAR) {
      productCount = ReactDOMServer.renderToString(
        EnergyUnitDecimal({
          value: transformKwhToWattUnit(dailyAvgKwh, wattUnit),
          placeholder: ENERGY_UNIT_DECIMAL_PLACEHOLDER,
        })
      )
    }

    productSummaries.push({
      productColor,
      strokeColor,
      productOrder,
      productCount,
      productId: id,
      displayName,
    })
    productPrices.push({
      productId: id,
      productOrder,
      productPrice,
    })
    productCosts.push({
      productCost,
      productOrder,
      productId: id,
    })

    // "cost" is currently in cents
    rawCosts.push(cost / CENTS_PER_DOLLAR)
    productVolumes.push(kwh)
  })

  const productIdToNumber = (product: {
    productId: string
    productOrder: number
  }) => {
    if (
      includes(product.productId, "Received") ||
      includes(product.productId, "Delivered")
    ) {
      return product.productOrder * -1
    }
    if (includes(product.productId, "confirm")) {
      return product.productOrder * -1
    }
    if (product.productId === "curtailment") {
      return -10000
    }
    if (
      product.productId === "shortImbalance" ||
      product.productId === "longImbalance" ||
      product.productId === "imbalance"
    ) {
      return -10001
    }
  }
  const imbalanceSum = sum(
    map(products, (product) => product.imbalanceKwh || 0)
  )

  let imbalanceClass = "no-imbalance"
  if (imbalanceSum > 0) {
    imbalanceClass = "short-imbalance"
  } else if (imbalanceSum < 0) {
    imbalanceClass = "long-imbalance"
  }

  return {
    imbalanceClass,
    isMetered: null,
    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
    productCosts: sortBy(productCosts, (o) => productIdToNumber(o)),
    // migration to strict mode batch disable
    // Mass lint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
    productPrices: sortBy(productPrices, (o) => productIdToNumber(o)),
    productSummaries: sortBy(productSummaries, (o) => productIdToNumber(o)),
    // migration to strict mode batch disable
    // Mass eslint disable @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
    costSum: rawCosts.reduce((rawCostSum, cost) => rawCostSum + cost, 0),
    // migration to strict mode batch disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    volumeSumKwh: productVolumes.reduce(
      // Mass lint disable
      // Mass eslint disable @typescript-eslint/no-unsafe-return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
      (volumeSum, volume) => volumeSum + Number.parseFloat(volume || 0),
      0
    ),
  }
}

const EnergySum = ({
  wattUnit,
  lineValue,
  isMetered,
}: {
  isMetered: boolean
  lineValue: number
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
}) => {
  const value: number = transformKwhToWattUnit(lineValue, wattUnit)

  return (
    <span className="paragraph--medium__heavy graph-flyout__content">
      <If condition={isMetered}>
        <EnergyUnitDecimal value={value} />
      </If>
      <If condition={!isMetered}>
        <EnergyUnitDecimal value={undefined} />
      </If>
      <If condition={lineValue}>
        {" "}
        <Unit unit={wattUnit} />
      </If>
    </span>
  )
}

export const GraphFlyout = ({
  graphDatum,
  orientation,
  weather,
  x,
  y,
  wattUnit,
  headers,
  dateRangeWithType = {
    startDate: null,
    endDate: null,
    timeRange: TIME_RANGES.RANGE,
  },
}: {
  dateRangeWithType?: DateRangeWithType
  graphDatum: {
    hour: Moment
    hoverLabel: string
    lineValue?: number
    products: Product[]
  }
  headers: JSX.Element[]
  orientation:
    | GRAPH_DETAIL_MODAL_ORIENTATION.RIGHT
    | GRAPH_DETAIL_MODAL_ORIENTATION.LEFT
  wattUnit: UnitName.KilowattHour | UnitName.MegawattHour
  // Mass eslint disable @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weather?: { humidity: any; icon: any; temp: any }
  x: number
  y: number
}) => {
  const divStyles =
    orientation === GRAPH_DETAIL_MODAL_ORIENTATION.RIGHT
      ? { top: y, left: x }
      : { top: y, right: x }
  const isMetered = Math.abs(kwhToMwh(graphDatum.lineValue)) > METERED_LINE
  const {
    productSummaries,
    productCosts,
    productPrices,
    costSum,
    volumeSumKwh,
  } = getFlyoutData(graphDatum.products, wattUnit, dateRangeWithType.timeRange)

  return (
    <Box
      border={`1px solid ${gray500.toString()}`}
      className={`graph-flyout ${isMetered ? "" : "graph-flyout--no-meter"}`}
      style={divStyles}
    >
      {weather ? (
        <>
          <Box
            className="graph-flyout__title"
            style={{ gridColumn: "1/4", backgroundColor: gray100.toString() }}
          >
            <p className="graph-flyout__time paragraph--medium__regular">
              {
                // Monthly display
              }
              <If
                condition={
                  graphDatum.hoverLabel.length >= 6 &&
                  !graphDatum.hoverLabel.includes("HOUR")
                }
              >
                <DateDisplay
                  format={{ month: "long", year: "numeric" }}
                  value={moment(graphDatum.hoverLabel, "M/YYYY")}
                />
              </If>
              {
                // Daily display
              }
              <If
                condition={
                  graphDatum.hoverLabel.length >= 3 &&
                  graphDatum.hoverLabel.length <= 5 &&
                  !graphDatum.hoverLabel.includes("HOUR")
                }
              >
                <DateDisplay
                  format={{
                    day: "numeric",
                    month: "numeric",
                  }}
                  value={moment(graphDatum.hoverLabel, "M/D")}
                />
              </If>
              {
                // Hourly display
              }
              <If condition={graphDatum.hoverLabel.includes("HOUR")}>
                {graphDatum.hoverLabel}
              </If>
            </p>
          </Box>
          <Box
            className="graph-flyout__temperature temperature"
            style={{ backgroundColor: gray100.toString() }}
          >
            <p className="paragraph--medium__regular">
              <img
                alt=""
                className="temperature__icon"
                height="26px"
                // migration to strict mode batch disable
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                src={weather.icon}
                width="26px"
              />
              <span className="temperature__degrees">
                <DecimalWithPlaceholder
                  useAccountingFormatWhenNegative={false}
                  // migration to strict mode batch disable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value={weather.temp}
                />{" "}
                <Unit unit={UnitName.Fahrenheit} />
              </span>
            </p>
          </Box>
          <Box
            className="graph-flyout__humidity humidity"
            style={{ backgroundColor: gray100.toString() }}
          >
            <p className="paragraph--medium__regular">
              <span className="humidity__label">Humidity</span>
              <span className="humidity__percentage">{weather.humidity}%</span>
            </p>
          </Box>
        </>
      ) : (
        <div
          className="graph-flyout__title"
          style={{ gridColumn: "1/6", backgroundColor: gray100.toString() }}
        >
          <p className="graph-flyout__time">{graphDatum.hoverLabel}</p>
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="header-text__product">
        <p className="paragraph--medium__heavy graph-flyout__content">
          Product
        </p>
      </label>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="header-text__count">
        <p className="paragraph--medium__heavy graph-flyout__content">
          {headers[0]}
        </p>
      </label>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="header-text__price">
        <p className="paragraph--medium__heavy graph-flyout__content">
          {headers[1]}
        </p>
      </label>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="header-text__total">
        <p className="paragraph--medium__heavy graph-flyout__content">
          {headers[2]}
        </p>
      </label>
      {productSummaries.map(
        ({
          productId,
          productColor,
          strokeColor,
          productCount,
          displayName,
        }) => (
          <React.Fragment key={`confirm-summary-${productId}`}>
            <div className="graph-flyout__product-color">
              <span
                className="product-color"
                data-testid="product-color"
                style={{
                  borderColor: strokeColor,
                  backgroundColor: productColor,
                }}
              />
            </div>
            <div className="graph-flyout__product-name">
              <p
                className="paragraph--medium__regular graph-flyout__content"
                data-e2e="product-name"
                data-testid="product-name"
              >
                {displayName}
              </p>
            </div>
            <div className="graph-flyout__product-count">
              <p
                className="paragraph--medium__regular graph-flyout__content"
                data-e2e="product-count"
                data-testid="product-count"
              >
                {productCount}
                {dateRangeWithType.timeRange === TIME_RANGES.YEAR && (
                  <>
                    {" "}
                    <Unit unit={wattUnit} />
                  </>
                )}
              </p>
            </div>
            <div className="graph-flyout__product-price">
              <p
                className="paragraph--medium__regular graph-flyout__content"
                data-e2e="product-price"
                data-testid="product-price"
              >
                {dateRangeWithType.timeRange === TIME_RANGES.YEAR ? (
                  // This is being displayed as a Monthly Summary graph
                  <>
                    <EnergyUnitDecimal
                      value={transformKwhToWattUnit(volumeSumKwh, wattUnit)}
                    />{" "}
                    <Unit unit={wattUnit} />
                  </>
                ) : (
                  // This is being displayed in a Energy Allocations graph
                  <CurrencyRange
                    end={
                      productPrices.find(
                        (productPrice) => productPrice.productId === productId
                      )?.productPrice.end
                    }
                    start={
                      productPrices.find(
                        (productPrice) => productPrice.productId === productId
                      )?.productPrice.start
                    }
                  />
                )}
              </p>
            </div>
            <div className="graph-flyout__product-cost">
              <p
                className="paragraph--medium__regular graph-flyout__content"
                data-e2e="product-cost"
                data-testid="product-cost"
              >
                <If condition={productCosts}>
                  <CurrencyWithPlaceholder
                    value={
                      productCosts.find(
                        (productCost) => productCost.productId === productId
                      )?.productCost
                    }
                  />
                </If>
              </p>
            </div>
          </React.Fragment>
        )
      )}
      {dateRangeWithType.timeRange !== TIME_RANGES.YEAR && (
        <div
          className={`graph-flyout__summary${!isMetered ? "--no-meter" : ""}`}
        >
          {!isMetered ? (
            <div className="graph-flyout__notice">
              <p className="paragraph--medium__heavy graph-flyout__content">
                Meter Data Unavailable
              </p>
            </div>
          ) : (
            <div className="total__energy-label">
              <p className="paragraph--medium__heavy graph-flyout__content">
                Total Metered
              </p>
            </div>
          )}
          <div className="total__energy-sum">
            <p className="paragraph--medium__heavy graph-flyout__content">
              <EnergySum
                isMetered={isMetered}
                lineValue={graphDatum.lineValue}
                wattUnit={wattUnit}
              />
            </p>
          </div>
          <div className="total__average-sum">
            <p className="paragraph--medium__heavy graph-flyout__content">
              <CurrencyWithPreciseFraction
                value={getAverageCostPerKwh({
                  totalCost: costSum,
                  lineValue: graphDatum.lineValue,
                  isMetered,
                })}
              />
            </p>
          </div>
          <div className="total__cost-sum">
            <p className="paragraph--medium__heavy graph-flyout__content">
              <CurrencyWithPreciseFraction value={costSum} />
            </p>
          </div>
        </div>
      )}
    </Box>
  )
}
