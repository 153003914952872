import React from "react"
import type { FC } from "react"

import { Box, Stack } from "@mui/material"

import type { PageProps } from "./page.types"

export const Page: FC<PageProps> = ({ children, fullHeight, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Stack
    {...rest}
    p={3}
    sx={{
      ...(fullHeight && {
        flex: 1,
      }),
    }}
  >
    {fullHeight ? (
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            bottom: 0,
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    ) : (
      children
    )}
  </Stack>
)
