import React, { useEffect } from "react"
import type { FC } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import {
  SelectedDateRangeProvider,
  SiteProvider,
  SiteSearchProvider,
  SiteSearchResultsActiveProvider,
  ViewportProvider,
} from "@/contexts"
import { RootPath } from "@/models/route"
import { UserSettingsProvider } from "@/modules/settings"
import {
  useAuthentication,
  useDatadogRumSetUser,
  useGtagSetUser,
  usePendoInit,
} from "@/services"
import { getCurrentAppUrl } from "@/utils"
import { LogoutProvider } from "@/utils/logout-context"

import Page404 from "../../nav/page404/page404"

export const AuthenticatedRoute: FC = () => {
  const { isAuthenticated, isFreeUser } = useAuthentication()
  useDatadogRumSetUser()
  // Set the gtag user_id here if user loads a page and is already authenticated
  useGtagSetUser()
  usePendoInit()
  const location = useLocation()
  const navigate = useNavigate()

  const isFreeUserRoute =
    location.pathname.includes(RootPath.Rep) &&
    !location.pathname.includes(RootPath.Report)

  // Redirect to sign in if not authenticated
  useEffect(() => {
    const state = { from: { pathname: getCurrentAppUrl() } }

    if (!isAuthenticated) {
      navigate(RootPath.Root, { state })
    }
  }, [isAuthenticated, navigate])

  return (
    isAuthenticated && (
      <LogoutProvider>
        <UserSettingsProvider>
          <SiteProvider>
            <ViewportProvider>
              <SelectedDateRangeProvider>
                <SiteSearchProvider>
                  <SiteSearchResultsActiveProvider>
                    {!isFreeUser || (isFreeUser && isFreeUserRoute) ? (
                      <Outlet />
                    ) : (
                      <Page404 />
                    )}
                  </SiteSearchResultsActiveProvider>
                </SiteSearchProvider>
              </SelectedDateRangeProvider>
            </ViewportProvider>
          </SiteProvider>
        </UserSettingsProvider>
      </LogoutProvider>
    )
  )
}
