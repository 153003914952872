export const createAndSortOptions = (
  values: string[],
  labels: string[],
  options: { sort: boolean } = { sort: true }
) => {
  if (values.length !== labels.length) {
    throw new Error("Lengths must match")
  }
  const sorted = values.map((val, index) => ({
    label: val === "__MISSING__" ? "Unknown" : labels[index],
    value: val,
  }))

  if (options.sort) {
    sorted.sort((a, b) => a.label.localeCompare(b.label))
  }

  // Add "Unknown" option to the bottom of the list
  const unknownIndex = sorted.findIndex((o) => o.value === "__MISSING__")
  const unknown = sorted.splice(unknownIndex, 1)[0]
  sorted.push(unknown)
  return sorted
}
