import React from "react"
import { Link } from "react-router-dom"

import { useFeature } from "@/services/feature"

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"
import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material"

import type Organization from "../../../../models/organization"
import { RootPath } from "../../../../models/route"
import { primary50, primary600 } from "../../../../utils/colors"
import { pxToRem } from "../../../../utils/unit"
import type { ICard } from "../../models/report"

interface IReportingCardProps {
  card: ICard
  organization: Organization
}

export const ReportingCard = ({ card, organization }: IReportingCardProps) => {
  const { isFeatureEnabled } = useFeature()

  const isDataUnderReview: boolean = isFeatureEnabled(
    card.underReviewFeatureFlag,
    organization
  )

  const isDataReady: boolean = isFeatureEnabled(
    card.dataReadyFeatureFlag,
    organization
  )

  const isUnderReviewChipVisible: boolean = isDataUnderReview && !isDataReady

  const isNew: boolean = isFeatureEnabled(card.newFeatureFlag, organization)

  const isNewChipVisible: boolean = isNew && !isUnderReviewChipVisible

  const to = card.buttonLink.startsWith("/")
    ? card.buttonLink
    : `${RootPath.Report}/${organization?.id}/${card.buttonLink}`
  return (
    <Paper data-testid={card.dataE2e} sx={{ width: pxToRem(320), p: 3 }}>
      <Stack
        alignItems={card.logo ? "top" : "center"}
        direction="row"
        mb={1.5}
        spacing={2}
      >
        {card.logo ? (
          <Box
            alt={card.altText}
            component="img"
            src={card.logo}
            sx={{
              height: pxToRem(60),
              mt: 0,
              mb: 1.5,
            }}
          />
        ) : (
          <Typography variant="h2">{card.title}</Typography>
        )}
        <span>
          {isUnderReviewChipVisible && (
            <Chip
              label="Under Review"
              size="small"
              sx={{
                color: primary600.toString(),
                backgroundColor: primary50.toString(),
                borderColor: primary600.toString(),
              }}
              variant="outlined"
            />
          )}
          {isNewChipVisible && (
            <Chip color="primary" label="New" size="small" variant="outlined" />
          )}
        </span>
      </Stack>
      {!card.logo && <Typography mb={2}>{card.description}</Typography>}
      <Button
        color="secondary"
        component={Link}
        data-e2e={card.dataE2e}
        endIcon={<ChevronRightRoundedIcon fontSize="large" />}
        fullWidth
        to={to}
        variant="contained"
      >
        {card.buttonText}
      </Button>
    </Paper>
  )
}

export default ReportingCard
