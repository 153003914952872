import type { FC } from "react"
import React from "react"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

import { PageHeader } from "@/components"
import { RootPath, SitePath } from "@/models/route"

import { Tab, Tabs } from "@mui/material"

export const SitesIndexRoute: FC = () => {
  const { pathname } = useLocation()
  if ((pathname as RootPath) === RootPath.Site) {
    return <Navigate to={SitePath.Overview} replace={true} />
  }

  return (
    <>
      <PageHeader
        navigation={
          <Tabs
            aria-label="Site Explorer navigation"
            role="navigation"
            sx={{ px: 3 }}
            value={pathname}
          >
            <Tab
              component={Link}
              label="Overview"
              to={SitePath.Overview}
              value="/sites/overview"
            />
            <Tab
              component={Link}
              label="Performance"
              to={SitePath.Performance}
              value="/sites/performance"
            />
            <Tab
              component={Link}
              label="Compliance"
              to={SitePath.Compliance}
              value="/sites/compliance"
            />
          </Tabs>
        }
        title="Site Explorer"
      />
      <Outlet />
    </>
  )
}
