import type { ReactElement } from "react"
import React, { useEffect, useMemo, useState } from "react"

import { throttle } from "lodash-es"

import type { SxProps } from "@mui/material"
import { Box, Collapse, Toolbar, Typography } from "@mui/material"

import { useViewport } from "../../contexts/viewportProvider"
import { bgColor, black12 as dropShadow } from "../../utils/colors"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
} from "../../utils/constants"
import { pxToRem } from "../../utils/unit"

interface StickyBarProps {
  children?: ReactElement | ReactElement[]
  className?: string
  sx?: SxProps
  title?: string
}

const stickyBarScrolledStyles: SxProps = {
  backgroundColor: `${bgColor}`,
  boxShadow: `0px -4px 4px 6px ${dropShadow.rgb().toString()}`,
}

const stickyBarStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minHeight: "40px !important",
}

const scrollStyleThreshold = 0 // In pixels. Determined through observation

/**
 * React component that provides a Sticky Bar with navigation features
 *
 * @param props
 * @param props.className The root's element class name
 * @param props.children The children
 * @param props.onChange The root's element function to execute behavior when it’s sticking or not
 * @param props.sx The root element's sx
 * @param props.title The sticky bar's title
 * @returns a page header bar that will stick to the top when scrolled
 *
 * @example
 * return (
 *   <StickyBar className={"my-class"} sx={{zIndex: "2"}} title="Dashboard">
 *     <div>Content</div>
 *   <StickyBar>
 * )
 */

const StickyBar = ({ className, children, sx, title }: StickyBarProps) => {
  const { collapsed } = useViewport()
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const handleOnScroll = () => {
    const scrolled: boolean = window.scrollY > scrollStyleThreshold
    setIsScrolled(scrolled)
  }

  const throttledHandleOnScroll = throttle(handleOnScroll, 200)

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleOnScroll)
    return () => {
      window.removeEventListener("scroll", throttledHandleOnScroll)
    }
  }, [])

  const containerStyle: SxProps = useMemo(
    () => ({
      left: collapsed ? LEFT_NAV_COLLAPSED_WIDTH : LEFT_NAV_EXPANDED_WIDTH,
      paddingTop: pxToRem(16),
      paddingBottom: pxToRem(16),
      borderRadius: 0,
      position: "fixed",
      width: "100%",
      zIndex: "1",
      ...sx,
      ...(isScrolled ? stickyBarScrolledStyles : {}),
    }),
    [sx, collapsed, isScrolled]
  )

  return (
    <Box
      className={className}
      data-testid="stickyBarContainer"
      sx={containerStyle}
    >
      <Toolbar sx={stickyBarStyles}>
        <Collapse in={!isScrolled}>
          <Typography mb={1} ml={-0.125} variant="h1">
            {title}
          </Typography>
        </Collapse>
        {children}
      </Toolbar>
    </Box>
  )
}

StickyBar.defaultProps = {
  className: "",
  children: [],
  sx: {},
  title: "",
} as StickyBarProps

export default StickyBar
