import type { UseInfiniteQueryOptions } from "react-query"
import { useInfiniteQuery } from "react-query"

import { useAuthentication } from "@/services"
import type { Paths } from "@/services/types"
import { useQueryKey } from "@/services/utils/useQueryKey"

import { ApiQueryName } from "../../../../models/api"
import { getProfiles } from "./get-profiles"

export interface UseInfiniteGetProfilesOptions {
  initialPage?: number
  parameters: Omit<
    Paths["/api/v1/recommendations/emission_profiles"]["get"]["parameters"],
    "page"
  >
  queryOptions?: Omit<UseInfiniteQueryOptions, "queryKey" | "queryFn">
}

/**
 * Returns emission profiles for a given org
 *
 * @example
 * const { data, isLoading, error, isError } =
 * useGetProfiles({
 *   parameters: { query: { organization_id: 123, orderBy: "name", order: "asc" } },
 *   queryOptions // react query options
 * });
 */
export const useInfiniteGetProfiles = ({
  parameters,
  initialPage,
  queryOptions,
}: UseInfiniteGetProfilesOptions) => {
  const { isFreeUser } = useAuthentication()

  const queryKey = useQueryKey(
    ApiQueryName.EmissionProfiles,
    "getMany",
    null,
    parameters
  )

  let queryParams = {
    queryKey,
    queryFn: ({ pageParam = initialPage ?? 1 }) => {
      const newParameters = {
        ...parameters,
      }
      newParameters.query.access_level = isFreeUser ? "LIMITED" : "FULL"
      newParameters.query.page = pageParam
      return getProfiles(newParameters)
    },
  }

  if (queryOptions) {
    queryParams = {
      ...queryOptions,
      ...queryParams,
    }
  }

  const query = useInfiniteQuery({
    ...queryParams,
    getNextPageParam: (lastPage) => {
      if (lastPage.page === lastPage.pages) {
        return undefined
      }
      return lastPage.page + 1
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.page === 1) {
        return undefined
      }
      return firstPage.page - 1
    },
  })

  return query
}
