import React, { useEffect, useState } from "react"
import type { SubmitHandler } from "react-hook-form"
import { Controller, useForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"

import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { TYPOGRAPHY_VARIANT } from "../../../../models/typography"
import {
  AuthenticationOptionType,
  PostRequestStatusType,
  UtilityFormStateType,
  UtilityFormType,
  UtilityProviderType,
  electricityProviders,
  naturalGasProviders,
  securityQuestions,
} from "../../model/utilityCredentials"
import type { UtilityCredentialsBody } from "../../services/useUtilityCredentialsSignUp"
import { useUtilityCredentialsSignUp } from "../../services/useUtilityCredentialsSignUp"
import type { UtilityCredentialsSchemaType } from "../../services/validationSchema"
import { utilityCredentialsSchema } from "../../services/validationSchema"
import { ErrorPage } from "../statusPages/errorPage"
import { SuccessPage } from "../statusPages/successPage"

interface CredentialSignUpFormProps {
  currentForm: UtilityFormType
  handleCurrentFormSubmit: (
    curForm: UtilityFormType,
    curFormState: UtilityFormStateType,
    nextForm: UtilityFormType
  ) => void
  token: string
}

const CredentialSignUpForm = ({
  token,
  currentForm,
  handleCurrentFormSubmit,
}: CredentialSignUpFormProps): JSX.Element => {
  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<UtilityCredentialsSchemaType>({
    mode: "onBlur",
    defaultValues: {
      utility_provider: "",
      username: "",
      two_factor_auth_notes: "",
      password: "",
      question: "",
      answer: "",
      authentication_option: "security_question",
    },
    resolver: zodResolver(utilityCredentialsSchema),
  })
  const [showPassword, setShowPassword] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [authentication_option, utility_provider] = watch([
    "authentication_option",
    "utility_provider",
  ])

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const { mutateAsync, error, status } = useUtilityCredentialsSignUp()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handleSkip = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCurrentFormSubmit(
      currentForm,
      UtilityFormStateType.Complete,
      UtilityFormType.Success
    )
  }

  const onSubmit: SubmitHandler<UtilityCredentialsSchemaType> = async (
    formData
  ) => {
    // Mass eslint disable @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let mainBody: any = {
      token,
      provider_type: currentForm,
      utility_provider: formData.utility_provider,
      username: formData.username,
      password: formData.password,
    }
    if (formData.utility_provider === "national grid") {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      mainBody = {
        ...mainBody,
        authentication_option: "",
        security_question: {
          question: "",
          answer: "",
        },
      }
    } else {
      if (formData.authentication_option === "security_question") {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        mainBody = {
          ...mainBody,
          authentication_option: formData.authentication_option,
          security_question: {
            question: formData.question,
            answer: formData.answer,
          },
        }
      }

      if (formData.authentication_option === "2FA") {
        // migration to strict mode batch disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        mainBody = {
          ...mainBody,
          authentication_option: formData.authentication_option,
          two_factor_auth_notes: formData.two_factor_auth_notes,
          security_question: {
            question: "",
            answer: "",
          },
        }
      }
    }
    const resp: UtilityCredentialsBody = {
      // migration to strict mode batch disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: mainBody,
    }
    await mutateAsync(resp)
  }

  useEffect(() => {
    if (status === PostRequestStatusType.Error) {
      handleCurrentFormSubmit(
        currentForm,
        UtilityFormStateType.Error,
        UtilityFormType.Error
      )
    }
  }, [status])

  useEffect(() => {
    reset()
    if (isSubmitSuccessful && currentForm === UtilityFormType.Electric) {
      handleCurrentFormSubmit(
        currentForm,
        UtilityFormStateType.Complete,
        UtilityFormType.NaturalGas
      )
    } else if (
      isSubmitSuccessful &&
      currentForm === UtilityFormType.NaturalGas
    ) {
      handleCurrentFormSubmit(
        currentForm,
        UtilityFormStateType.Complete,
        UtilityFormType.Success
      )
    }
  }, [isSubmitSuccessful])

  return (
    <Stack
      alignItems="flex-start"
      sx={{
        width: { xs: "100%", md: "50%" },
        maxHeight: "100%",
        overflowY: { md: "auto" },
        padding: 4.5,
      }}
    >
      {currentForm === UtilityFormType.Success && <SuccessPage />}
      {currentForm === UtilityFormType.Error && (
        <ErrorPage errorCode={error?.response?.status} />
      )}
      {(currentForm === UtilityFormType.Electric ||
        currentForm === UtilityFormType.NaturalGas) && (
        <>
          <Typography variant={TYPOGRAPHY_VARIANT.h2}>
            Utility Login Credentials
          </Typography>
          <Divider flexItem orientation="horizontal" sx={{ mb: 3, mt: 1 }} />
          <Stack
            component="form"
            id="utility-sign-up-form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%", height: "100%", overflowY: { md: "auto" } }}
          >
            <Grid
              container
              direction="column"
              flexWrap="nowrap"
              gap={1.5}
              height="100%"
            >
              <Grid item>
                <Controller
                  control={control}
                  name="utility_provider"
                  render={({ field, fieldState }) => (
                    <FormControl
                      error={Boolean(fieldState.error?.message)}
                      fullWidth
                    >
                      <InputLabel id="utility_provider_label">
                        Utility Provider
                      </InputLabel>
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        id="utility_provider_select"
                        label="Utility provider"
                        labelId="utility_provider_label"
                      >
                        {(currentForm === UtilityFormType.Electric
                          ? electricityProviders
                          : naturalGasProviders
                        ).map((i) => (
                          // Mass lint disable
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.error?.message ? (
                        <FormHelperText>
                          {fieldState.error.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="username"
                  render={({ field, fieldState }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      error={Boolean(fieldState.error?.message)}
                      fullWidth
                      helperText={fieldState.error?.message}
                      id="username"
                      label="Email / Username"
                      type="email"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      error={Boolean(fieldState.error?.message)}
                      fullWidth
                      helperText={fieldState.error?.message}
                      id="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                    />
                  )}
                />
              </Grid>
              {utility_provider === UtilityProviderType.conEdison && (
                <>
                  <Grid item>
                    <Controller
                      control={control}
                      name="authentication_option"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <FormLabel id="authentication_option_label">
                            Additional Security Information
                          </FormLabel>
                          <Divider
                            flexItem
                            orientation="horizontal"
                            sx={{ mb: 1.5 }}
                          />
                          <RadioGroup
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            aria-labelledby="authentication_option_label"
                            id="authentication_option"
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Security Question"
                              value="security_question"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Two Factor Authentication"
                              value="2FA"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {authentication_option ===
                  AuthenticationOptionType.SecurityQuestion ? (
                    <>
                      <Grid item>
                        <Controller
                          control={control}
                          name="question"
                          render={({ field, fieldState }) => (
                            <FormControl
                              error={Boolean(fieldState.error?.message)}
                              fullWidth
                            >
                              <InputLabel id="question_label">
                                Security Question
                              </InputLabel>
                              <Select
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                                id="question"
                                label="Security Question"
                                labelId="question_label"
                              >
                                {securityQuestions.map((i) => (
                                  <MenuItem key={i} value={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                              </Select>
                              {fieldState.error?.message ? (
                                <FormHelperText>
                                  {fieldState.error.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name="answer"
                          render={({ field, fieldState }) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              error={Boolean(fieldState.error?.message)}
                              fullWidth
                              helperText={fieldState.error?.message}
                              id="answer"
                              label="Security Answer"
                              type="text"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Controller
                        control={control}
                        name="two_factor_auth_notes"
                        render={({ field }) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            fullWidth
                            id="two_factor_auth_notes"
                            label="Notes (Optional)"
                            multiline
                            placeholder="Timezone, Availability, Contact Details"
                            type="text"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item marginTop="auto">
                {currentForm === UtilityFormType.Electric && (
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
                {currentForm === UtilityFormType.NaturalGas && (
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      disabled={isSubmitting}
                      sx={{ width: "48%" }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      onClick={(e) => {
                        handleSkip(e)
                      }}
                      sx={{ width: "48%" }}
                      type="reset"
                      variant="outlined"
                    >
                      Skip
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default CredentialSignUpForm
