import React from "react"

import { AppBar, Box, Toolbar, useTheme } from "@mui/material"

import { useSiteSearchResultsActiveContext } from "../../../../contexts/siteSearchResultsActiveProvider"
import type { ISitePreview } from "../../../../models/site"
import { bgColor, darkGray } from "../../../../utils/colors"
import {
  LEFT_NAV_COLLAPSED_WIDTH,
  LEFT_NAV_EXPANDED_WIDTH,
} from "../../../../utils/constants"
import { If } from "../../../if/if"
import { ShareButton } from "../../../share-button/share-button"
import { SiteSearchResultsTable } from "../../siteSearchResultsTable"
import { SiteSearch } from "../../sitesSearch"
import { UserMenu } from "../../userMenu/userMenu"

const styles = {
  appBar: {
    backgroundColor: bgColor.toString(),
    boxShadow: "none",
    borderRadius: "0",
  },
  appBarOpen: {
    width: `calc(100% - ${LEFT_NAV_EXPANDED_WIDTH}px)`,
    marginLeft: `${LEFT_NAV_EXPANDED_WIDTH}px`,
  },
  appBarCollapsed: {
    width: `calc(100% - ${LEFT_NAV_COLLAPSED_WIDTH}px)`,
    marginLeft: `${LEFT_NAV_COLLAPSED_WIDTH}px`,
  },
  homeLink: {
    alignItems: "center",
    display: "inline-flex",
    gap: "12px",
  },
  digitalInfraLogo: {
    display: "flex",
    minWidth: "44px",
    width: "44px",
  },
  nZeroLogo: {
    display: "flex",
    color: darkGray.toString(),
  },
}

interface MarginStyles {
  marginLeft: string
  width: string
}

interface IAppHeaderProps {
  collapsed: boolean
  sitePreviews: ISitePreview[]
}

export const AppHeader = ({ collapsed, sitePreviews }: IAppHeaderProps) => {
  const theme = useTheme()
  const { isSiteSearchResultsActive } = useSiteSearchResultsActiveContext()
  const [searchTerm, setSearchTerm] = React.useState<string>("")

  const appBarState: MarginStyles = collapsed
    ? styles.appBarCollapsed
    : styles.appBarOpen

  return (
    <AppBar position="fixed" sx={[styles.appBar, appBarState]}>
      <Toolbar>
        <SiteSearch setSearchTerm={setSearchTerm} sitePreviews={sitePreviews} />
        <Box
          position="absolute"
          right={theme.typography.pxToRem(72)}
          top={theme.typography.pxToRem(12)}
        >
          <ShareButton iconButton title="Share URL" />
        </Box>
        <Box
          position="absolute"
          right={theme.typography.pxToRem(24)}
          top={theme.typography.pxToRem(12)}
        >
          <UserMenu />
        </Box>
      </Toolbar>
      <If condition={isSiteSearchResultsActive}>
        <SiteSearchResultsTable searchTerm={searchTerm} />
      </If>
    </AppBar>
  )
}
