import React, { useMemo } from "react"

import type { ISitePreview } from "../models/site"

interface SiteSearchContext {
  isOrgSitesChanging: boolean
  searchResults: ISitePreview[]
  setIsOrgSitesChanging: (isOrgSitesChanging: boolean) => void
  setSearchResults: (siteSearch: ISitePreview[]) => void
}

const siteSearchContext = React.createContext<SiteSearchContext>({
  searchResults: [],
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSearchResults: (results: ISitePreview[]) => undefined,
  isOrgSitesChanging: false,
  // Mass eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOrgSitesChanging: (isOrgSitesChanging: boolean) => undefined,
})

export const SiteSearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = React.useState<ISitePreview[]>([])
  const [isOrgSitesChanging, setIsOrgSitesChanging] =
    React.useState<boolean>(false)

  const value = useMemo(
    () => ({
      searchResults,
      setSearchResults,
      isOrgSitesChanging,
      setIsOrgSitesChanging,
    }),
    [searchResults, isOrgSitesChanging]
  )

  return (
    <siteSearchContext.Provider value={value}>
      {children}
    </siteSearchContext.Provider>
  )
}

export const useSiteSearchContext = () => React.useContext(siteSearchContext)
